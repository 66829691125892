import React, { PropsWithChildren, useContext } from 'react'
import { CrmContext, ICrmContext } from '../../contexts/crmContext'
import { GoogleMap } from '@react-google-maps/api'

const defaultContainerStyle = {
  width: '100%',
  height: '100%',
  overflow: 'visible !important',
}

const defaultCenter = {
  lat: 52.520008,
  lng: 13.404954,
}

interface ICustomGoogleMap {
  containerStyle?: {
    width: string
    height: string
    visible?: string
  }
  center?: {
    lat: number
    lng: number
  }
}

const SimpleGoogleMap: React.FC<PropsWithChildren<ICustomGoogleMap>> = ({ children, containerStyle, center }) => {
  const { setMapInstance } = useContext(CrmContext) as ICrmContext
  return (
    <GoogleMap
      center={center || defaultCenter}
      mapContainerStyle={defaultContainerStyle}
      // mapContainerClassName="mapOverview"
      zoom={8}
      onLoad={(map) => {
        setMapInstance(map)
      }}
      options={{
        mapTypeControl: false,
        fullscreenControl: false,
      }}
    >
      {children}
    </GoogleMap>
  )
}

export default React.memo(SimpleGoogleMap)
