import { Appointment, debounce, useAbilities, Pharmacy } from '@aposphaere/core-kit'
import {
  ApoCampusIcon,
  Button,
  ButtonKind,
  CloseIcon,
  DangerIcon,
  EditIcon,
  IconButton,
  IconButtonKind,
  LeftSidebar,
  LockedForCallsIcon,
  PhoneIcon,
  PlusSignIcon,
  ReminderIcon,
  SwitchOnIcon,
  TabBar,
  TabBarItem,
  TabBarItemKind,
} from '@aposphaere/ui-components'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { CrmContext, ICrmContext, ModalKind } from '../../contexts/crmContext'
import AppointmentsCard from '../AppointmentsCard'
import NotesCard from '../NotesCard'
import PersonenCard from '../PersonenCard'
import PharmaciesCard from '../PharmaciesCard'
import ProjectsCard from '../ProjectsCard'
import { IPointType, OpeningHour } from './PharmacyDataSheetTypes'

const PharmacyDataSheetPanel: React.FC = () => {
  const abilities = useAbilities()

  const context = useContext(CrmContext) as ICrmContext
  const {
    appointmentHook: { setCurrentAppointment, currentlySelectedAppointment },
    onOpenModal,
    currentlyActivePharmacy: pharmacy,
    selectPharmacyBranchHook: { setPharmacyBranchActive, pharmacyBranchActive },
    setLeftSideBoardStatus,
    appointments,
    toggleAvailableForCallCenter,
    projects,
    pharmacies,
    getFreshPharmacyDetails,
    currentPointHook: { currentPoint, setCurrentPoint },
    infoRef,
    notizenRef,
    aufgabenRef,
    filialenRef,
    termineRef,
    projekteRef,
    personenRef,
    windowRef,
  } = context

  const switchActivePharmacy = () => {
    if (!pharmacyBranchActive) {
      setPharmacyBranchActive(true)
    }
  }
  const onCreateAppointment = () => {
    switchActivePharmacy()
    setLeftSideBoardStatus('appointmentCreation')
  }

  useEffect(() => {
    if (currentlySelectedAppointment) {
      onOpenModal(ModalKind.Appointment)
    }
  }, [currentlySelectedAppointment, onOpenModal])
  useEffect(() => {
    const loadPharmacyDetails = async () => {
      await getFreshPharmacyDetails(pharmacy?.id || 0, pharmacyBranchActive)
    }
    if (pharmacy?.id) {
      loadPharmacyDetails().catch(console.error)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacyBranchActive])

  const [futureAppointments, pastAppointments] = useMemo<[Appointment[], Appointment[]]>(() => {
    const pharmacyAppointments: Appointment[] =
      appointments?.filter((appointment) => appointment.pharmacy?.id === pharmacy?.id && `${appointment.appointmentType?.id || 0}` !== '4') || []
    const future = pharmacyAppointments.filter((appointment) => new Date(appointment?.date?.toString().replace(/\s/, 'T') || '') > new Date())
    const past = pharmacyAppointments.filter((appointment) => new Date(appointment?.date?.toString().replace(/\s/, 'T') || '') < new Date())
    return [future, past]
  }, [appointments, pharmacy])

  const futureVisits = useMemo<Appointment[]>(() => {
    const currentVisits = appointments?.filter((appointment) => `${appointment.appointmentType?.id || 0}` === '4') || []
    const pharmacyVisits: Appointment[] = currentVisits?.filter((appointment) => appointment.pharmacy?.id === pharmacy?.id) || []
    const future = pharmacyVisits.filter((appointment) => new Date(appointment?.date?.toString().replace(/\s/, 'T') || '') > new Date())
    return future
  }, [appointments, pharmacy])

  const renderOpeningHours = () => {
    if (pharmacy?.opening_times === undefined) {
      return <p>{'Keine Öffningszeiten hinterlegt'}</p>
    }
    const openingHours: OpeningHour[] = JSON.parse(pharmacy?.opening_times) as OpeningHour[]

    const readableWeekday = (day: number) => {
      switch (day) {
        case 0:
          return 'So'
        case 1:
          return 'Mo'
        case 2:
          return 'Di'
        case 3:
          return 'Mi'
        case 4:
          return 'Do'
        case 5:
          return 'Fr'
        case 6:
          return 'So'
        default:
          return ''
      }
    }

    const buildOpeningTimes = (slots: [string, string][]) => {
      const slotParts = slots.join(' – ')
      return slotParts
    }

    return (
      <>
        {openingHours.map((openingHour, i) => (
          <span key={i}>
            {`${readableWeekday(openingHour.weekday)}:${buildOpeningTimes(openingHour.slots)}`}
            <br />
          </span>
        ))}
      </>
    )
  }
  const mainBranch = useMemo<Pharmacy | undefined>(() => {
    if (!pharmacies || !pharmacy || !pharmacy.parent?.id) {
      return undefined
    }
    let left = 0
    let right = pharmacies.length - 1
    let mid = Math.floor((left + right) / 2)

    if (`${pharmacies[mid].id}` === `${pharmacy.parent.id}`) {
      return pharmacies[mid]
    }
    while (pharmacies[mid].id !== pharmacy.parent.id && left <= right) {
      if (parseInt(`${pharmacies[mid].id}`) < parseInt(`${pharmacy.parent.id}`)) {
        left = mid + 1
      } else {
        right = mid - 1
      }
      mid = Math.floor((left + right) / 2)
    }
    return pharmacies[mid].id === pharmacy.parent.id ? pharmacies[mid] : undefined
  }, [pharmacy, pharmacies])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkThePointSelected = useCallback(
    debounce((point: IPointType) => {
      if (currentPoint !== point) {
        setCurrentPoint(point)
      }
    }, 500),
    [currentPoint],
  )

  const executeScroll = useCallback(
    (ref: React.RefObject<HTMLHeadingElement>, point: IPointType) => {
      if (ref && windowRef && ref.current && windowRef.current) {
        windowRef.current.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop - 20 })
        checkThePointSelected(point)
      }
    },
    [windowRef, checkThePointSelected],
  )

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      if (
        infoRef.current &&
        notizenRef.current &&
        aufgabenRef.current &&
        filialenRef.current &&
        termineRef.current &&
        projekteRef.current &&
        personenRef.current
      ) {
        const offsetTopInfo = infoRef.current.offsetTop - 15
        const offsetTopNotezen = notizenRef.current.offsetTop - 25
        const offsetTopAufgaben = aufgabenRef.current.offsetTop - 25
        const offsetTopFilialen = filialenRef.current.offsetTop - 25
        const offsetTopTermine = termineRef.current.offsetTop - 25
        const offsetTopProjekte = projekteRef.current.offsetTop - 25
        const offsetTopPersonen = personenRef.current.offsetTop - 25

        const currentScrollTop = Math.floor(e.currentTarget.scrollTop)
        const maxScrollTop = e.currentTarget.scrollHeight - e.currentTarget.clientHeight

        if (offsetTopInfo + 30 >= currentScrollTop && currentScrollTop >= offsetTopInfo - 30) {
          if (currentPoint !== 'info') {
            setCurrentPoint('info')
          }
        } else if (offsetTopNotezen + 30 >= currentScrollTop && currentScrollTop >= offsetTopNotezen - 30) {
          if (currentPoint !== 'notizen') {
            setCurrentPoint('notizen')
          }
        } else if (offsetTopAufgaben + 30 >= currentScrollTop && currentScrollTop >= offsetTopAufgaben - 30) {
          if (currentPoint !== 'aufgaben') {
            setCurrentPoint('aufgaben')
          }
        } else if (offsetTopFilialen + 30 >= currentScrollTop && currentScrollTop >= offsetTopFilialen - 30) {
          if (currentPoint !== 'filialen') {
            setCurrentPoint('filialen')
          }
        } else if (offsetTopTermine + 30 >= currentScrollTop && currentScrollTop >= offsetTopTermine - 30) {
          if (currentPoint !== 'termine') {
            setCurrentPoint('termine')
          }
        } else if (offsetTopProjekte + 30 >= currentScrollTop && currentScrollTop >= offsetTopProjekte - 30) {
          if (currentPoint !== 'projekte') {
            setCurrentPoint('projekte')
          }
        } else if (offsetTopPersonen + 30 >= currentScrollTop && currentScrollTop >= offsetTopPersonen - 30) {
          if (currentPoint !== 'personen') {
            setCurrentPoint('personen')
          }
        }
        if (currentScrollTop === maxScrollTop) {
          if (currentPoint !== 'personen') {
            setCurrentPoint('personen')
          }
        }
      }
    },
    [currentPoint, aufgabenRef, filialenRef, infoRef, notizenRef, personenRef, projekteRef, termineRef, setCurrentPoint],
  )

  const additionalCss = pharmacy?.tasks?.filter((task) => moment(new Date(task.until || '')).isSameOrAfter(new Date(), 'day')).length
    ? 'text-yellow-600'
    : ''

  return (
    <LeftSidebar
      onScroll={handleScroll}
      ref={windowRef}
      headerContent={
        <div className="flex w-full flex-wrap font-body">
          <div className="flex flex-wrap-reverse xl:flex-nowrap w-full h-16 2xl:h-20  items-center pl-4">
            <div className="flex xl:w-1/3 w-full items-center">
              <h4 className={`text-base ${pharmacy?.available_for_callcenter === false ? 'text-red-700' : 'text-blue-700'} leading-6 font-medium`}>
                {pharmacy?.name}
              </h4>
            </div>
            <div className="flex w-full xl:w-2/3 justify-end pr-2 text-gray-700 items-center">
              {abilities.create_appointments && (
                <IconButton kind={IconButtonKind.default} icon={<PlusSignIcon dimension="30" />} onClick={onCreateAppointment} />
              )}
              {abilities.create_tasks && (
                <IconButton
                  additionalCss={additionalCss}
                  kind={IconButtonKind.default}
                  icon={<ReminderIcon dimension="30" />}
                  onClick={() => context?.onOpenModal(ModalKind.ReminderCreate)}
                />
              )}
              {abilities.edit_pharmacies && (
                <IconButton
                  kind={IconButtonKind.default}
                  additionalCss={pharmacy?.available_for_callcenter === false ? 'text-red-700' : ''}
                  icon={<LockedForCallsIcon dimension="30" />}
                  onClick={() => toggleAvailableForCallCenter(false)}
                />
              )}
              {abilities.edit_pharmacies && (
                <IconButton
                  kind={IconButtonKind.default}
                  icon={<EditIcon dimension="30" />}
                  onClick={() => context?.onOpenModal(ModalKind.PharmacyUpdate)}
                />
              )}
              {abilities.delete_pharmacies && (
                <IconButton
                  kind={IconButtonKind.default}
                  icon={<SwitchOnIcon dimension="30" />}
                  onClick={() => context?.onOpenModal(ModalKind.Pharmacy)}
                />
              )}
              <div className="inline-flex border-l border-gray-400 border-solid w-px h-10 mx-2" />
              <IconButton kind={IconButtonKind.default} icon={<CloseIcon dimension="30" />} onClick={() => context?.onClosePharmacyDetails()} />
            </div>
          </div>
          <div className="hidden xl:flex w-full py-2 px-2 bg-white border-t border-gray-400 overflow-x-auto">
            <TabBar>
              <TabBarItem
                kind={TabBarItemKind.secondary}
                selected={currentPoint === 'info'}
                onClick={() => {
                  executeScroll(infoRef, 'info')
                }}
              >
                {'Info'}
              </TabBarItem>
              <TabBarItem
                kind={TabBarItemKind.secondary}
                selected={currentPoint === 'notizen'}
                onClick={() => {
                  executeScroll(notizenRef, 'notizen')
                }}
              >
                {'Notizen'}
              </TabBarItem>
              <TabBarItem
                kind={TabBarItemKind.secondary}
                selected={currentPoint === 'aufgaben'}
                onClick={() => {
                  executeScroll(aufgabenRef, 'aufgaben')
                }}
              >
                {'Aufgaben'}
              </TabBarItem>
              <TabBarItem
                kind={TabBarItemKind.secondary}
                selected={currentPoint === 'filialen'}
                onClick={() => {
                  executeScroll(filialenRef, 'filialen')
                }}
              >
                {mainBranch ? 'Haupt Fililal' : 'Filialen'}
              </TabBarItem>
              <TabBarItem
                kind={TabBarItemKind.secondary}
                selected={currentPoint === 'termine'}
                onClick={() => {
                  executeScroll(termineRef, 'termine')
                }}
              >
                {'Termine'}
              </TabBarItem>
              <TabBarItem
                kind={TabBarItemKind.secondary}
                selected={currentPoint === 'projekte'}
                onClick={() => {
                  executeScroll(projekteRef, 'projekte')
                }}
              >
                {'Projekte'}
              </TabBarItem>
              <TabBarItem
                kind={TabBarItemKind.secondary}
                selected={currentPoint === 'personen'}
                onClick={() => {
                  executeScroll(projekteRef, 'personen')
                }}
              >
                {'Personen'}
              </TabBarItem>
            </TabBar>
          </div>
        </div>
      }
      showMax
    >
      <div className="relative z-30 bg-white w-100">
        <div className="w-full 2xl:px-6 px-3">
          {pharmacy?.available_for_callcenter === false ? (
            <div className="my-4">
              <div className={`p-2 rounded-lg bg-red-600 shadow-xl sm:p-3`}>
                <div className="flex items-center justify-start">
                  <span className="flex p-2 rounded-lg text-white">
                    <DangerIcon />
                  </span>
                  <p className="ml-2 text-white">{'Callcenter - Sperre aktiv: Dieser Standort darf nicht kontaktiert werden.'}</p>
                </div>
              </div>
            </div>
          ) : null}
          <div className="font-body pr-0 pt-6 pb-2 border-b-4 border-gray-300 last:border-b-0">
            <h2 ref={infoRef} className="text-lg font-medium mb-8">
              {'Allgemeine Informationen'}
            </h2>
            <div className="flex flex-wrap">
              <div className="w-1/2">
                <div className="text-base mb-6 leading-6">
                  {`ID: ${pharmacy?.id || ''} / ${pharmacy?.okid || ''}`}
                  <br />
                  {/* campus: YLJBCP / X-APO */}
                  {`Registrierungscode: ${pharmacy?.campus_registration_code || '-'}`}
                </div>
                <div className="text-base mb-6 pr-6">
                  <span className="block text-sm font-medium leading-6 text-gray-700 tracking-wide uppercase">{'Adresse:'}</span>
                  <div className="flex flex-wrap">
                    <span className="w-full">{pharmacy?.name}</span>
                    <span className="w-full">{pharmacy?.address?.address_name}</span>
                    <span className="w-full">
                      {pharmacy?.address?.zipcode} {pharmacy?.address?.city}
                    </span>
                  </div>
                </div>
                <div className="text-base mb-6">
                  <span className="block text-sm font-medium leading-6 text-gray-700 tracking-wide uppercase">{'Kontakt:'}</span>
                  <div className="flex flex-wrap">
                    <span className="w-full">{pharmacy?.contact_person}</span>
                    <span className="w-full">{`Tel.: ${pharmacy?.phone || ''}`}</span>
                    <span className="w-full">{pharmacy?.email}</span>
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-base mb-6">
                  <Button icon={<ApoCampusIcon />} kind={ButtonKind.outlinedPrimary} onClick={() => console.log()}>
                    {'ApoCampus Mail'}
                  </Button>
                </div>
                <div className="text-base mb-6">
                  <span className="block text-sm font-medium leading-6 text-gray-700 tracking-wide uppercase">{'Quelle:'}</span>
                  <div className="flex flex-wrap">
                    <span className="w-full">{'IQVIA'}</span>
                  </div>
                </div>
                <div className="text-base mb-6">
                  <span className="block text-sm font-medium leading-6 text-gray-700 tracking-wide uppercase">
                    {'Öffnungszeiten(noch auszufüllen ):'}
                  </span>
                  <div className="flex flex-wrap leading-6">{renderOpeningHours()}</div>
                </div>
              </div>
              <div className="flex w-full py-3 pl-4 pr-6 my-2 rounded-md bg-blue-100 text-blue-700 items-center">
                <PhoneIcon />
                <span className="pl-4">{'Letzte Anwahl via CRM: Hier können ihre Daten stehen'}</span>
              </div>
            </div>
          </div>
          <div className="font-body pr-0 pb-2 border-b-4 border-gray-300">
            <h2 ref={notizenRef} className="text-lg font-medium my-6">
              {'Notizen'}
            </h2>
            <NotesCard notes={pharmacy?.notes} showAddButton={abilities.create_notes} />
          </div>
          <div className="font-body pr-0 pb-2 border-b-4 border-gray-300">
            <h2 ref={aufgabenRef} className="text-lg font-medium my-6">
              {'Aufgaben'}
            </h2>
            <NotesCard reminders={pharmacy?.tasks} showAddButton={abilities.create_tasks} />
          </div>
          <div className="font-body pr-0 pb-2 border-b-4 border-gray-300">
            <h2 ref={filialenRef} className="text-lg font-medium my-6">
              {mainBranch ? 'Haupt Fililal' : 'Filialen'}
            </h2>
            <PharmaciesCard
              title={mainBranch ? 'Haupt Fililal' : 'Filialen'}
              pharmacies={mainBranch ? [mainBranch] : pharmacy && pharmacy.branches ? pharmacy.branches : []}
              showAddButton={false}
            />
          </div>
          <div className="font-body pr-0 pb-2 border-b-4 border-gray-300 last:border-b-0">
            <h2 ref={termineRef} className="text-lg font-medium my-6">
              {'Termine'}
            </h2>
            <AppointmentsCard
              projects={projects || []}
              showAllEntries={true}
              title="Kommende Trainingstermine trainig"
              setCurrentAppointment={setCurrentAppointment}
              canEditDelete={abilities.edit_appointments}
              appointments={futureAppointments || []}
              showAddButton={false}
            />

            <AppointmentsCard
              projects={projects || []}
              showAllEntries={true}
              title="Anstehende Besuchstermine"
              setCurrentAppointment={setCurrentAppointment}
              canEditDelete={abilities.edit_appointments}
              appointments={futureVisits || []}
              showAddButton={false}
            />
            <AppointmentsCard
              projects={projects || []}
              showAllEntries={false}
              setCurrentAppointment={setCurrentAppointment}
              canEditDelete={abilities.edit_appointments}
              title="Abgeschlossene Termine"
              appointments={pastAppointments === undefined ? [] : pastAppointments}
              showAddButton={false}
            />
          </div>
          <div className="font-body pr-0 pb-2 border-b-4 border-gray-300 last:border-b-0 last:mb-16">
            <h2 ref={projekteRef} className="text-lg font-medium my-6">
              {'Projekte'}
            </h2>
            <ProjectsCard showAddButton={false} showSpecialButton={true} />
          </div>
          <div className="font-body pr-0 pb-2 border-b-4 border-gray-300 last:border-b-0 last:mb-16">
            <h2 ref={personenRef} className="text-lg font-medium my-6">
              {'Personen'}
            </h2>
            <PersonenCard employees={pharmacy?.employees || []} showAddButton={false} />
          </div>
        </div>
      </div>
    </LeftSidebar>
  )
}
export default PharmacyDataSheetPanel
