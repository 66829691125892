import { DeleteIcon, EditIcon } from '@aposphaere/ui-components'
import React from 'react'

interface WeekNoteSectionProps {
  onUpdate: () => void
  onDelete: () => void
  note: string
}

const WeekNoteSection = ({ onDelete, onUpdate, note }: WeekNoteSectionProps) => (
  <section>
    <div className="pl-1 2xl:pl-4 flex justify-between items-center">
      <span className="w-15pc font-semibold">Notiz:</span>
      <p className=" w-70pc text-gray-900 ml-2 mr-1">{note}</p>

      <div className="text-gray-600 w-15pc flex justify-end">
        <button
          onClick={() => onUpdate()}
          className="flex items-center h-fit px-0.5 py-0.5 rounded-md text-gray-600 hover:bg-blue-200 hover:text-blue-700 focus:border-gray-300 active:text-blue-800 focus:outline-none transition ease-in-out duration-150"
        >
          <EditIcon />
        </button>
        <button
          onClick={() => onDelete()}
          className="flex items-center h-fit px-0.5 py-0.5 rounded-md text-gray-600 hover:bg-blue-200 hover:text-blue-700 focus:border-gray-300 active:text-blue-800 focus:outline-none transition ease-in-out duration-150"
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  </section>
)

export default WeekNoteSection
