import React, { FunctionComponent } from 'react'

export type NavBarProps = {
  logoUrl: string
  logoUrlSmall: string
  logoAlt: string
  children?: React.ReactNode
}

const NavBar: FunctionComponent<NavBarProps> = ({ logoUrl, logoUrlSmall, logoAlt, children }) => (
  <div className="w-full shadow-md bg-blue-600 sticky top-0 z-50">
    <div className="relative z-10 shadow">
      <div className="transition ease-in-out duration-150 h-12 xl:h-14 mx-auto flex justify-between items-center px-2 py-4 xl:px-6 sm:py-2 xl:px-6 md:justify-start">
        <div className="mr-4 xl:mr-8 text-white hidden xl:block">
          <img className="h-9 w-auto" src={`${logoUrl}`} alt={`${logoAlt}`} />
        </div>
        <div className="mr-2 text-white block xl:hidden">
          <img className="h-8 w-auto" src={`${logoUrlSmall}`} alt={`${logoAlt}`} />
        </div>
        <div className="hidden md:flex-1 md:flex md:items-center xl:justify-between md:space-x-2 xl:space-x-12">{children}</div>
      </div>
    </div>
  </div>
)

export default NavBar
