import { Appointment, Project, useAbilities } from '@aposphaere/core-kit'
import { SectionCard } from '@aposphaere/ui-components'
import React, { useState } from 'react'
import AppointmentsCardItem from './AppointmentsCardItem'

export type AppointmentsCardProps = {
  appointments: Appointment[]
  title: string
  showAddButton?: boolean
  showAllEntries: boolean
  canEditDelete?: boolean
  setCurrentAppointment: React.Dispatch<React.SetStateAction<Appointment | null>>
  projects: Project[]
}

const AppointmentsCard: React.FunctionComponent<AppointmentsCardProps> = ({
  setCurrentAppointment,
  appointments,
  title,
  showAddButton,
  showAllEntries,
  canEditDelete,
  projects,
}) => {
  const abilities = useAbilities()
  const [expanded, setExpanded] = useState<boolean>(false)
  return (
    <SectionCard
      isExpanded={expanded}
      title={title}
      badgeValue={appointments.length}
      showAllEntries={showAllEntries}
      showAddButton={showAddButton}
      setExpanded={setExpanded}
    >
      {appointments.length === 0 ? (
        <p className="w-full py-4 text-base text-gray-600 text-center">{'Keine Einträge vorhanden'}</p>
      ) : (
        appointments.map((appointment) => {
          const filteredProjects = projects.filter(({ id }) => appointment.order_items.filter(({ project_id }) => `${project_id}` === `${id}`).length)
          return (
            <AppointmentsCardItem
              projects={filteredProjects}
              canEditAppointment={abilities.edit_appointments}
              canEditDelete={!!canEditDelete}
              setCurrentAppointment={() => setCurrentAppointment(appointment)}
              appointment={appointment}
              key={appointment.id}
            />
          )
        })
      )}
    </SectionCard>
  )
}

export default React.memo(AppointmentsCard)
