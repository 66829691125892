import { Pharmacy, useAbilities } from '@aposphaere/core-kit'
import { DeactivateIcon, EditIcon, IconButton, IconButtonKind, LockedForCallsIcon, PlusSignIcon, ReminderIcon } from '@aposphaere/ui-components'
import moment from 'moment'
import React from 'react'
import { ModalKind } from '../../contexts/crmContext'

interface IQuickActionsInTable {
  pharmacy: Pharmacy
  addAndSet: (pharmacy: Pharmacy, kind: ModalKind) => void
  toggleAvailableForCallCenter: (arg0: boolean, arg1?: Pharmacy) => Promise<void | Pharmacy>
}

const QuickActionsInTable: React.FC<IQuickActionsInTable> = ({ pharmacy, addAndSet, toggleAvailableForCallCenter }) => {
  const additionalCssForReminder = pharmacy.tasks?.filter((task) => moment(new Date(task.until || '')).isSameOrAfter(new Date(), 'day')).length
    ? 'text-yellow-600'
    : ''
  const abilities = useAbilities()
  return (
    <div className="flex justify-center border-l border-solid border-gray-400 pl-1 2x:pl-4">
      {abilities.create_appointments && (
        <IconButton kind={IconButtonKind.default} icon={<PlusSignIcon />} onClick={() => addAndSet(pharmacy, ModalKind.Appointment)} />
      )}
      {abilities.create_tasks && (
        <IconButton
          kind={IconButtonKind.default}
          icon={<ReminderIcon />}
          additionalCss={additionalCssForReminder}
          onClick={() => addAndSet(pharmacy, ModalKind.ReminderCreate)}
        />
      )}
      {abilities.edit_pharmacies && (
        <IconButton
          kind={IconButtonKind.default}
          icon={<LockedForCallsIcon />}
          onClick={() => toggleAvailableForCallCenter(false, pharmacy)}
          additionalCss={pharmacy.available_for_callcenter === false ? 'text-red-700' : ''}
        />
      )}
      {abilities.create_notes && (
        <IconButton kind={IconButtonKind.default} icon={<EditIcon />} onClick={() => addAndSet(pharmacy, ModalKind.NoteCreature)} />
      )}
      {abilities.delete_pharmacies && (
        <IconButton kind={IconButtonKind.default} icon={<DeactivateIcon />} onClick={() => addAndSet(pharmacy, ModalKind.Pharmacy)} />
      )}
    </div>
  )
}

export default React.memo(QuickActionsInTable)
