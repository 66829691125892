import React, { useContext } from 'react'
import { CrmContext } from '../../contexts/crmContext'

const PharmacyClusterSwitcher: React.FC = () => {
  const crmContext = useContext(CrmContext)
  return (
    <select
      onChange={(event) => crmContext?.userPharmacyClusterChange(event.target.value)}
      defaultValue="canada"
      className="w-full form-select font-body h-10 text-blue-800 bg-blue-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-blue-700 focus:border-blue-400"
    >
      {crmContext?.userPharmacyClusters?.map((userPharmacyCluster) => (
        <option key={userPharmacyCluster.pharmacy_cluster.id} value={userPharmacyCluster.pharmacy_cluster.id}>
          {`${userPharmacyCluster.pharmacy_cluster.name || ''} - ${userPharmacyCluster.user.name || ''}`}
        </option>
      ))}
    </select>
  )
}

export default PharmacyClusterSwitcher
