import { filterReducer, IFilterAction, initialFilterState, IFilterState } from './filterReducer'
import { filterOnMapReducer, IOnMapFilterAction, initialOnMapFilterState, IOnMapFilterState } from './filterOnMapReducer'

export const reducers = {
  filterReducer,
  filterOnMapReducer,
}

export const initialsStates = {
  initialFilterState,
  initialOnMapFilterState,
}

export type types = {
  IFilterAction: IFilterAction
  IFilterState: IFilterState
  IOnMapFilterState: IOnMapFilterState
  IOnMapFilterAction: IOnMapFilterAction
}
