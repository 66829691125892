import React, { useContext } from 'react'
import { NotificationBanner } from '@aposphaere/ui-components'
import { CrmContext, ICrmContext } from '../../contexts/crmContext'

const NotificationManager: React.FC = () => {
  const crmContext = useContext(CrmContext) as ICrmContext

  const RenderUsingTimeOut = () => {
    if (crmContext.notification !== undefined) {
      const seconds = crmContext.notification.seconds ? crmContext.notification.seconds : 3

      setTimeout(() => {
        crmContext.clearNotification()
      }, seconds * 1000)

      return (
        <NotificationBanner
          onClick={() => crmContext.clearNotification()}
          headline={crmContext.notification.title}
          subline={crmContext.notification.content}
          kind={crmContext.notification.kind}
        />
      )
    } else {
      return null
    }
  }

  return RenderUsingTimeOut()
}

export default NotificationManager
