import { useLoadScript } from '@react-google-maps/api'
import React, { useContext, useMemo } from 'react'
import { CrmContext, ICrmContext } from '../../contexts/crmContext'
import ActionPanel from '../ActionsPanel'
import ActivePharmaciesViewTable from '../ActivePharmaciesViewTable'
import CalenderPanelAppointment from '../CalendarPanelAppointment'
import CalenderPanel from '../CalenderPanel'
import CustomRouteModal from '../CustomRouteModal'
import Spinner from '../loader.gif'
import PharmacyDataSheetPanel from '../PharmacyDataSheetPanel'
import SimpleGoogleMap from './SimpleGoogleMap'
import { IconButton, IconButtonKind, MaximizeIcon } from '@aposphaere/ui-components'
// make sure that we always use a string a value even when no key is given
export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : ''

type ToggleMaximizeProps = {
  showLeftPane: boolean
  setShowLeftPane: (showPane: boolean) => void
}

const ToggleMaximize: React.FC<ToggleMaximizeProps> = ({ setShowLeftPane, showLeftPane }) => (
  <div className=" z-50 absolute top-24 left-1 bg-white " title="maximize calender view">
    <IconButton kind={IconButtonKind.default} icon={<MaximizeIcon />} onClick={() => setShowLeftPane(!showLeftPane)} />
  </div>
)
const Map: React.FunctionComponent = ({ children }) => {
  const {
    routeHook: { routeResponse, setRouteResponse },
    selectedDayHook: { setSelectedDay, selectedDay },
    routeMap: { sortedAppointmentsToday, pharmaciesHasAppointmentsToday },
    activePharmaciesTableHook: { listViewedActivePharmaciesTable },
    currentlyActivePharmacy,
    leftSideBoardStatus,
    minimizeLeftPaneHook: { showLeftPane, setShowLeftPane },
  } = useContext(CrmContext) as ICrmContext
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
  })

  const leftSideBoard: JSX.Element = useMemo(() => {
    switch (leftSideBoardStatus) {
      case 'appointmentCreation':
        return <CalenderPanelAppointment key={currentlyActivePharmacy?.id || 0} />
      case 'calendar':
        return <CalenderPanel />
      case 'pharmacyDetails':
        return <PharmacyDataSheetPanel />
    }
  }, [currentlyActivePharmacy, leftSideBoardStatus])

  return (
    <React.Fragment>
      {isLoaded}
      {loadError}
      {isLoaded ? (
        <>
          {showLeftPane ? (
            <div className="z-50 relative w-full z-50 min-w-0 max-w-md 2xl:max-w-xl">
              <div className="w-full overflow-auto">{leftSideBoard}</div>
            </div>
          ) : (
            <ToggleMaximize {...{ showLeftPane, setShowLeftPane }} />
          )}
          {listViewedActivePharmaciesTable ? (
            <div className={'sticky px-4 top-0 w-full overflow-auto max-h-screen'}>
              <div className="sticky top-0 z-50 mt-4 mb-5 w-full">
                <ActionPanel />
              </div>
              <ActivePharmaciesViewTable />
            </div>
          ) : (
            <SimpleGoogleMap>
              <React.Fragment>
                <div className="fixed mt-4 mx-2 right-0 w-2/5 2lg:w-1/2 max-w-1024px">
                  <ActionPanel />
                </div>
                {children}
              </React.Fragment>
            </SimpleGoogleMap>
          )}
          {routeResponse && sortedAppointmentsToday && pharmaciesHasAppointmentsToday && (
            <CustomRouteModal
              selectedDay={selectedDay}
              routeResponse={routeResponse}
              setSelectedDay={setSelectedDay}
              sortedAppointmentsToday={sortedAppointmentsToday}
              pharmaciesHasAppointmentsToday={pharmaciesHasAppointmentsToday}
              setRouteResponse={setRouteResponse}
            />
          )}
        </>
      ) : (
        <div className="h-screen w-screen bg-white z-9999 absolute flex items-center justify-center">
          <div className="flex items-center justify-center flex-wrap max-w-48">
            <img className="flex w-24" src={Spinner} alt="" />
            <br />
            <div className="w-full text-center mx-auto flex justify-center font-body mt-6 text-base text-blue-400 leading-5">
              {'Daten werden geladen.'}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Map
