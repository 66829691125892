import { getAppointmentTerminationReasons, AppointmentTerminationReasonsResponse } from '@aposphaere/core-kit'
import { Button, ButtonKind, InputLabel, Modal, ModalKind } from '@aposphaere/ui-components'
import React, { useEffect, useState } from 'react'
import { useAuthenticatedQuery } from '../../hooks/useAuthenticatedQuery'

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirmReason: (reasonID: number) => void
}

const TerminationReasonModal = ({ onClose, isOpen, onConfirmReason }: Props) => {
  const [selectedReasonID, setSelectedReasonID] = useState<number | null>(null)
  const { data } = useAuthenticatedQuery<AppointmentTerminationReasonsResponse>(getAppointmentTerminationReasons)

  const handleSelectTerminationReason = (terminationReasonID: number) => setSelectedReasonID(terminationReasonID)

  useEffect(() => {
    setSelectedReasonID(null)
  }, [isOpen])

  const handleCloseModal = () => {
    onClose()
  }

  const handleConfirmReason = () => {
    if (!selectedReasonID) {
      throw new Error('A termination reason must be selected')
    }
    onConfirmReason(selectedReasonID)
  }

  return isOpen ? (
    <Modal kind={ModalKind.sm} title={'Wählen Sie einen Beendigungsgrund'} onClose={handleCloseModal} onBack={handleCloseModal}>
      <div className="flex flex-col flex-grow">
        <div className="my-2">
          <InputLabel htmlFor="terminationReason">{'Beendigungsgrund:'}</InputLabel>
          <select
            name="terminationReason"
            id="terminationReason"
            value={selectedReasonID ?? ''}
            onChange={(e) => handleSelectTerminationReason(Number(e.target.value))}
            className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
          >
            {data?.termination_reasons ? (
              data.termination_reasons.map((reason) => (
                <option key={reason.id} value={reason.id}>
                  {reason.note_de}
                </option>
              ))
            ) : (
              <option value={0}>Laden...</option>
            )}
          </select>
        </div>
        <div className="flex justify-end mt-3 mb-5">
          <Button kind={ButtonKind.danger} disabled={!selectedReasonID} onClick={handleConfirmReason}>
            {'Termin löschen'}
          </Button>
        </div>
      </div>
    </Modal>
  ) : null
}

export default TerminationReasonModal
