/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  allAppointmentTypes,
  allProjects,
  activeProjects as activeProjectsQuery,
  allQuarters,
  allStatuses,
  ApoClient,
  Appointment,
  Appointments,
  AppointmentType,
  AppointmentTypes,
  formattedDateString,
  getPharmacyByPharmacyCluster,
  getQuarter,
  getUserPharmacyClusters,
  Note,
  Offtime,
  Offtimes,
  Pharmacies,
  Pharmacy,
  PharmacyClustersResponse,
  PharmacyClusterUsers,
  Project,
  Projects,
  Quarter,
  QuarterResponse,
  Quarters,
  Status,
  Statuses,
  useAuth,
  UserAddress,
  userOfftimes,
  getUserAppointmentsByRegion,
  PharmacyResponse,
  IRouteResponse,
  getPharmacyDetails,
  Reminder,
  allTasks,
  RemindersResponse,
  updatePharmacyForCallCenter,
  filterFunctions,
  PharmacyProjectStatus,
  allPharmacyProjectStatuses,
  PharmacyProjectStatuses,
  ActiveProjects,
  getNextPossibleDateQuery,
  getNoteWeekPharmacyClusterAll,
  NoteWeek,
  ILocation,
  PTA,
  getActivities,
  getAppointmentsPerProject,
} from '@aposphaere/core-kit'
import { NotificationBannerKind, OfftimeKind } from '@aposphaere/ui-components'
import { useCallback, useEffect, useMemo, useReducer, useState, useRef } from 'react'
import Notification from '../components/NotificationManager/notification'
import { IPointType } from '../components/PharmacyDataSheetPanel/PharmacyDataSheetTypes'
import { ICrmContext, ModalKind, filterReducer, initialFilterState, filterOnMapReducer, initialOnMapFilterState } from '../contexts/crmContext'
import { ActivitiesResponse, AppointmentsPerProjectResponse } from '../pages/OverviewPageTypes'
/**
 * The concrete implementation of useCrm as a custom Hook.
 */
export const useCrmProvider = (): ICrmContext => {
  const auth = useAuth()
  const client = useMemo(() => new ApoClient(), [])

  const [pharmacies, setPharmacies] = useState<Pharmacy[] | undefined>(undefined)

  const [pharmacyProjectStatuses, setPharmacyProjectStatuses] = useState<PharmacyProjectStatus[] | undefined>(undefined)
  const [selectedPharmacyClusterId, setSelectedPharmacyClusterId] = useState<string | undefined>(undefined)

  const [quarters, setSelectedQuaters] = useState<Quarter[] | undefined>(undefined)
  const [selectedQuarterId, setSelectedQuarterId] = useState<string | undefined>(undefined)
  const [userPharmacyClusters, setUserPharmacyClusters] = useState<PharmacyClusterUsers[] | undefined>(undefined)

  const [selectedQuater, setSelectedQuater] = useState<Quarter | undefined>(undefined)

  const [currentlyOpenedModalKind, setCurrentlyOpenedModalKind] = useState<null | ModalKind>(null)

  const [currentlySelectedNote, setCurrentlySelectedNote] = useState<null | Note>(null)

  const [currentlySelectedReminder, setCurrentlySelectedReminder] = useState<null | Reminder>(null)
  const [currentlySelectedAppointment, setCurrentAppointment] = useState<null | Appointment>(null)

  const [selectedPharmacyId, setSelectedPharmacyId] = useState<number | undefined>(undefined)
  const [currentlyActivePharmacy, setCurrentlyActivePharmacy] = useState<Pharmacy | undefined>(undefined)
  const [pharmacyBranchActive, setPharmacyBranchActive] = useState<boolean>(false)

  const [pharmacyBranchDetails, setPharmacyBranchDetails] = useState<Pharmacy | undefined>()
  const [selectedPharmacyBranchId, setSelectedPharmacyBranchId] = useState<number | undefined>(undefined)

  const [selectedDay, setSelectedDay] = useState<Date | undefined>(undefined)

  const [statuses, setStatuses] = useState<Status[] | undefined>(undefined)

  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[] | undefined>(undefined)

  const [projects, setProjects] = useState<Project[] | undefined>(undefined)
  const [activeProjects, setActiveProjects] = useState<Project[] | undefined>(undefined)

  const [routeResponse, setRouteResponse] = useState<IRouteResponse | null>(null)

  const [listViewedActivePharmaciesTable, setListViewedActivePharmaciesTable] = useState<boolean>(false)

  const [reminders, setReminders] = useState<Reminder[] | null>(null)

  const [isRemindersLoaded, setIsRemindersLoaded] = useState<boolean>(false)

  const [filterState, dispatchFilterState] = useReducer(filterReducer, initialFilterState)

  const [filterOnMapState, dispatchFilterOnMapState] = useReducer(filterOnMapReducer, initialOnMapFilterState)

  const [leftSideBoardStatus, setLeftSideBoardStatus] = useState<'calendar' | 'pharmacyDetails' | 'appointmentCreation'>('calendar')

  const [selectedWeek, setSelectedWeek] = useState<number | undefined>(undefined)

  const [selectedYear, setSelectedYear] = useState<number | undefined>(undefined)

  const [noteWeeks, setNoteWeeks] = useState<NoteWeek[] | undefined>(undefined)

  const [selectedNoteWeek, setSelectedNoteWeek] = useState<NoteWeek | undefined>()

  const [isNoteWeeksLoaded, setIsNoteWeeksLoaded] = useState<boolean>(false)
  const [offtimeType, setOfftimeType] = useState<OfftimeKind | undefined>(undefined)

  const [mapInstance, setMapInstance] = useState<null | { fitBounds: (arg0: any) => void }>(null)

  const [currentlySelectedEmployee, setCurrentlySelectedEmployee] = useState<null | PTA>(null)

  const [currentPoint, setCurrentPoint] = useState<IPointType>('info')
  const [showLeftPane, setShowLeftPane] = useState<boolean>(true)

  const infoRef = useRef<HTMLHeadingElement>(null)
  const notizenRef = useRef<HTMLHeadingElement>(null)
  const aufgabenRef = useRef<HTMLHeadingElement>(null)
  const filialenRef = useRef<HTMLHeadingElement>(null)
  const termineRef = useRef<HTMLHeadingElement>(null)
  const projekteRef = useRef<HTMLHeadingElement>(null)
  const personenRef = useRef<HTMLHeadingElement>(null)

  const windowRef = useRef<HTMLDivElement>(null)

  const fetchNoteWeeks = useCallback(async () => {
    try {
      const noteWeekPharmacyClusterAllResponse = await client.requestWithAuthentication<{
        noteWeekPharmacyClusterAll: NoteWeek[]
      }>(getNoteWeekPharmacyClusterAll, auth.token, {
        pharmacy_cluster_id: selectedPharmacyClusterId,
      })
      console.log('noteWeeks: ', noteWeekPharmacyClusterAllResponse.data?.noteWeekPharmacyClusterAll)
      setNoteWeeks(noteWeekPharmacyClusterAllResponse.data?.noteWeekPharmacyClusterAll)
      setIsNoteWeeksLoaded(true)
    } catch (error) {
      console.debug(`couldn't fetch noteWeeks: ${error as string}`)
    }
  }, [selectedPharmacyClusterId, auth.token, client])

  const fitToMarkers = (map: { fitBounds: (arg0: any) => void }, markers: Pharmacy[]) => {
    markers.forEach((pharmacy, index) => {
      if (map) {
        const bounds = new (window as any).google.maps.LatLngBounds()

        //gradually extends the bounds to include the pharmacies
        //https://groups.google.com/g/google-maps-js-api-v3/c/HyAFbvzoGGo?pli=1
        const pharmacyLongitude = pharmacy.address.longitude
        const pharmacyLatitude = pharmacy.address.latitude
        const pharmacyLngLatPoint = new (window as any).google.maps.LatLng(pharmacyLatitude, pharmacyLongitude)

        bounds.extend(pharmacyLngLatPoint)

        if (index === markers.length - 1 && map) {
          //we have reached the end of the pharmacies markers
          //fit bounds to the map
          //https://stackoverflow.com/questions/3334729/google-maps-v3-fitbounds-zoom-too-close-for-single-marker
          if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
            const extendPoint1 = new (window as any).google.maps.LatLng(
              (bounds.getNorthEast().lat() as number) + 1.3,
              (bounds.getNorthEast().lng() as number) + 1.3,
            )
            const extendPoint2 = new (window as any).google.maps.LatLng(
              (bounds.getNorthEast().lat() as number) - 1.3,
              (bounds.getNorthEast().lng() as number) - 1.3,
            )
            bounds.extend(extendPoint1)
            bounds.extend(extendPoint2)
          }
          map.fitBounds(bounds)
        }
      }
    })
  }

  useEffect(() => {
    fetchNoteWeeks().catch(console.error)
  }, [fetchNoteWeeks])

  const refreshNoteWeeks = () => {
    fetchNoteWeeks().catch(console.error)
  }

  const getNoteWeek = useCallback(
    (week?: number, year?: number) => noteWeeks?.find((noteWeek) => noteWeek.week === week?.toString() && noteWeek.year === year?.toString()),
    [noteWeeks],
  )

  useEffect(() => {
    const foundNoteWeek = noteWeeks?.find((noteWeek) => noteWeek.week === selectedWeek?.toString() && noteWeek.year === selectedYear?.toString())
    setSelectedNoteWeek(foundNoteWeek)
  }, [selectedWeek, selectedYear, noteWeeks])

  const clearPharmacyBranch = useCallback(() => {
    setPharmacyBranchDetails(undefined)
    setSelectedPharmacyBranchId(undefined)
    setPharmacyBranchActive(false)
  }, [])

  const updatePharmacies = useCallback(
    (pharmacy: Pharmacy | undefined, deactivate?: boolean) => {
      if (pharmacy) {
        deactivate
          ? setPharmacies((prevPharmacies) => prevPharmacies?.filter((oldPharmacy) => oldPharmacy.id !== pharmacy.id))
          : setPharmacies((prevPharmacies) => prevPharmacies?.map((oldPharmacy) => (oldPharmacy.id === pharmacy.id ? pharmacy : oldPharmacy)))

        if (filterState.searchResults.length) {
          const updatedFilteredPharmacies = deactivate
            ? filterState.searchResults.filter((filteredPharmacy) => pharmacy.id !== filteredPharmacy.id)
            : filterState.searchResults.map((filteredPharmacy) => (pharmacy.id === filteredPharmacy.id ? pharmacy : filteredPharmacy))
          dispatchFilterState({
            type: 'updateFilterResults',
            payload: { searchResults: updatedFilteredPharmacies },
          })
        }
      }
    },
    [filterState.searchResults],
  )

  useEffect(() => {
    if (selectedPharmacyId) {
      if (selectedPharmacyId !== currentlyActivePharmacy?.id) {
        getFreshPharmacyDetails(selectedPharmacyId, false).then(clearPharmacyBranch).catch(console.error)

        return
      }
    }
    if (selectedPharmacyBranchId) {
      if (selectedPharmacyBranchId !== pharmacyBranchDetails?.id) {
        getFreshPharmacyDetails(selectedPharmacyBranchId, true).catch(console.error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPharmacyId, selectedPharmacyBranchId, currentlyActivePharmacy, pharmacyBranchDetails])

  const [nextPossibleDate, setNextPossibleDate] = useState<string | undefined>(undefined)

  const fetchNextPossibleDate = useCallback(async () => {
    try {
      const nextPossibleDateResponse = await client.requestWithAuthentication<{
        getNextPossibleDate: string
      }>(getNextPossibleDateQuery, auth.token, {
        quarter_id: selectedQuater?.id,
        pharmacy_id: selectedPharmacyId,
      })
      console.log('nextPossibleDate: ', nextPossibleDateResponse.data?.getNextPossibleDate)
      setNextPossibleDate(nextPossibleDateResponse.data?.getNextPossibleDate)
    } catch (error) {
      console.debug(`couldn't fetch appointments: ${error as string}`)
    }
  }, [selectedQuater, selectedPharmacyId, auth.token, client])

  useEffect(() => {
    if (!!selectedQuater?.id && !!selectedPharmacyId) {
      console.log(`selectedQuarterId`, selectedQuater?.id)
      console.log(`selectedPharmacyId`, selectedPharmacyId)
      fetchNextPossibleDate().catch(console.error)
    }
  }, [selectedQuater, selectedPharmacyId, fetchNextPossibleDate])

  const [appointments, setAppointments] = useState<Appointment[] | undefined>(undefined)
  const [temporaryAppointment, setTemporaryAppointment] = useState<Appointment | null>(null)
  const [offtimes, setOfftimes] = useState<Offtime[] | undefined>(undefined)

  const currentUserAddress: UserAddress | undefined = useMemo(
    () => userPharmacyClusters?.find((cluster) => `${cluster.pharmacy_cluster.id}` === `${selectedPharmacyClusterId || ''}`)?.user?.address,
    [userPharmacyClusters, selectedPharmacyClusterId],
  )
  // You need to use useMemo instead of useEffect - in some cases it is required to work out before rendering
  const { sortedAppointmentsToday, pharmaciesHasAppointmentsToday } = useMemo(() => {
    if (selectedDay && appointments && pharmacies) {
      setRouteResponse(null)
      const filteredAppointments = appointments.filter(
        (el) =>
          formattedDateString(new Date(el.date ? new Date(el.date.toString().replace(/\s/, 'T')) : '')) === formattedDateString(selectedDay) &&
          el.pharmacy?.id,
      )
      if (temporaryAppointment) {
        filteredAppointments.push(temporaryAppointment)
      }
      const sortedAppointments = filteredAppointments.sort((a, b) =>
        (a.date ? new Date(a.date.toString().replace(/\s/, 'T')) : '') > (b.date ? new Date(b.date.toString().replace(/\s/, 'T')) : '') ? 1 : -1,
      )

      const idsPharmaciesHasAppointmentsToday = sortedAppointments.map((el) => `${el?.pharmacy?.id || 0}`)

      const pharmaciesHasAppointments = pharmacies.reduce((res: Record<string, Pharmacy>, pharmacy) => {
        if (idsPharmaciesHasAppointmentsToday.includes(`${pharmacy.id}`)) {
          res[`${pharmacy.id}`] = pharmacy
        }
        return res
      }, {})

      return sortedAppointments.length && Object.values(pharmaciesHasAppointments).length
        ? {
            sortedAppointmentsToday: sortedAppointments,
            pharmaciesHasAppointmentsToday: Object.values(pharmaciesHasAppointments),
          }
        : {
            sortedAppointmentsToday: null,
            pharmaciesHasAppointmentsToday: null,
          }
    }
    return {
      sortedAppointmentsToday: null,
      pharmaciesHasAppointmentsToday: null,
    }
  }, [selectedDay, appointments, pharmacies, temporaryAppointment])

  const updatePharmacyProjectStatuses = useCallback(
    (status: PharmacyProjectStatus, addMode: boolean, deleteMode: boolean) => {
      if (pharmacyProjectStatuses) {
        if (addMode) {
          setPharmacyProjectStatuses([...pharmacyProjectStatuses, status])
        } else if (deleteMode) {
          setPharmacyProjectStatuses(pharmacyProjectStatuses.filter((oldStatus) => status.id !== oldStatus.id))
        } else {
          const newPharmacyProjectStatuses = pharmacyProjectStatuses.map((oldStatus: PharmacyProjectStatus) =>
            oldStatus.id === status.id ? status : oldStatus,
          )
          setPharmacyProjectStatuses(newPharmacyProjectStatuses)
        }
      }
    },
    [pharmacyProjectStatuses],
  )

  const zoomToLocation = (location: ILocation, map: { fitBounds: (arg0: any) => void }) => {
    const { longitude, latitude } = location
    console.log('called with', location)

    const bounds = new (window as any).google.maps.LatLngBounds()
    const pharmacyLngLatPoint = new (window as any).google.maps.LatLng(latitude, longitude)
    bounds.extend(pharmacyLngLatPoint)
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      const extendPoint1 = new (window as any).google.maps.LatLng(
        (bounds.getNorthEast().lat() as number) + 0.01,
        (bounds.getNorthEast().lng() as number) + 0.01,
      )
      const extendPoint2 = new (window as any).google.maps.LatLng(
        (bounds.getNorthEast().lat() as number) - 0.01,
        (bounds.getNorthEast().lng() as number) - 0.01,
      )
      bounds.extend(extendPoint1)
      bounds.extend(extendPoint2)
    }
    map.fitBounds(bounds)
  }

  useEffect(() => {
    const fetchQuaters = async () => {
      try {
        const completeQuarters = await client.requestWithAuthentication<Quarters>(allQuarters, auth.token)
        setSelectedQuaters(completeQuarters.data?.quarters)
        completeQuarters.data?.quarters.forEach((quarter) => {
          if (quarter.default) {
            setSelectedQuater(quarter)
          }
        })
      } catch (err) {
        console.debug("Couldn't fetch quareters: ", err)
      }
    }

    const fetchPharmacyProjectStatuses = async () => {
      try {
        const completePharmacyProjectStatuses = await client.requestWithAuthentication<PharmacyProjectStatuses>(
          allPharmacyProjectStatuses,
          auth.token,
        )
        setPharmacyProjectStatuses(completePharmacyProjectStatuses.data?.pharmacyProjectStatuses)
      } catch (err) {
        console.debug("Couldn't fetch quareters: ", err)
      }
    }

    const fetchStatuses = async () => {
      try {
        const statusesResp = await client.requestWithAuthentication<Statuses>(allStatuses, auth.token)
        setStatuses(statusesResp.data?.statuses)
      } catch (err) {
        console.log("Couldn't fetch statuses: ", err)
        console.debug("Couldn't fetch statuses: ", err)
      }
    }

    const fetchAppointmentTypes = async () => {
      try {
        const appooinmentTypes = await client.requestWithAuthentication<AppointmentTypes>(allAppointmentTypes, auth.token)
        setAppointmentTypes(appooinmentTypes.data?.appointmentTypes)
      } catch (err) {
        console.debug("Couldn't fetch AppointmentTypes: ", err)
      }
    }

    const fetchProjects = async () => {
      /*  console.log("Fetching projects") */
      try {
        const projectsResp = await client.requestWithAuthentication<Projects>(allProjects, auth.token)
        const activeProjectsResp = await client.requestWithAuthentication<ActiveProjects>(activeProjectsQuery, auth.token)
        setProjects(projectsResp.data?.projects)
        setActiveProjects(activeProjectsResp.data?.activeProjects)
      } catch (err) {
        console.log("Couldn't fetch Projects: ", err)
        console.debug("Couldn't fetch Projects: ", err)
      }
    }
    if (auth?.token) {
      fetchQuaters().catch(console.error)
      fetchStatuses().catch(console.error)
      fetchAppointmentTypes().catch(console.error)
      fetchProjects().catch(console.error)
      fetchPharmacyProjectStatuses().catch(console.error)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.token])
  const fetchAppointments = useCallback(async () => {
    try {
      const completeAppointments = await client.requestWithAuthentication<Appointments>(getUserAppointmentsByRegion, auth.token, {
        userId: auth.user?.id,
        pharmacyClusterId: selectedPharmacyClusterId,
      })
      /*  console.log("APPOINTMENTS: ", completeAppointments) */
      setAppointments(completeAppointments.data?.appointments)
    } catch (error) {
      console.debug(`couldn't fetch appointments: ${error as string}`)
    }
  }, [auth, client, selectedPharmacyClusterId])

  useEffect(() => {
    if (!selectedPharmacyClusterId) {
      return
    }
    setPharmacies(undefined)
    const fetchPharmaciesInPharmacyCluster = async () => {
      try {
        const pharmaciesWithinPharmacyCluster = await client.requestWithAuthentication<Pharmacies>(getPharmacyByPharmacyCluster, auth.token, {
          pharmacyClusterId: selectedPharmacyClusterId,
        })
        setPharmacies(pharmaciesWithinPharmacyCluster.data?.pharmacies)
      } catch (err) {
        console.debug("Couldn't fetch pharmacies within PharmacyByPharmacyCluster: ", err)
      }
    }

    fetchPharmaciesInPharmacyCluster().catch(console.error)
    fetchAppointments().catch(console.error)
  }, [auth, client, fetchAppointments, selectedPharmacyClusterId])

  useEffect(() => {
    if (!selectedQuarterId) {
      return
    }
    const fetchQuater = async () => {
      try {
        const selectedQuarter = await client.requestWithAuthentication<QuarterResponse>(getQuarter, auth.token, { id: selectedQuarterId })
        if (selectedQuarter.data?.quarter === null) {
          return
        }

        setSelectedQuater(selectedQuarter.data?.quarter)
      } catch (err) {
        console.debug("Couldn't fetch selected region: ", err)
      }
    }
    if (selectedQuarterId && auth.token) {
      fetchQuater().catch(console.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuarterId, auth.token])

  const fetchAppointmentsPerProject = async (quarterId: number, projectId: number) => {
    try {
      const appointmentsPerProject = await client.requestWithAuthentication<AppointmentsPerProjectResponse>(getAppointmentsPerProject, auth.token, { quarterId, projectId })

      return appointmentsPerProject?.data?.appointments_per_project
    } catch (error) {
      console.debug(`couldn't fetch appointments per project: ${error as string}`)
    }
  }

  const fetchActivities = async (quarterId: number) => {
    try {
      const activities = await client.requestWithAuthentication<ActivitiesResponse>(getActivities, auth.token, { quarterId })

      return activities?.data?.activities
    } catch (error) {
      console.debug(`couldn't fetch activities: ${error as string}`)
    }
  }

  const fetchPharmacyDetails = async (id: number, branch: boolean) => {
    try {
      const pharmacyDetails = await client.requestWithAuthentication<PharmacyResponse>(getPharmacyDetails, auth.token, { id })
      branch ? setPharmacyBranchDetails(pharmacyDetails?.data?.pharmacy) : setCurrentlyActivePharmacy(pharmacyDetails?.data?.pharmacy)

      return pharmacyDetails?.data?.pharmacy
    } catch (error) {
      console.debug(`couldn't fetch current pharmacy: ${error as string}`)
    }
  }
  const fetchOfftimes = useCallback(async () => {
    try {
      const completeOfftimes = await client.requestWithAuthentication<Offtimes>(userOfftimes, auth.token, { userId: auth.user?.id })
      setOfftimes(completeOfftimes.data?.offtimes)
    } catch (error) {
      console.log('Region ID: ', selectedPharmacyClusterId)
      console.log(`couldn't fetch offtimes: ${error as string}`)
    }
  }, [auth, client, setOfftimes, selectedPharmacyClusterId])

  const fetchUserPharmacyCluster = useCallback(async () => {
    try {
      const completePharmacyCluster = await client.requestWithAuthentication<PharmacyClustersResponse>(getUserPharmacyClusters, auth.token, {
        userId: auth.user?.id,
      })
      setUserPharmacyClusters(completePharmacyCluster.data?.PharmacyClusterUsers)
      setSelectedPharmacyClusterId(completePharmacyCluster.data?.PharmacyClusterUsers[0].pharmacy_cluster.id.toString())
    } catch (err) {
      console.debug("Couldn't fetch UserPharmacyCluster: ", err)
    }
  }, [setUserPharmacyClusters, setSelectedPharmacyClusterId, auth, client])

  useEffect(() => {
    if (!auth.user?.id) {
      return
    }

    fetchUserPharmacyCluster().catch(console.error)
    fetchOfftimes().catch(console.error)
    fetchReminders().catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, fetchOfftimes])

  const fetchReminders = async () => {
    try {
      const completeReminders = await client.requestWithAuthentication<RemindersResponse>(allTasks, auth.token, { userId: auth.user?.id })
      console.log({ remin: completeReminders })
      setReminders(completeReminders.data?.tasksUser || null)
      setIsRemindersLoaded(true)
    } catch (error) {
      console.debug(`couldn't fetch reminders: ${error as string}`)
    }
  }

  const onCloseModal = useCallback(() => setCurrentlyOpenedModalKind(null), [setCurrentlyOpenedModalKind])

  const refreshAppointments = () => {
    fetchAppointments().catch(console.error)
  }

  const refreshOfftimes = () => {
    fetchOfftimes().catch(console.error)
  }
  const refreshClusters = useCallback(() => {
    fetchUserPharmacyCluster().catch(console.error)
  }, [fetchUserPharmacyCluster])

  const refetchReminders = () => {
    fetchReminders().catch(console.error)
  }

  const getFreshAppointmentsPerProject = (quarterId: number, projectId: number) => fetchAppointmentsPerProject(quarterId, projectId)

  const getFreshActivities = (quarterId: number) => fetchActivities(quarterId)

  const getFreshPharmacyDetails = (id: number, branch: boolean) => fetchPharmacyDetails(id, branch)

  const toggleAvailableForCallCenter = async (branch: boolean, pharm?: Pharmacy | undefined) => {
    const pharmacy: Pharmacy | undefined = pharm ? pharm : branch ? pharmacyBranchDetails : currentlyActivePharmacy
    const availableForCallCenter = pharmacy?.available_for_callcenter !== false
    try {
      await client.requestWithAuthentication<PharmacyResponse>(updatePharmacyForCallCenter, auth.token, {
        id: pharmacy?.id,
        available_for_callcenter: !availableForCallCenter,
      })
      const updatedPharmacy = await getFreshPharmacyDetails(pharmacy?.id || 0, branch)
      updatePharmacies(updatedPharmacy)
      presentNotification({
        title: updatedPharmacy?.available_for_callcenter
          ? ' Diese Apotheke ist jetzt für das Callcenter aktiviert.'
          : 'Diese Apotheke ist jetzt für das Callcenter deaktiviert.',
        content: '',
        kind: NotificationBannerKind.success,
      })
      return updatedPharmacy
    } catch (error) {
      console.debug(`couldn't toggle available status for call center: ${error as string}`)
    }
  }

  const arrayOfFilters = useMemo<Array<(arg: Pharmacy) => boolean>>(() => {
    const filters: Array<(arg: Pharmacy) => boolean> = []
    const { getFilterByProjectsFunction, getFilterByFutureAppointments, getFilterByCanceledAppointment } = filterFunctions
    const { selectedProjects, onActiveProject, onPotential, onPriority } = filterOnMapState

    if (selectedProjects.length) {
      filters.push(getFilterByProjectsFunction(filterOnMapState.selectedProjects, filterOnMapState.optionAndActive))
    }
    if (onActiveProject) {
      filters.push(getFilterByFutureAppointments(appointments || []))
    }
    if (onPotential) {
      filters.push(getFilterByProjectsFunction(activeProjects || []))
    }
    if (onPriority) {
      filters.push(getFilterByCanceledAppointment(appointments || [], statuses || []))
    }
    return filters
  }, [appointments, filterOnMapState, statuses, activeProjects])

  const selectedPharmacies = useMemo<Pharmacy[]>(() => {
    if (arrayOfFilters.length && pharmacies?.length) {
      return pharmacies.filter((pharmacy) => arrayOfFilters.reduce((acc: boolean, filterFunction) => (acc ? acc : filterFunction(pharmacy)), false))
    }
    return []
  }, [pharmacies, arrayOfFilters])

  const onProjectClick = (project: Project) => {
    const { selectedProjects } = filterOnMapState
    if (selectedProjects.some((pr) => pr.id === project.id)) {
      const filtered = selectedProjects.filter((el) => el.id !== project.id)
      dispatchFilterOnMapState({
        type: 'setSelectedProjects',
        payload: { selectedProjects: filtered },
      })
    } else {
      dispatchFilterOnMapState({
        type: 'setSelectedProjects',
        payload: { selectedProjects: [...selectedProjects, project] },
      })
    }
  }

  const onAllProjectClick = () => {
    const { selectedProjects } = filterOnMapState
    if (selectedProjects.length === activeProjects?.length) {
      dispatchFilterOnMapState({
        type: 'setSelectedProjects',
        payload: { selectedProjects: [] },
      })
    } else {
      const newProjectsList = activeProjects || []
      dispatchFilterOnMapState({
        type: 'setSelectedProjects',
        payload: { selectedProjects: newProjectsList },
      })
    }
  }

  useEffect(() => {
    if (pharmacies) {
      dispatchFilterState({ type: 'setPharmacies', payload: { pharmacies } })
    }
  }, [pharmacies])

  const onOpenPharmacyDetails = () => {
    setLeftSideBoardStatus('pharmacyDetails')
  }
  const onClosePharmacyDetails = () => setLeftSideBoardStatus('calendar')

  const [notification, setNotification] = useState<Notification | undefined>()

  const presentNotification = useCallback((newNotification: Notification) => {
    setNotification(newNotification)
  }, [])

  const clearNotification = useCallback(() => {
    setNotification(undefined)
  }, [])

  const currentPharmacyProjectStatuses = useMemo<PharmacyProjectStatus[]>(() => {
    if (currentlyActivePharmacy && pharmacyProjectStatuses) {
      return pharmacyProjectStatuses.filter((status: PharmacyProjectStatus) => `${status.pharmacy_id}` === `${currentlyActivePharmacy.id}`)
    }
    return []
  }, [currentlyActivePharmacy, pharmacyProjectStatuses])

  return {
    isRemindersLoaded,
    clearNotification,
    notification,
    presentNotification,
    onProjectClick,
    onAllProjectClick,
    selectedPharmacies,
    appointments,
    offtimes,
    pharmacies,
    selectedQuater,
    userPharmacyClusters,
    userPharmacyClusterChange: setSelectedPharmacyClusterId,
    selectedPharmacyClusterId,
    quarters,
    onQuarterChange: setSelectedQuarterId,
    currentlyOpenedModalKind,
    onOpenModal: setCurrentlyOpenedModalKind,
    onCloseModal,
    currentlyActivePharmacy,
    setActivePharmacy: setCurrentlyActivePharmacy,
    statuses,
    appointmentTypes,
    projects,
    activeProjects,
    refreshAppointments,
    onOpenPharmacyDetails,
    onClosePharmacyDetails,
    getFreshPharmacyDetails,
    getFreshActivities,
    getFreshAppointmentsPerProject,
    selectedPharmacyId,
    setSelectedPharmacyId,
    selectNoteHook: { currentlySelectedNote, setCurrentlySelectedNote },
    selectReminderHook: {
      currentlySelectedReminder,
      setCurrentlySelectedReminder,
    },
    selectedDayHook: { selectedDay, setSelectedDay },
    routeMap: { sortedAppointmentsToday, pharmaciesHasAppointmentsToday },
    appointmentHook: { currentlySelectedAppointment, setCurrentAppointment },
    routeHook: { routeResponse, setRouteResponse },
    currentUserAddress,
    remindersHook: { reminders, refetchReminders },
    selectPharmacyBranchHook: {
      pharmacyDetails: pharmacyBranchDetails,
      setSelectedPharmacyBranchId,
      selectedPharmacyBranchId,
      clear: clearPharmacyBranch,
      pharmacyBranchActive,
      setPharmacyBranchActive,
    },
    updatePharmacies,
    activePharmaciesTableHook: {
      listViewedActivePharmaciesTable,
      setListViewedActivePharmaciesTable,
    },
    searchFilterHook: { filterState, dispatchFilterState },
    filterOnMapHook: { filterOnMapState, dispatchFilterOnMapState },
    toggleAvailableForCallCenter,
    pharmacyProjectStatusesHook: {
      updatePharmacyProjectStatuses,
      pharmacyProjectStatuses,
      currentPharmacyProjectStatuses,
    },
    appointmentCreationHook: { setTemporaryAppointment },
    setLeftSideBoardStatus,
    leftSideBoardStatus,
    nextPossibleDate,
    selectedWeek,
    selectedYear,
    setSelectedWeek,
    setSelectedYear,
    noteWeeks,
    getNoteWeek,
    selectedNoteWeek,
    refreshNoteWeeks,
    isNoteWeeksLoaded,
    setOfftimeType,
    offtimeType,
    refreshOfftimes,
    refreshClusters,
    zoomToLocation,
    mapInstance,
    setMapInstance,
    selectEmployeeHook: { currentlySelectedEmployee, setCurrentlySelectedEmployee },
    fitToMarkers,
    currentPointHook: { currentPoint, setCurrentPoint },
    infoRef,
    notizenRef,
    aufgabenRef,
    filialenRef,
    termineRef,
    projekteRef,
    personenRef,
    windowRef,
    minimizeLeftPaneHook: {
      showLeftPane,
      setShowLeftPane,
    },
  }
}
