import React, { FunctionComponent } from 'react'

export type WeekItemProps = {
  weekNumber: number
  children?: React.ReactNode
  appendComponent?: React.ReactNode
}

const WeekItem: FunctionComponent<WeekItemProps> = ({ appendComponent, children, weekNumber }) => {
  const dividerPart1 = <div className="h-1 bg-gray-300 w-placeholder-kw -ml-6"></div>
  const dividerPart2 = <div className="flex h-1 bg-gray-300 w-full m-1 "></div>
  const dividerPart3 = <div className="flex h-1 bg-gray-300 w-full m-1"></div>
  const dividerPart4 = <div className="h-1 bg-gray-300 w-5pc flex ml-1"></div>

  return (
    <>
      <div className="flex h-8 items-center row ml-6 pt-2 bg-white relative">
        {dividerPart1}
        <div className="flex items-center h-8 w-80pc ml-1">
          {dividerPart2}
          <div className="flex items-center bg-white h-5 px-2 text-gray-400 text-center font-medium -mt-1">
            <span className="pr-2">KW</span>
            {weekNumber}
            {appendComponent ? appendComponent : null}
          </div>
          {dividerPart3}
        </div>
        {dividerPart4}
      </div>
      {children}
    </>
  )
}

export default WeekItem
