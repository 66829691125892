import { createPharmacyProjectStatus, deletePharmacyProjectStatus, PharmacyProjectStatus, updatePharmacyProjectStatus } from '@aposphaere/core-kit'
import { Button, ButtonKind, InputLabel, Modal, ModalKind, NotificationBannerKind } from '@aposphaere/ui-components'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { CrmContext, ICrmContext } from '../../contexts/crmContext'
import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'

interface ISelectedStatus {
  project_id: number
  status_id: number
}

interface IResponse {
  updatePharmacyProjectStatus?: PharmacyProjectStatus
  createPharmacyProjectStatus?: PharmacyProjectStatus
  deletePharmacyProjectStatus?: PharmacyProjectStatus
}

const ProjectsModal: React.FC = () => {
  const {
    onCloseModal,
    activeProjects,
    currentlyActivePharmacy,
    statuses,
    pharmacyProjectStatusesHook: { updatePharmacyProjectStatuses, currentPharmacyProjectStatuses },
    presentNotification,
  } = useContext(CrmContext) as ICrmContext
  const [selectedStatus, setSelectedStatus] = useState<ISelectedStatus | null>(null)

  const updatePharmacyProjectStatusMutation = useAuthenticatedMutation<IResponse>(updatePharmacyProjectStatus)
  const deletePharmacyProjectStatusMutation = useAuthenticatedMutation<IResponse>(deletePharmacyProjectStatus)
  const createPharmacyProjectStatusMutation = useAuthenticatedMutation<IResponse>(createPharmacyProjectStatus)

  const getMutationWithVariables = useCallback(
    (settledStatus: ISelectedStatus) => {
      const currentStatus = currentPharmacyProjectStatuses.find((status) => `${status.project_id}` === `${settledStatus.project_id}`)
      const deleteMode = !!currentStatus && settledStatus.status_id === 13
      const mutation = currentStatus
        ? deleteMode
          ? deletePharmacyProjectStatusMutation
          : updatePharmacyProjectStatusMutation
        : createPharmacyProjectStatusMutation
      const variables = deleteMode
        ? { id: currentStatus?.id }
        : {
            project_id: `${settledStatus?.project_id}`,
            pharmacy_id: `${currentlyActivePharmacy?.id || ''}`,
            status_id: `${settledStatus?.status_id}`,
            id: currentStatus?.id,
          }
      return { mutation, variables, addMode: !currentStatus, deleteMode }
    },
    [
      createPharmacyProjectStatusMutation,
      currentPharmacyProjectStatuses,
      currentlyActivePharmacy,
      deletePharmacyProjectStatusMutation,
      updatePharmacyProjectStatusMutation,
    ],
  )
  useEffect(() => {
    if (selectedStatus) {
      const doMutation = async () => {
        const { mutation, variables, addMode, deleteMode } = getMutationWithVariables(selectedStatus)
        const response = await mutation(variables)
        if (response.errors !== undefined) {
          alert(response.errors)
          throw new Error('updatePharmacyProjectStatusMutation is failed')
        }
        setSelectedStatus(null)
        const data =
          response.data?.updatePharmacyProjectStatus || response.data?.createPharmacyProjectStatus || response.data?.deletePharmacyProjectStatus
        if (data) {
          updatePharmacyProjectStatuses(data, addMode, deleteMode)
        }
        presentNotification({
          title: 'Das Projekt wurde erfolgreich aktualisiert',
          content: '',
          kind: NotificationBannerKind.success,
        })
      }
      doMutation().catch(console.warn)
    }
  }, [getMutationWithVariables, presentNotification, updatePharmacyProjectStatuses, selectedStatus])

  return (
    <Modal kind={ModalKind.ms} title={'Projekte bearbeiten'} onClose={onCloseModal} onBack={() => null}>
      <div className="flex flex-wrap w-full">
        <div className="flex flex-wrap w-full">
          <div className="w-full p-4">
            <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 pb-2 mt-4">
              <div className="sm:col-span-3">
                <InputLabel>{'Produktname:'}</InputLabel>
              </div>
              <div className="sm:col-span-3">
                <InputLabel>{'Status:'}</InputLabel>
              </div>
            </div>
            <ul className="w-full">
              {activeProjects?.map((project) => {
                const selectedStatusId =
                  `${project.id}` === `${selectedStatus?.project_id || ''}`
                    ? selectedStatus?.status_id
                    : currentPharmacyProjectStatuses.find((status) => `${status.project_id}` === `${project.id}`)?.status.id || 13
                return (
                  <li
                    key={project.name}
                    className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 border-b border-gray-400 last:border-0 py-2.5"
                  >
                    <div className="sm:col-span-3 flex items-center">
                      <div className="flex w-full rounded-md">{project.name}</div>
                    </div>
                    <div className="sm:col-span-3">
                      <div className="flex w-full rounded-md">
                        <select
                          onChange={(event) => setSelectedStatus({ project_id: project.id, status_id: parseInt(event.currentTarget.value, 10) })}
                          value={selectedStatusId}
                          className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400"
                        >
                          {statuses?.map((status) => (
                            <option key={status.id} value={status.id} disabled={!!selectedStatus}>
                              {status.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-end p-4 pt-8 pb-6 place-items-stretch">
          <Button kind={ButtonKind.primary} onClick={onCloseModal}>
            {'Schliessen'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ProjectsModal
