import { gql } from 'graphql-request'

export const getUserPharmacyClusters = gql`
  query getPharmacyClusters($userId: ID!) {
    PharmacyClusterUsers(user_id: $userId) {
      pharmacy_cluster {
        name
        id
        pharmacy_clusterscode
      }
      user {
        id
        name
        address {
          address_name
          city
          zipcode
          longitude
          latitude
        }
      }
    }
  }
`
