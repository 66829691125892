import React, { FunctionComponent } from 'react'
import IconButton, { IconButtonKind } from '../IconButton'
import { CloseIcon, DangerIcon, DoneIcon, NoteAttentionIcon } from '../Icons'

export enum NotificationBannerKind {
  danger = 'bg-red-600',
  info = 'bg-gray-700',
  success = 'bg-green-600',
}

export type NotificationBannerProps = {
  kind: NotificationBannerKind
  onClick: () => void
  headline?: React.ReactNode
  subline?: React.ReactNode
}

const NotificationBanner: FunctionComponent<NotificationBannerProps> = ({ kind = NotificationBannerKind.danger, headline, subline, onClick }) => (
  <div className="fixed bottom-8 inset-x-0 pb-2 sm:pb-5 font-body z-9999 lg:w-2/6 lg:left-2/4">
    <div className="max-w-screen-lg mx-auto px-2 sm:px-6 lg:px-8">
      <div className={`p-2 rounded-lg ${kind} shadow-xl sm:p-3`}>
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-full max-w-lg flex-1 flex items-center">
            <span className="flex p-2 rounded-lg text-white">
              {kind === NotificationBannerKind.danger ? <DangerIcon /> : ''}
              {kind === NotificationBannerKind.info ? <NoteAttentionIcon /> : ''}
              {kind === NotificationBannerKind.success ? <DoneIcon /> : ''}
            </span>
            <p className="ml-3 text-white">
              <span className="text-base font-medium leading-6">{headline}</span>
              <br />
              <span className="text-base font-normal mt-1">{subline}</span>
            </p>
          </div>
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2 text-white">
            <IconButton kind={IconButtonKind.custom} additionalCss="text-white" icon={<CloseIcon />} onClick={onClick} />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default NotificationBanner
