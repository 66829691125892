import { gql } from 'graphql-request'

export const createOfftime = gql`
  mutation($offtime_type: String!, $whole_day: Boolean!, $day_parts: [ID], $date: DateTimeUtc!) {
    createOfftime(offtime_type: $offtime_type, whole_day: $whole_day, day_parts: $day_parts, date: $date) {
      id
      offtime_type
    }
  }
`

export const deleteOfftime = gql`
  mutation($id: ID!, $date: DateTimeUtc) {
    deleteOfftime(id: $id, date: $date) {
      id
    }
  }
`
