import { gql } from 'graphql-request'

export const allAppointments = gql`
  query getUserAppointmentsByPharmacyCluster($userId: ID) {
    appointments(user_id: $userId) {
      id
      date
      note
      pharmacy {
        id
      }
      status {
        id
      }

      quarter {
        id
      }
      appointmentType {
        id
      }
      status {
        id
      }
      creator {
        id
      }
      lastEditor {
        id
      }
    }
  }
`

export const getUserAppointmentsByRegion = gql`
  query getUserAppointmentsByPharmacyCluster($userId: ID, $pharmacyClusterId: ID) {
    appointments(user_id: $userId, pharmacy_cluster_id: $pharmacyClusterId) {
      id
      date
      note
      pharmacy {
        name
        id
        okid
        address {
          latitude
          longitude
          address_name
          city
          zipcode
        }
      }
      status {
        id
      }
      quarter {
        id
      }
      appointmentType {
        id
      }
      status {
        id
      }
      creator {
        id
        name
      }
      lastEditor {
        id
      }
      order_items {
        project_id
        amount
      }
      status_id
    }
  }
`

export const getNextPossibleDateQuery = gql`
  query getNextPossibleDate($quarter_id: ID, $pharmacy_id: ID) {
    getNextPossibleDate(quarter_id: $quarter_id, pharmacy_id: $pharmacy_id)
  }
`
