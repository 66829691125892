import { debounce } from '@aposphaere/core-kit'
import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'

interface IInputWithValidation {
  onChangeCheck: (value: string) => boolean
  schema: Yup.StringSchema<string | undefined>
  placeholder: string
  getErrorOrSetValue: (value: string, bool: boolean) => string | void
  debounceTime: number
  disabled: boolean
  initialValue?: string
}

const InputWithValidation: React.FC<IInputWithValidation> = ({
  initialValue,
  disabled,
  onChangeCheck,
  schema,
  placeholder,
  getErrorOrSetValue,
  debounceTime,
}) => {
  const [value, setValue] = useState<string>('')
  const [inputError, setInputError] = useState<string>('')
  const [inValue, setInValue] = useState<string>('')

  const handleTimeValidate = useCallback(
    debounce((data: string) => {
      const error: string | void = getErrorOrSetValue(data, schema.isValidSync(data))
      if (error) {
        return setInputError(error)
      }
    }, debounceTime),
    [debounce, getErrorOrSetValue, schema, debounceTime],
  )

  const handleTimeSet = useCallback(
    (text: string) => {
      if (onChangeCheck(text)) {
        setInValue('')
        setValue(text)
        setInputError('')
        handleTimeValidate(text)
      }
    },
    [handleTimeValidate, onChangeCheck],
  )

  useEffect(() => {
    if (inValue) {
      handleTimeSet(inValue)
    }
  }, [handleTimeSet, inValue])

  useEffect(() => {
    if (initialValue) {
      setInValue(initialValue)
      setInputError('')
    }
  }, [initialValue])

  return (
    <>
      <input
        value={inValue || value}
        onChange={(event) => handleTimeSet(event.currentTarget.value)}
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        className={`form-input font-body block w-full h-10
        bg-gray-100 rounded-md px-4 py-2 text-base
        leading-6 border outline-none focus:shadow-focus
        focus:border-4 border-solid border-gray-400 focus:border-blue-400
        sm:text-base sm:leading-5`}
      />
      {inputError && <p className={'mt-1 font-body text-xs text-red-600 md:text-sm'}>{inputError}</p>}
    </>
  )
}

export default InputWithValidation
