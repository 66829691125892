import { AuthProvider } from '@aposphaere/core-kit'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css'
import Event from './components/events'
import ModalContainer from './components/ModalContainer'
import NotificationManager from './components/NotificationManager'
import CrmProvider from './contexts/crmContext'
import OverviewProvider from './contexts/overviewContext'
import MainLayout from './layouts/MainLayout'
import AddressPage from './pages/AddressPage'
import LoginPage from './pages/LoginPage'
import OverviewPage from './pages/OverviewPage'
import ReminderItemsPage from './pages/ReminderItemsPage'
import StartPage from './pages/StartPage'
import ViewportProvider from './contexts/viewPort'

const App: React.FC = () => (
  <AuthProvider>
    <CrmProvider>
      <ViewportProvider>
        <Router>
          <Event />
          <NotificationManager />
          <ModalContainer />
          <MainLayout>
            <Switch>
              <Route path="/" exact component={StartPage} />
              <Route path="/reminders" exact component={ReminderItemsPage} />
              <Route path="/addresses" exact component={AddressPage} />
              <Route path="/login" exact component={LoginPage} />
              <OverviewProvider>
                <Route path="/overview" exact component={OverviewPage} />
              </OverviewProvider>
            </Switch>
          </MainLayout>
        </Router>
      </ViewportProvider>
    </CrmProvider>
  </AuthProvider>
)

export default App
