import { Appointment, ILeg, IRouteResponse, svgToDataUri, UserAddress } from '@aposphaere/core-kit'
import { DirectionsRenderer, DirectionsService, Polyline } from '@react-google-maps/api'
import React, { memo } from 'react'
import HomeMarker from './HomeMarker'
import { MapMarker } from './MapMarker'

interface ICustomDirectionsRenderer {
  sortedAppointmentsToday: Appointment[]
  origin?: UserAddress | undefined
  setRouteResponse: React.Dispatch<React.SetStateAction<IRouteResponse | null>>
  routeResponse: IRouteResponse | null
}

interface IDestination {
  lat: number
  lng: number
}

// TODO change mock colors for polilines
export const arrayOfColors = ['#C6A8FF', '#FF50A1', '#FFBC24', '#50DDFF', '#BCFF50', '#6034A3', '#AC0035', '#E9EEF4', '#1a202c', '#D92656', '#E5B4C7']
export const arrayOfColorsTailwind = [
  'purple-200',
  'red-500',
  'yellow-600',
  'blue-300-hi',
  'green-300-hi',
  'purple-700',
  'red-800',
  'gray-300',
  'gray-900',
  'red-600',
  'red-400',
]

const CustomDirectionsRenderer: React.FC<ICustomDirectionsRenderer> = ({
  sortedAppointmentsToday,
  origin = {
    address_name: 'Home',
    city: 'Berlin',
    zipcode: '246045',
    latitude: 49.23923998075661,
    longitude: 11.376917311914081,
  },
  setRouteResponse,
  routeResponse,
}) => {
  const directionsCallback = (res: IRouteResponse) => {
    if (res !== null) {
      if (res.status === 'OK') {
        setRouteResponse(res)
      } else {
        console.error('response: ', res)
      }
    }
  }
  const destinations: IDestination[] = sortedAppointmentsToday.map((appointment) => ({
    lat: appointment.pharmacy?.address?.latitude || 0,
    lng: appointment.pharmacy?.address?.longitude || 0,
  }))
  const waypoints = destinations.map((destination) => ({ location: destination }))
  return (
    <>
      {destinations.length !== 0 && routeResponse === null && origin && (
        <DirectionsService
          options={{
            destination: { lng: origin.longitude, lat: origin.latitude },
            origin: { lng: origin.longitude, lat: origin.latitude },
            travelMode: 'DRIVING',
            waypoints,
          }}
          callback={directionsCallback}
        />
      )}

      {routeResponse !== null && (
        <>
          <DirectionsRenderer
            options={{
              directions: routeResponse,
              suppressMarkers: true,
              suppressInfoWindows: true,
              suppressPolylines: true,
            }}
          />
          {routeResponse.routes[0].legs
            .filter((leg: ILeg) => !!leg.duration.value)
            .map((leg: ILeg, index: number) =>
              leg.steps.map((step) => (
                <Polyline
                  key={step.end_point.lat()}
                  path={step.path.map((dot) => ({ lat: dot.lat(), lng: dot.lng() }))}
                  visible={true}
                  options={{ strokeColor: arrayOfColors[index] || '#C6A8FF', strokeWeight: 6, strokeOpacity: 0.6 }}
                />
              )),
            )}
          <MapMarker
            key={'home'}
            id={(origin.longitude || 0) + (origin.latitude || 0)}
            long={origin.longitude || 0}
            lat={origin.latitude || 0}
            icon={svgToDataUri(HomeMarker())}
            onClick={() => null}
          />
        </>
      )}
    </>
  )
}

export default memo(CustomDirectionsRenderer)
