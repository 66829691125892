import {
  Button,
  ButtonKind,
  InputLabel,
  InputMessage,
  InputMessageKind,
  Modal,
  ModalKind,
  TextAreaInput,
  TextInput,
  NotificationBannerKind,
} from '@aposphaere/ui-components'
import { createReminder, Reminder, deleteReminder, updateReminder, useAuth, formattedDateString } from '@aposphaere/core-kit'

import { Formik } from 'formik'
import React, { useContext } from 'react'
import * as Yup from 'yup'
import { CrmContext, ICrmContext } from '../../contexts/crmContext'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'

type ReminderFormValues = {
  pharmacy_id?: number
  user_id?: number
  state?: string
  contact_person?: string
  until?: Date | string
  note?: string
}

interface INoteModal {
  editing?: boolean
  deletion?: boolean
  creation?: boolean
  reminder?: Reminder
}

const ReminderModal: React.FC<INoteModal> = ({ deletion = false, editing = false }) => {
  const context = useContext(CrmContext) as ICrmContext
  const auth = useAuth()
  const {
    selectReminderHook: { currentlySelectedReminder, setCurrentlySelectedReminder },
    remindersHook: { refetchReminders },
    currentlyActivePharmacy,
    selectPharmacyBranchHook: { setPharmacyBranchActive, pharmacyBranchActive, pharmacyDetails },
    updatePharmacies,
  } = context

  const deleteNoteMutation = useAuthenticatedMutation(deleteReminder)
  const updateNoteMutation = useAuthenticatedMutation(updateReminder)
  const createNoteMutation = useAuthenticatedMutation(createReminder)

  const getMutationWithVariables = (values: ReminderFormValues) => {
    const defaultVariables = {
      pharmacy_id: values.pharmacy_id,
      user_id: auth.user?.id,
      state: values.state,
      contact_person: values.contact_person,
      until: values.until,
      note: values.note,
    }
    const mutation = deletion ? deleteNoteMutation : editing ? updateNoteMutation : createNoteMutation
    const variables = deletion
      ? { id: currentlySelectedReminder?.id }
      : editing
      ? {
          ...defaultVariables,
          id: currentlySelectedReminder?.id,
        }
      : defaultVariables
    return { mutation, variables }
  }

  const activePharmacy = pharmacyBranchActive ? pharmacyDetails : currentlyActivePharmacy

  const intialFormValues: ReminderFormValues = {
    /* initialformvalue */
    pharmacy_id: activePharmacy ? activePharmacy.id : undefined,
    user_id: auth ? auth.user?.id : undefined,
    state: currentlySelectedReminder?.state || 'aktiv',
    contact_person: currentlySelectedReminder?.contact_person || '',
    until: currentlySelectedReminder?.until?.toString().replace(/\s/, 'T') || undefined,
    note: currentlySelectedReminder?.note || '',
  }

  /* German localisation  */
  const MONTHS = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
  const WEEKDAYS_LONG = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
  const WEEKDAYS_SHORT = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']

  const ReminderValidationScheme = Yup.object().shape({
    note: Yup.string().required('Pflichtfeld'),
    contact_person: Yup.string().required('Pflichtfeld'),
    until: Yup.date().required('Pflichtfeld'),
  })

  const onFormSubmit = async (values: ReminderFormValues) => {
    values.until = values.until ? new Date(values.until).toISOString() : values.until
    const { mutation, variables } = getMutationWithVariables(values)

    const reminderResponse = await mutation(variables)
    if (reminderResponse.errors !== undefined) {
      alert(reminderResponse.errors)
      return
    }
    const { pharmacy_id = 0 } = values

    refetchReminders()
    const updatedPharmacy = await context?.getFreshPharmacyDetails(pharmacy_id, pharmacyBranchActive)
    updatePharmacies(updatedPharmacy)
    if (pharmacyBranchActive) {
      setPharmacyBranchActive(false)
    }
    const { notificationText } = getTextObject()

    closeModal()
    context.presentNotification({
      title: notificationText,
      content: '',
      kind: NotificationBannerKind.success,
    })
  }

  const closeModal = () => {
    context.onCloseModal()
    setCurrentlySelectedReminder(null)
  }

  const getTextObject = () => {
    if (deletion) {
      return {
        titleModalText: 'Wiedervorlage löschen',
        submitButtonText: 'Löschen bestätigen',
        notificationText: 'Wiedervorlage erfolgreich gelöscht',
      }
    } else if (editing) {
      return {
        titleModalText: 'Wiedervorlage bearbeiten',
        submitButtonText: 'Änderungen speichern',
        notificationText: 'Wiedervorlage erfolgreich bearbeitet.',
      }
    }
    return {
      titleModalText: 'Wiedervorlage anlegen',
      submitButtonText: 'Wiedervorlage anlegen',
      notificationText: 'Wiedervorlage erfolgreich erstellt',
    }
  }

  const { id = 0, name = 'NAME', okid = 'okid' } = activePharmacy || {}

  return (
    <Modal kind={ModalKind.sm} title={getTextObject().titleModalText} onClose={closeModal} onBack={() => null}>
      <Formik initialValues={intialFormValues} onSubmit={onFormSubmit} validationSchema={ReminderValidationScheme}>
        {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
          <div className="flex flex-wrap w-full">
            <div className="flex flex-wrap w-full">
              <div className="w-full p-4">
                <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <InputLabel>{'Standort:'}</InputLabel>
                    <div className="mt-1 text-base font-body">{`${name} (ID: ${id} / ${okid})`}</div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'Status:'}</InputLabel>
                    <div className="flex w-full mt-1 rounded-md">
                      <select
                        disabled={deletion}
                        value={values.state}
                        className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400"
                        onChange={(event: React.FormEvent<HTMLSelectElement>) => setFieldValue('state', event.currentTarget.value)}
                      >
                        <option value="aktiv">{'aktiv'}</option>
                        <option value="inaktiv">{'inaktiv'}</option>
                      </select>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'Datum für Wiedervorlage:'}</InputLabel>
                    <div className="flex w-full mt-1 rounded-md">
                      <DayPickerInput
                        formatDate={formattedDateString}
                        placeholder={'DD.MM.YYYY'}
                        dayPickerProps={{
                          disabledDays: { before: new Date() },
                          firstDayOfWeek: 1,
                          months: MONTHS,
                          weekdaysLong: WEEKDAYS_LONG,
                          weekdaysShort: WEEKDAYS_SHORT,
                          locale: 'de',
                        }}
                        inputProps={{
                          readOnly: true,
                          className:
                            'form-input font-body block w-full h-10 bg-gray-100 rounded-md px-4 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5',
                        }}
                        value={values.until ? formattedDateString(new Date(values.until)) : ''}
                        onDayChange={(day) => setFieldValue('until', day)}
                      />
                    </div>
                    {errors.until && touched.until ? <InputMessage kind={InputMessageKind.error}>{errors.until}</InputMessage> : null}
                  </div>
                  <div className="sm:col-span-6">
                    <InputLabel>{'Gesprächspartner * in:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        value={values.contact_person || ''}
                        disabled={deletion}
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('contact_person', event.currentTarget.value)}
                        type="text"
                      />
                    </div>
                    {errors.contact_person && touched.contact_person ? (
                      <InputMessage kind={InputMessageKind.error}>{errors.contact_person}</InputMessage>
                    ) : null}
                  </div>
                  <div className="sm:col-span-6">
                    <InputLabel>{'Notiz:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextAreaInput
                        disabled={deletion}
                        initialValue={values.note}
                        onChange={(event: React.FormEvent<HTMLTextAreaElement>) => setFieldValue('note', event.currentTarget.value)}
                      />
                    </div>
                    {errors.note && touched.note ? <InputMessage kind={InputMessageKind.error}>{errors.note}</InputMessage> : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-between p-4 pt-8 pb-6 place-items-stretch">
              <Button kind={ButtonKind.secondary} onClick={closeModal}>
                {'Abbrechen'}
              </Button>
              <Button kind={deletion ? ButtonKind.danger : ButtonKind.primary} onClick={handleSubmit} disabled={isSubmitting}>
                {getTextObject().submitButtonText}
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  )
}

export default ReminderModal
