import React from 'react'
import { InputLabel } from '..'

interface Props {
  name: string
  id?: string
  label: string
  checked: boolean
  onChange: (checked: boolean) => void
}

const Checkbox = (props: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.checked)

  return (
    <div className="flex items-center">
      <input name={props.name} id={props?.id || props.name} type="checkbox" checked={props.checked} onChange={handleChange} className="mr-3" />
      <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
    </div>
  )
}

export default Checkbox
