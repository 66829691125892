import { gql } from 'graphql-request'

export const createPharmacyProjectStatus = gql`
  mutation($project_id: String!, $pharmacy_id: String!, $status_id: String!) {
    createPharmacyProjectStatus(project_id: $project_id, pharmacy_id: $pharmacy_id, status_id: $status_id) {
      id
      project_id
      pharmacy_id
      project {
        name
      }
      status {
        id
        label
      }
    }
  }
`

export const updatePharmacyProjectStatus = gql`
  mutation($id: ID!, $project_id: String!, $pharmacy_id: String!, $status_id: String!) {
    updatePharmacyProjectStatus(id: $id, project_id: $project_id, pharmacy_id: $pharmacy_id, status_id: $status_id) {
      id
      project_id
      pharmacy_id
      project {
        name
      }
      status {
        id
        label
      }
    }
  }
`

export const deletePharmacyProjectStatus = gql`
  mutation($id: ID!) {
    deletePharmacyProjectStatus(id: $id) {
      id
    }
  }
`
