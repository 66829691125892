import React from 'react'
import { AppointmentSummaryInProject } from '@aposphaere/core-kit/build/models/appointment'
import { IAppointmentsPerProjectType } from '../../pages/OverviewPageTypes'
export function AppointmentSummaryCard(props: { project: AppointmentSummaryInProject; appointmentsPerProject: IAppointmentsPerProjectType }) {
  const total: number = props.project.past + props.project.future
  //e.g 0.05235=>5.23
  const goalPercent: number = Math.floor((props.project.past / props.project.contact_goal) * 10000) / 100
  const showAmountSummary = goalPercent >= 100
  const appointmentsPerProject = props.appointmentsPerProject

  return (
    <div className="flex flex-col w-full">
      <div className="flex w-full flex-wrap items-stretch min-h-150 p-1 ">
        <div className="flex flex-wrap bg-blue-50 w-1/2 lg:w-1/4 xl:w-1/4 p-8 pb-6 rounded border-2 border-white ">
          <span className="block h-10 text-sm font-medium leading-4 text-gray-700 tracking-wide uppercase w-full">{'Vergangene Termine'}</span>
          <div className="flex flex-row items-center">
            <span className="flex w-1/2 text:base lg:text-xl xl:text-2xl font-medium text-blue-600 self-center">{props.project.past}</span>
            <div className="flex flex-col ml-8 whitespace-no-wrap">
              <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${appointmentsPerProject.past_appointments} Termine`}</span>
              <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${appointmentsPerProject.past_slots} Slots`}</span>
              <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${appointmentsPerProject.past_participants} Teilnehmer`}</span>
              <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${appointmentsPerProject.past_average_participants} Durchschnittliche Teilnehmer`}</span>
              <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${appointmentsPerProject.past_average_duration_in_minutes} Durchschnittliche Zeit in Minuten`}</span>
            </div>
          </div>
          <span className="flex w-1/2 text-xxl font-semibold justify-end text-blue-600 opacity-45 self-center" />
        </div>
        <div className="flex flex-wrap bg-blue-50 w-1/2 lg:w-1/4 xl:w-1/4 p-8 pb-6 rounded border-2 border-white ">
          <span className="block h-10 text-sm font-medium leading-4 text-gray-700 tracking-wide uppercase w-full">{'Avisierte Termine'}</span>
          <div className="flex flex-row items-center">
            <span className="flex w-1/2 text:base lg:text-xl xl:text-2xl font-medium text-blue-600 self-center">{props.project.future}</span>
            <div className="flex flex-col ml-8 whitespace-no-wrap">
              <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${appointmentsPerProject.future_pharmacies_with_appointments} Apotheken`}</span>
              <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${appointmentsPerProject.future_slots} Slots`}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap bg-blue-50 w-1/2 lg:w-1/4 xl:w-1/4 p-8 pb-6 rounded border-2 border-white ">
          <span className="block h-10 text-sm font-medium leading-4 text-gray-700 tracking-wide uppercase w-full">{'Gesamtanzahl Termine'}</span>
          <div className="flex flex-row items-center">
            <span className="flex w-1/2 text:base lg:text-xl xl:text-2xl font-medium text-blue-600 self-center">{total}</span>
            <div className="flex flex-col ml-8 whitespace-no-wrap">
              <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${appointmentsPerProject.future_pharmacies_with_appointments} Apotheken`}</span>
              <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${
                Number(appointmentsPerProject.past_slots) + Number(appointmentsPerProject.future_slots)
              } Slots`}</span>
              <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${appointmentsPerProject.past_participants} Teilnehmer`}</span>
            </div>
          </div>
        </div>
        <div className={`flex flex-wrap bg-blue-50 w-1/2 lg:w-1/4 xl:w-1/4 p-8 pb-6 rounded border-2 border-white`}>
          <span className="block h-10 text-sm font-medium leading-4 text-gray-700 tracking-wide uppercase w-full">{'Fortschritt'}</span>
          <span className="flex w-1/2 text:base lg:text-xl xl:text-2xl font-medium text-blue-600 self-center">
            {showAmountSummary ? props.project.amount_summary : goalPercent.toString() + '%'}
          </span>
        </div>
      </div>
    </div>
  )
}
