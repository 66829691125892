import { Pharmacy } from '@aposphaere/core-kit'
import { IconButton, IconButtonKind, MapIcon } from '@aposphaere/ui-components'
import React, { useContext } from 'react'
import { CrmContext, ICrmContext } from '../../contexts/crmContext'

type PharmaciesCardItemProps = {
  pharmacy: Pharmacy
}

const PharmaciesCardItem: React.FunctionComponent<PharmaciesCardItemProps> = ({ pharmacy }) => {
  const {
    selectPharmacyBranchHook: { setSelectedPharmacyBranchId },
  } = useContext(CrmContext) as ICrmContext
  return (
    <li className="w-full px-2 2xl:px-4 border-b border-gray-400 last:border-0">
      <div className="flex w-full py-2 2xl:py-4">
        <div className="flex w-5/6 items-center">
          <div className="text-base leading-6 font-medium">
            <span className="font-medium">
              {pharmacy.name}
              {`(ID: ${pharmacy.okid || ''})`}
            </span>
            <br />
            <span className="font-normal">
              {`${pharmacy.address?.address_name || ''}, ${pharmacy.address?.zipcode || ''} ${pharmacy.address?.city || ''}`}
            </span>
            <br />
            <span className="font-normal">{`Tel.: ${pharmacy.phone || ''}, E-Mail: ${pharmacy.email || ''}`}</span>
          </div>
        </div>
        <div className="flex w-1/6 justify-end">
          <IconButton icon={<MapIcon />} onClick={() => setSelectedPharmacyBranchId(pharmacy.id)} kind={IconButtonKind.default} />
        </div>
      </div>
    </li>
  )
}

export default PharmaciesCardItem
