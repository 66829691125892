import { Note } from '@aposphaere/core-kit'
import { DeleteIcon, EditIcon, IconButton, IconButtonKind, ReminderIcon } from '@aposphaere/ui-components'
import React, { useMemo } from 'react'
import useCETTime from '../../hooks/useCETTime'

type NotesCardItemProps = {
  note: Note
  setCurrentlySelectedNote: React.Dispatch<React.SetStateAction<Note | null>>
  onOpenModal: [() => void, () => void]
  canEditNotes: boolean
  canDeleteNotes: boolean
}

const NotesCardItem: React.FunctionComponent<NotesCardItemProps> = ({
  note,
  setCurrentlySelectedNote,
  onOpenModal,
  canEditNotes,
  canDeleteNotes,
}) => {
  const [onEditNote, onDeleteNote] = onOpenModal
  const getCETTime = useCETTime()
  const deleteModalOpen = () => {
    setCurrentlySelectedNote(note)
    onDeleteNote()
  }

  const editModalOpen = () => {
    setCurrentlySelectedNote(note)
    onEditNote()
  }

  const CETTime = useMemo(() => getCETTime(note.created_at), [getCETTime, note.created_at])

  return (
    <li className="w-full relative px-2 2xl:px-4 border-b border-gray-400 last:border-0">
      <div className="flex w-full py-2 2xl:py-4">
        <div className="flex w-1/6 min-w-130px">
          <div className="text-base leading-6 font-medium">
            <span className="font-medium">{CETTime[0]}</span>
            <br />
            <span className="font-medium ">{CETTime[1]}</span>
          </div>
        </div>
        <div className="flex w-3/6 items-center">
          <div className="text-base leading-6 font-medium">
            <span className="font-normal">{note.content}</span>
            <br />
            <span className="font-normal italic text-gray-600">{`${note.note_type || ''}, ${note.user?.name || ''} `}</span>
          </div>
        </div>
        <div className="xl:flex hidden w-2/6 justify-end">
          {note.note_type === 'Wiedervorlage' ? (
            <div className="">
              <IconButton additionalCss="text-yellow-600" icon={<ReminderIcon />} onClick={() => console.log()} kind={IconButtonKind.default} />
            </div>
          ) : (
            ''
          )}
          {canDeleteNotes && <IconButton icon={<DeleteIcon />} onClick={deleteModalOpen} kind={IconButtonKind.default} />}
          {canEditNotes && <IconButton icon={<EditIcon />} onClick={editModalOpen} kind={IconButtonKind.default} />}
        </div>
        <div className="absolute top-0 right-0 xl:hidden">
          {note.note_type === 'Wiedervorlage' ? (
            <div className="">
              <IconButton
                additionalCss="text-yellow-600"
                icon={<ReminderIcon dimension="28" />}
                onClick={() => console.log()}
                kind={IconButtonKind.default}
              />
            </div>
          ) : (
            ''
          )}
          {canDeleteNotes && <IconButton icon={<DeleteIcon dimension="28" />} onClick={deleteModalOpen} kind={IconButtonKind.default} />}
          {canEditNotes && <IconButton icon={<EditIcon dimension="28" />} onClick={editModalOpen} kind={IconButtonKind.default} />}
        </div>
      </div>
    </li>
  )
}

export default NotesCardItem
