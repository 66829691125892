import React, { useContext } from 'react'
import { CrmContext, ICrmContext, ModalKind } from '../../contexts/crmContext'
import AppointmentModal from '../AppointmentModal'
import ExportModal from '../ExportModal'
import DeactivatePharmacyModal from '../DeactivatePharmacyModal'
import NoteModal from '../NoteModal'
import ProjectsModal from '../ProjectsModal'
import PharmacyModal from '../PharmacyModal'
import ReminderModal from '../ReminderModal'
import NoteWeekPharmacyClusterModal from '../NoteWeekPharmacyClusterModal'
import PersonenModal from '../personenModal'

const ModalContainer: React.FC = () => {
  const {
    currentlyActivePharmacy,
    currentlyOpenedModalKind,
    selectPharmacyBranchHook: { pharmacyBranchActive, pharmacyDetails },
    onCloseModal,
  } = useContext(CrmContext) as ICrmContext
  const activePharmacy = pharmacyBranchActive ? pharmacyDetails : currentlyActivePharmacy
  const mapModalKindToComponent = () => {
    switch (currentlyOpenedModalKind) {
      case ModalKind.Appointment:
        return <AppointmentModal />
      case ModalKind.Export:
        return <ExportModal onClose={onCloseModal} />

      case ModalKind.NoteCreature:
        return <NoteModal creature />
      case ModalKind.NoteEditing:
        return <NoteModal editing />
      case ModalKind.NoteDeletion:
        return <NoteModal deletion />

      case ModalKind.ReminderCreate:
        return <ReminderModal key={`reminder-creation-${activePharmacy ? activePharmacy.id : ''}`} creation />
      case ModalKind.ReminderDelete:
        return <ReminderModal key={`reminder-deletion-${activePharmacy ? activePharmacy.id : ''}`} deletion />
      case ModalKind.ReminderEdit:
        return <ReminderModal key={`reminder-editing-${activePharmacy ? activePharmacy.id : ''}`} editing />

      case ModalKind.Pharmacy:
        return <DeactivatePharmacyModal />
      case ModalKind.PharmacyUpdate:
        return <PharmacyModal key={Date.now()} />

      case ModalKind.ProjectsEdit:
        return <ProjectsModal />

      case ModalKind.NoteWeekPharmacyClusterCreate:
        return <NoteWeekPharmacyClusterModal mode="create" />
      case ModalKind.NoteWeekPharmacyClusterUpdate:
        return <NoteWeekPharmacyClusterModal mode="edit" />
      case ModalKind.NoteWeekPharmacyClusterDelete:
        return <NoteWeekPharmacyClusterModal mode="delete" />

      case ModalKind.PersonenEdit:
        return <PersonenModal editing />

      default:
        return null
    }
  }

  return mapModalKindToComponent()
}

export default ModalContainer
