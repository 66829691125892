import { Pharmacy } from '@aposphaere/core-kit'
import { SectionCard } from '@aposphaere/ui-components'
import React, { useState } from 'react'
import PharmaciesCardItem from './PharmaciesCardItem'

export type PharmaciesCardProps = {
  pharmacies: Pharmacy[]
  showAddButton: boolean
  title: string
}

const PharmaciesCard: React.FunctionComponent<PharmaciesCardProps> = ({ pharmacies, showAddButton, title }) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  return (
    <SectionCard
      isExpanded={expanded}
      title={title}
      badgeValue={8}
      showAllEntries={pharmacies.length <= 3}
      showAddButton={showAddButton}
      setExpanded={setExpanded}
    >
      {pharmacies.length === 0 ? (
        <p className="w-full py-2 text-base text-gray-600 text-center">{'Keine Einträge vorhanden'}</p>
      ) : (
        pharmacies.map((pharmacy) => <PharmaciesCardItem pharmacy={pharmacy} key={pharmacy.id} />)
      )}
    </SectionCard>
  )
}

export default PharmaciesCard
