import { Reminder } from '@aposphaere/core-kit'
import { DeleteIcon, EditIcon, IconButton, IconButtonKind, ReminderIcon } from '@aposphaere/ui-components'
import React, { useMemo } from 'react'
import useCETTime from '../../hooks/useCETTime'

type RemindersCardItemProps = {
  reminder: Reminder
  setCurrentlySelectedReminder: React.Dispatch<React.SetStateAction<Reminder | null>>
  onOpenModal: [() => void, () => void]
  canEditReminders: boolean
  canDeleteReminders: boolean
}

const ReminderCardItem: React.FunctionComponent<RemindersCardItemProps> = ({
  reminder,
  setCurrentlySelectedReminder,
  onOpenModal,
  canEditReminders,
  canDeleteReminders,
}) => {
  const [onEditReminder, onDeleteReminder] = onOpenModal
  const getCETTime = useCETTime()

  const deleteModalOpen = () => {
    setCurrentlySelectedReminder(reminder)
    onDeleteReminder()
  }

  const editModalOpen = () => {
    setCurrentlySelectedReminder(reminder)
    onEditReminder()
  }
  const CETTime = useMemo(() => getCETTime(reminder.created_at.toString()), [getCETTime, reminder.created_at])

  return (
    <li className="w-full relative px-2 2xl:px-4 border-b border-gray-400 last:border-0">
      <div className="flex justify-between gap-2 py-2 2xl:py-4">
        <div className="flex w-2/12">
          <div className="text-base leading-6 font-medium">
            <span className="font-medium">{CETTime[0]} </span>
            <br />
            <span className="font-normal italic text-gray-600">{CETTime[1]}</span>
          </div>
        </div>
        <div className="hidden xl:flex xl:w-4/12">
          <div className="text-base leading-6 font-medium">
            <p className="font-normal">{reminder.note}</p>
            <span className="font-normal italic text-gray-600">{`Wiedervorlage, ${reminder.contact_person || ''}`}</span>
          </div>
        </div>
        <div className="flex w-8/12 pl-2 xl:w-4/12">
          <div className="text-base leading-6 font-medium">
            <h4 className="font-normal">{`Wiedervorlage:`}</h4>
            <span className="font-normal">{`${reminder?.user?.name}`}</span>
          </div>
        </div>
        <div className="flex w-2/12 items-start justify-end">
          <IconButton
            additionalCss="text-yellow-600"
            icon={<ReminderIcon dimension="28" />}
            onClick={() => console.log()}
            kind={IconButtonKind.default}
          />
          {canDeleteReminders && <IconButton icon={<DeleteIcon dimension="28" />} onClick={deleteModalOpen} kind={IconButtonKind.default} />}
          {canEditReminders && <IconButton icon={<EditIcon dimension="28" />} onClick={editModalOpen} kind={IconButtonKind.default} />}
        </div>
      </div>
    </li>
  )
}

export default ReminderCardItem
