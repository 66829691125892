import React from 'react'

const SearchIcon: React.FC = () => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1-2">
        <g id="Gruppe_72" data-name="Gruppe 72">
          <g id="Ellipse_1" data-name="Ellipse 1">
            <path
              d="M28,27.6728,22.4093,22.085a.2806.2806,0,0,0-.3926-.0032,8.1429,8.1429,0,1,1,2.572-5.9594"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth="2"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SearchIcon
