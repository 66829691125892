import { Pharmacy, Appointment } from '@aposphaere/core-kit'
import React, { memo, useContext, useEffect } from 'react'
import { CrmContext, ICrmContext } from '../../contexts/crmContext'
import { MapMarker } from './MapMarker'
import { MarkerClusterer, useGoogleMap } from '@react-google-maps/api'
import ClusterMarker from '../../m-all.svg'

interface IMapMarkers {
  pharmacies?: Pharmacy[]
  searchResults?: Pharmacy[]
  showInputSearchFilter: boolean
  selectedPharmacies?: Pharmacy[]
  setSelectedPharmacyId?: React.Dispatch<React.SetStateAction<number | undefined>>
  determineMapIcon: (pharmacy: Pharmacy, sortedAppointmentsToday: Appointment[] | null, selectedDay?: Date) => string
}

interface IClusteredMarkers {
  markers: Pharmacy[]
  minimumClusterSize: number
  minimumClusterRenderSize?: number
}

const MapMarkers: React.FC<IMapMarkers> = ({
  pharmacies,
  searchResults,
  showInputSearchFilter,
  selectedPharmacies,
  determineMapIcon,
  setSelectedPharmacyId,
}) => {
  const mapInstance = useGoogleMap() as { fitBounds: (arg0: any) => void }
  const {
    fitToMarkers,
    filterOnMapHook: {
      filterOnMapState: { selectedProjects },
    },
    selectedDayHook: { selectedDay },
    routeMap: { sortedAppointmentsToday },
  } = useContext(CrmContext) as ICrmContext

  const renderMarkers = (props: IClusteredMarkers) => {
    const { markers, minimumClusterSize, minimumClusterRenderSize = 100 } = props

    return markers.length > minimumClusterRenderSize ? (
      <MarkerClusterer
        minimumClusterSize={minimumClusterSize}
        // We can change the images here, you can see the available default images, just add a number and an extension
        // for example https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png
        options={{
          imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        }}
        styles={[
          {
            url: ClusterMarker,
            height: 42,
            width: 42,
            textColor: '#FFF',
          },
        ]}
      >
        {(clusterer) =>
          markers?.map((pharmacy, index) => (
            <MapMarker
              // clusterer={clusterer}
              key={pharmacy.id}
              id={pharmacy.id}
              long={pharmacy.address?.longitude || 0}
              lat={pharmacy.address?.latitude || 0}
              icon={determineMapIcon(pharmacy, sortedAppointmentsToday, selectedDay)}
              onClick={setSelectedPharmacyId}
              text={pharmacy.name}
            />
          ))
        }
      </MarkerClusterer>
    ) : (
      markers?.map((pharmacy) => (
        <MapMarker
          key={pharmacy.id}
          id={pharmacy.id}
          long={pharmacy.address?.longitude || 0}
          lat={pharmacy.address?.latitude || 0}
          icon={determineMapIcon(pharmacy, sortedAppointmentsToday, selectedDay)}
          onClick={setSelectedPharmacyId}
          text={pharmacy.name}
        />
      ))
    )
  }

  useEffect(() => {
    if (selectedPharmacies && selectedPharmacies.length > 0) {
      fitToMarkers(mapInstance, selectedPharmacies)
    }
    if (pharmacies && searchResults?.length === 0 && !showInputSearchFilter) {
      fitToMarkers(mapInstance, pharmacies)
    }
    if (searchResults && searchResults.length > 0) {
      fitToMarkers(mapInstance, searchResults)
    }
    // eslint-disable-next-line
  }, [selectedPharmacies, pharmacies, searchResults])

  return (
    <>
      {pharmacies && searchResults?.length === 0 && !showInputSearchFilter ? renderMarkers({ markers: pharmacies, minimumClusterSize: 5 }) : null}

      {pharmacies && showInputSearchFilter && selectedProjects.length === 0 ? renderMarkers({ markers: pharmacies, minimumClusterSize: 5 }) : null}

      {selectedPharmacies && selectedPharmacies.length > 0 && showInputSearchFilter
        ? renderMarkers({ markers: selectedPharmacies, minimumClusterSize: 5 })
        : null}

      {searchResults && searchResults.length > 0 ? renderMarkers({ markers: searchResults, minimumClusterSize: 5 }) : null}
    </>
  )
}

export default memo(MapMarkers)
