import React, { FunctionComponent } from 'react'

export enum MapMarkerColor {
  gray = 'fill-gray',
  green = 'fill-green',
  blue = 'fill-blue',
  yellow = 'fill-yellow',
  purple = 'fill-purple',
  red = 'fill-red',
  black = 'fill-black',
  transparent = 'fill-noColor',
}

export type MapMarkerProps = {
  color?: MapMarkerColor
  letter?: string
  shaded?: boolean
  selected?: boolean
  customCSS?: string
}

const MapMarker: FunctionComponent<MapMarkerProps> = ({ color, letter, shaded, selected, customCSS }) => {
  const shadingOpacity = shaded ? '1' : '0'
  if (selected) {
    return (
      <svg className={customCSS} xmlns="http://www.w3.org/2000/svg" width="33.2692" height="50.0048" viewBox="0 0 33.2692 50.0048">
        <g id="Ebene_2" data-name="Ebene 2">
          <g id="Ebene_1-2" data-name="Ebene 1">
            <g id="Gruppe_333" data-name="Gruppe 333">
              <g id="Pfad_180-2" data-name="Pfad 180-2">
                <path d="M16.6562,47.9282C14.9828,45.383,11.2,39.5854,7.81,33.4849,4.77,28.0158,1.1472,20.6547,1.1472,16.6455A15.4851,15.4851,0,0,1,27.5855,5.6863,15.402,15.402,0,0,1,32.122,16.6455c0,2.4518-1.3887,7.7873-8.004,19.2821C21.0994,41.1727,18.1245,45.7309,16.6562,47.9282Z" />
                <path
                  d="M16.6346,2.2944a14.3457,14.3457,0,0,0-14.34,14.3511c0,1.07.3263,3.1375,1.8807,6.907a92.6448,92.6448,0,0,0,4.6373,9.3752c2.83,5.0921,5.9388,9.9778,7.8495,12.914,1.6452-2.5148,4.1156-6.4051,6.5038-10.5594,6.7945-11.8193,7.8091-16.75,7.8091-18.6368a14.3457,14.3457,0,0,0-14.34-14.3511m0-2.2944A16.64,16.64,0,0,1,33.2692,16.6455c0,9.1734-16.6148,33.3593-16.6347,33.3593v0C16.686,49.9016,0,25.8385,0,16.6455A16.64,16.64,0,0,1,16.6346,0Z"
                  className="fill-white"
                />
              </g>
            </g>
            <circle cx="16.6346" cy="16.3571" r="9.8382" className="fill-white" />
          </g>
        </g>
      </svg>
    )
  } else {
    return (
      <svg className={customCSS} xmlns="http://www.w3.org/2000/svg" width="29" height="40.4" viewBox="0 0 29 40.4">
        <g id="Ebene_2" data-name="Ebene 2">
          <g id="Ebene_1-2" data-name="Ebene 1">
            <g id="Gruppe_333" data-name="Gruppe 333">
              <g id="Pfad_180-2" data-name="Pfad 180-2">
                <path
                  className={`${color}`}
                  d="M14.5,38.9c-1.5-2.2-5-6.5-7.9-11.8C3.9,22.3,1,17.7,1,14.2C1,6.8,7,0.7,14.5,0.7c3.6,0,7,1.4,9.6,4
				              	c2.5,2.5,4,6,4,9.6c0,2.1-1.2,4.7-7,14.8C18.4,33.6,15.8,37,14.5,38.9z"
                />
                <path
                  fill="#fff"
                  d="M14.5,2C7.6,2,2,7.6,2,14.5c0,0,0,0,0,0c0,6.6,10.9,19.6,12.5,22.2C16.1,34.2,27,21.1,27,14.5
                        C27,7.6,21.4,2,14.5,2C14.5,2,14.5,2,14.5,2 M14.5,0C22.5,0,29,6.5,29,14.5c0,0,0,0,0,0c0,8-14.9,25.2-14.5,25.2l0,0
                        C14.5,39.7,0,22.5,0,14.5C0,6.5,6.5,0,14.5,0C14.5,0,14.5,0,14.5,0z"
                />
                <rect x="0" y="0" width="29" height="29" fill="transparent" />
                <text x="50%" y="17" dominantBaseline="middle" fill="white" textAnchor="middle" className="font-body">{`${letter}`}</text>
              </g>
            </g>
            <g opacity={`${shadingOpacity}`}>
              <line x1="1.4" y1="12.1" x2="21" y2="2.9" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2" opacity="0.35" />
              <line x1="2.1" y1="18.8" x2="26" y2="7.7" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2" opacity="0.35" />
              <line x1="5.1" y1="24.5" x2="27.7" y2="14" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2" opacity="0.35" />
              <line x1="8.4" y1="30" x2="25.1" y2="22.3" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2" opacity="0.35" />
              <line x1="12.5" y1="35.3" x2="19.1" y2="32.2" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2" opacity="0.35" />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
export default MapMarker
