import { allPharmacies, compareValues, Pharmacy, userActions } from '@aposphaere/core-kit'
import {
  DeactivateIcon,
  DropdownArrowIcon,
  EditIcon,
  IconButton,
  IconButtonKind,
  LockedForCallsIcon,
  PlusSignIcon,
  ReminderIcon,
  TextInput,
  Button,
  ButtonKind,
} from '@aposphaere/ui-components'
import moment from 'moment'
import Table from 'rc-table'
import React, { useEffect, useReducer, useState } from 'react'
import { ModalKind } from '../../contexts/crmContext'
import { useAuthenticatedQuery } from '../../hooks/useAuthenticatedQuery'
import CustomPagination from '../CustomPagination'
import Spinner from '../loader.gif'

interface IAddressesTable {
  addAndSet: (pharmacy: Pharmacy, kind: ModalKind) => void
  toggleAvailableForCallCenterInTable: (pharmacy: Pharmacy) => Promise<void | Pharmacy>
  abilities: Record<typeof userActions[number], boolean>
}

type ISortDirection = 'top' | 'bottom'
type ISortType = 'ID' | 'name' | 'address_name' | 'zipcode' | 'city' | 'pharmacy_cluster_id' | 'pharmacy_cluster_name' | 'phone'

interface ISortedBy {
  top: Pharmacy[][] | undefined
  bottom: Pharmacy[][] | undefined
}

interface ISortState {
  defaultArray: Pharmacy[]
  defaultDataArray: Pharmacy[][]
  sortType: ISortType | ''
  sortDirection: ISortDirection | ''
  ID: ISortedBy
  name: ISortedBy
  address_name: ISortedBy
  zipcode: ISortedBy
  city: ISortedBy
  phone: ISortedBy
  pharmacy_cluster_id: ISortedBy
  pharmacy_cluster_name: ISortedBy
}

interface ISortAction {
  type: string
  payload?: {
    sortType?: ISortType
    sortDirection?: ISortDirection
    sortedArray?: Pharmacy[][]
    defaultArray?: Pharmacy[]
    defaultDataArray?: Pharmacy[][]
  }
}

const initialState: ISortState = {
  defaultArray: [],
  defaultDataArray: [],
  sortType: '',
  sortDirection: '',
  ID: {
    top: undefined,
    bottom: undefined,
  },
  name: {
    top: undefined,
    bottom: undefined,
  },
  address_name: {
    top: undefined,
    bottom: undefined,
  },
  zipcode: {
    top: undefined,
    bottom: undefined,
  },
  city: {
    top: undefined,
    bottom: undefined,
  },
  phone: {
    top: undefined,
    bottom: undefined,
  },
  pharmacy_cluster_id: {
    top: undefined,
    bottom: undefined,
  },
  pharmacy_cluster_name: {
    top: undefined,
    bottom: undefined,
  },
}

const reducer = <S extends ISortState, A extends ISortAction>(state: S, action: A) => {
  switch (action.type) {
    case 'clearSortKeys':
      return { ...state, sortType: '', sortDirection: '' }
    case 'setDefaultArray':
      return {
        ...state,
        sortType: '',
        sortDirection: '',
        defaultArray: action.payload?.defaultArray,
        defaultDataArray: action.payload?.defaultDataArray,
      }
    case 'setCurrentSortedArray':
      return {
        ...state,
        sortType: action.payload?.sortType,
        sortDirection: action.payload?.sortDirection,
        [action.payload?.sortType || 'name']: {
          ...state[action.payload?.sortType || 'name'],
          [action.payload?.sortDirection || 'top']: action.payload?.sortedArray,
        },
      }
    default:
      return state
  }
}

const AddressesTable: React.FC<IAddressesTable> = ({ addAndSet, toggleAvailableForCallCenterInTable, abilities }) => {
  const [pharmaciesData, setPharmaciesData] = useState<Pharmacy[][] | undefined>()

  const [sortingState, dispatch] = useReducer(reducer, initialState)

  const [currentPage, setCurrentPage] = useState<number>(1)

  const [filterZipcode, setFilterZipcode] = useState<string>('')

  const [filterName, setFilterName] = useState<string>('')

  const [filterAddress, setFilterAddress] = useState<string>('')

  const [filterCity, setFilterCity] = useState<string>('')

  const pharmaciesQuery = useAuthenticatedQuery<{ pharmacies: Pharmacy[] }>(allPharmacies)

  const applyFilters = () => {
    const filteredPharmacies = pharmaciesQuery?.data?.pharmacies.filter((pharmacy) => {
      if (
        (filterZipcode.length ? pharmacy?.address?.zipcode?.includes(filterZipcode) : true) &&
        (filterName.length ? pharmacy?.name?.includes(filterName) : true) &&
        (filterAddress.length ? pharmacy?.address?.address_name?.includes(filterAddress) : true) &&
        (filterCity.length ? pharmacy?.address?.city?.includes(filterCity) : true)
      ) {
        return pharmacy
      } else {
        return null
      }
    })
    if (filteredPharmacies && filteredPharmacies.length) {
      dispatch({
        type: 'setDefaultArray',
        payload: {
          defaultArray: filteredPharmacies,
          defaultDataArray: makeData(filteredPharmacies),
        },
      })
    }
  }

  const clearFilters = () => {
    setFilterName('')
    setFilterCity('')
    setFilterAddress('')
    setFilterZipcode('')
    if (pharmaciesQuery.data?.pharmacies) {
      dispatch({
        type: 'setDefaultArray',
        payload: {
          defaultArray: pharmaciesQuery.data.pharmacies,
          defaultDataArray: makeData(pharmaciesQuery.data.pharmacies),
        },
      })
    }
  }

  useEffect(() => {
    if (pharmaciesQuery.data?.pharmacies) {
      dispatch({
        type: 'setDefaultArray',
        payload: {
          defaultArray: pharmaciesQuery.data.pharmacies,
          defaultDataArray: makeData(pharmaciesQuery.data.pharmacies),
        },
      })
    }
  }, [pharmaciesQuery])

  useEffect(() => {
    if (sortingState.sortType && sortingState.sortDirection) {
      return setPharmaciesData(sortingState[sortingState.sortType][sortingState.sortDirection])
    }
    return setPharmaciesData(sortingState.defaultDataArray)
  }, [sortingState, sortingState.defaultDataArray, sortingState.sortType, sortingState.sortDirection])

  const applySorting = (type: ISortType) => {
    const { sortType, sortDirection, [type]: currentObj } = sortingState
    if (type === sortType) {
      if (sortDirection === 'bottom') {
        const sortedArray = currentObj.top ? currentObj.top : sortAndReduceArray(type, 'top')
        return dispatch({
          type: 'setCurrentSortedArray',
          payload: {
            sortDirection: 'top',
            sortType: type,
            sortedArray,
          },
        })
      } else if (sortDirection === 'top') {
        return dispatch({ type: 'clearSortKeys' })
      }
    } else {
      const sortedArray = currentObj.bottom ? currentObj.bottom : sortAndReduceArray(type, 'bottom')
      return dispatch({
        type: 'setCurrentSortedArray',
        payload: {
          sortDirection: 'bottom',
          sortType: type,
          sortedArray,
        },
      })
    }
  }

  const sortAndReduceArray = (type: ISortType, direction: ISortDirection): Pharmacy[][] => {
    const order = direction === 'bottom' ? 'asc' : 'desc'
    switch (type) {
      case 'ID':
        return makeData([...sortingState.defaultArray].sort(compareValues(['id'], order, true)))
      case 'address_name':
        return makeData([...sortingState.defaultArray].sort(compareValues(['address', 'address_name'], order)))
      case 'city':
        return makeData([...sortingState.defaultArray].sort(compareValues(['address', 'city'], order)))
      case 'name':
        return makeData([...sortingState.defaultArray].sort(compareValues(['name'], order)))
      case 'pharmacy_cluster_id':
        return makeData([...sortingState.defaultArray].sort(compareValues(['pharmacy_cluster', 'id'], order, true)))
      case 'pharmacy_cluster_name':
        return makeData([...sortingState.defaultArray].sort(compareValues(['pharmacy_cluster', 'name'], order)))
      case 'phone':
        return makeData([...sortingState.defaultArray].sort(compareValues(['phone'], order)))
      case 'zipcode':
        return makeData([...sortingState.defaultArray].sort(compareValues(['address', 'zipcode'], order)))
      default:
        return makeData(sortingState.defaultArray)
    }
  }

  const makeData = (arr: Pharmacy[]) =>
    arr.reduce((acc: Pharmacy[][], el, index) => {
      if (index === 0 || index % 13 === 0) {
        acc.push([el])
      } else {
        acc[acc.length - 1].push(el)
      }
      return acc
    }, [])

  const titleClassName =
    'flex h-fit w-fit py-2 pl-3 pr-2 -ml-3 items-center rounded-full hover:bg-blue-50 hover:text-blue-700 selected:text-blue-700 text-gray-600 text-left text-xs font-medium uppercase tracking-wider font-body'

  const columns = [
    {
      title: (
        <div className="pl-2">
          <div
            onClick={() => applySorting('ID')}
            className={`${titleClassName} ${sortingState.sortType === 'ID' ? 'text-blue-700' : ''} cursor-pointer`}
          >
            {'ID'}
            <span
              className={`flex pr-1.5 ml-2 ${sortingState.sortType === 'ID' && sortingState.sortDirection === 'top' ? 'transform rotate-180' : ''}`}
            >
              <DropdownArrowIcon />
            </span>
          </div>
        </div>
      ),
      className: 'h-10 items-center',
      width: '4%',
      dataIndex: 'id',
      key: 'id',
      render: (value: string) => <div className={' pr-6 py-4 whitespace-nowrap pl-2 w-16'}>{value}</div>,
    },
    {
      title: (
        <div
          onClick={() => applySorting('name')}
          className={`${titleClassName} ${sortingState.sortType === 'name' ? 'text-blue-700' : ''} cursor-pointer`}
        >
          {'Firmenname'}
          <span
            className={`flex pr-1.5 ml-2 ${sortingState.sortType === 'name' && sortingState.sortDirection === 'top' ? 'transform rotate-180' : ''}`}
          >
            <DropdownArrowIcon />
          </span>
        </div>
      ),
      className: 'h-10 items-center',
      width: '19%',
      dataIndex: 'name',
      key: 'Firmenname',
      render: (value: string) => <div className={'pr-6 py-4 whitespace-nowrap'}>{value}</div>,
    },
    {
      title: (
        <div
          onClick={() => applySorting('address_name')}
          className={`${titleClassName} ${sortingState.sortType === 'address_name' ? 'text-blue-700' : ''} cursor-pointer`}
        >
          {'Strasse'}
          <span
            className={`flex pr-1.5 ml-2 ${
              sortingState.sortType === 'address_name' && sortingState.sortDirection === 'top' ? 'transform rotate-180' : ''
            }`}
          >
            <DropdownArrowIcon />
          </span>
        </div>
      ),
      className: 'h-10',
      width: '13%',
      dataIndex: 'address',
      key: 'strasse',
      render: (value: { address_name: string }) => <div className={'pr-6 py-4 whitespace-nowrap'}>{value.address_name}</div>,
    },
    {
      title: (
        <div
          onClick={() => applySorting('zipcode')}
          className={`${titleClassName} ${sortingState.sortType === 'zipcode' ? 'text-blue-700' : ''} cursor-pointer`}
        >
          {'PLZ'}
          <span
            className={`flex pr-1.5 ml-2 ${
              sortingState.sortType === 'zipcode' && sortingState.sortDirection === 'top' ? 'transform rotate-180' : ''
            }`}
          >
            <DropdownArrowIcon />
          </span>
        </div>
      ),
      className: 'h-10',
      width: '8%',
      dataIndex: 'address',
      key: 'plz',
      render: (value: { zipcode: string }) => <div className={'pr-6 py-4 whitespace-nowrap'}>{value.zipcode}</div>,
    },
    {
      title: (
        <div
          onClick={() => applySorting('city')}
          className={`${titleClassName} ${sortingState.sortType === 'city' ? 'text-blue-700' : ''} cursor-pointer`}
        >
          {'Ort'}
          <span
            onClick={() => applySorting('city')}
            className={`flex pr-1.5 ml-2 ${sortingState.sortType === 'city' && sortingState.sortDirection === 'top' ? 'transform rotate-180' : ''}`}
          >
            <DropdownArrowIcon />
          </span>
        </div>
      ),
      className: 'h-10',
      width: '13%',
      dataIndex: 'address',
      key: 'ort',
      render: (value: { city: string }) => <div className={'pr-6 py-4 whitespace-nowrap'}>{value.city}</div>,
    },
    {
      title: (
        <div
          onClick={() => applySorting('phone')}
          className={`${titleClassName} ${sortingState.sortType === 'phone' ? 'text-blue-700' : ''} cursor-pointer`}
        >
          {'Telefon'}
          <span
            onClick={() => applySorting('phone')}
            className={`flex pr-1.5 ml-2 ${sortingState.sortType === 'phone' && sortingState.sortDirection === 'top' ? 'transform rotate-180' : ''}`}
          >
            <DropdownArrowIcon />
          </span>
        </div>
      ),
      className: 'h-10',
      width: '13%',
      dataIndex: 'phone',
      key: 'telefon',
      render: (value: string) => <div className={'pr-6 py-4 whitespace-nowrap'}>{value}</div>,
    },
    {
      title: (
        <div
          onClick={() => applySorting('pharmacy_cluster_id')}
          className={`${titleClassName} ${sortingState.sortType === 'pharmacy_cluster_id' ? 'text-blue-700' : ''} cursor-pointer`}
        >
          {'Region'}
          <span
            onClick={() => applySorting('pharmacy_cluster_id')}
            className={`flex pr-1.5 ml-2 ${
              sortingState.sortType === 'pharmacy_cluster_id' && sortingState.sortDirection === 'top' ? 'transform rotate-180' : ''
            }`}
          >
            <DropdownArrowIcon />
          </span>
        </div>
      ),
      className: 'h-10',
      width: '5%',
      dataIndex: 'pharmacy_cluster',
      key: 'region',
      render: (value: { id: string }) => <div className={'pr-6 py-4 whitespace-nowrap w-24'}>{value.id}</div>,
    },
    {
      title: (
        <div
          onClick={() => applySorting('pharmacy_cluster_name')}
          className={`${titleClassName} ${sortingState.sortType === 'pharmacy_cluster_name' ? 'text-blue-700' : ''} cursor-pointer`}
        >
          {'Referent'}
          <span
            className={`flex pr-1.5 ml-2 ${
              sortingState.sortType === 'pharmacy_cluster_name' && sortingState.sortDirection === 'top' ? 'transform rotate-180' : ''
            }`}
          >
            <DropdownArrowIcon />
          </span>
        </div>
      ),
      className: 'h-10',
      width: '10%',
      dataIndex: 'pharmacy_cluster',
      key: 'referent',
      render: (value: { name: string }) => <div className={'pr-6 py-4 whitespace-nowrap'}>{value.name}</div>,
    },
    {
      title: (
        <div className="pl-6">
          <div className={`${titleClassName}`}>{'Quickactions'}</div>
        </div>
      ),
      className: 'h-10',
      key: 'quickactions',
      render: (value: undefined, record: Pharmacy) => {
        const additionalCss = record.tasks?.filter((task) => moment(new Date(task.until || '')).isSameOrAfter(new Date(), 'day')).length
          ? 'text-yellow-600'
          : ''
        return (
          <div className="flex justify-center border-l border-solid border-gray-400 pl-4">
            {abilities.create_appointments && (
              <IconButton kind={IconButtonKind.default} icon={<PlusSignIcon />} onClick={() => addAndSet(record, ModalKind.Appointment)} />
            )}
            {abilities.create_tasks && (
              <IconButton
                kind={IconButtonKind.default}
                icon={<ReminderIcon />}
                additionalCss={additionalCss}
                onClick={() => addAndSet(record, ModalKind.ReminderCreate)}
              />
            )}
            {abilities.edit_pharmacies && (
              <IconButton
                kind={IconButtonKind.default}
                additionalCss={record.available_for_callcenter === false ? 'text-red-700' : ''}
                icon={<LockedForCallsIcon />}
                onClick={() => getUpdatedPharmacy(record, currentPage - 1)}
              />
            )}
            {abilities.create_notes && (
              <IconButton kind={IconButtonKind.default} icon={<EditIcon />} onClick={() => addAndSet(record, ModalKind.NoteCreature)} />
            )}
            {abilities.delete_pharmacies && (
              <IconButton kind={IconButtonKind.default} icon={<DeactivateIcon />} onClick={() => addAndSet(record, ModalKind.Pharmacy)} />
            )}
          </div>
        )
      },
    },
  ]

  const getUpdatedPharmacy = async (pharmacy: Pharmacy, index: number) => {
    const updatedPharmacy = await toggleAvailableForCallCenterInTable(pharmacy)
    if (updatedPharmacy) {
      setPharmaciesData((prevData) =>
        prevData?.map((pharmacies, i) =>
          i !== index ? pharmacies : pharmacies.map((oldPharmacy) => (oldPharmacy.id === updatedPharmacy.id ? updatedPharmacy : oldPharmacy)),
        ),
      )
    }
  }
  return pharmaciesData && pharmaciesData[currentPage - 1]?.length ? (
    <div>
      <div className="flex flex-col">
        <div className="flex flex-row gap-2">
          <TextInput
            value={filterName}
            disabled={false}
            onChange={(e) => setFilterName(e.currentTarget.value)}
            type="text"
            placeholder="Pharmacy name"
          />
          <TextInput value={filterCity} disabled={false} onChange={(e) => setFilterCity(e.currentTarget.value)} type="text" placeholder="City" />
          <TextInput
            value={filterAddress}
            disabled={false}
            onChange={(e) => setFilterAddress(e.currentTarget.value)}
            type="text"
            placeholder="Address"
          />
          <TextInput
            value={filterZipcode}
            disabled={false}
            onChange={(e) => setFilterZipcode(e.currentTarget.value)}
            type="text"
            placeholder="Zipcode"
          />
        </div>
        <div className="flex flex-row-reverse my-3 gap-2">
          <Button kind={ButtonKind.secondary} onClick={() => clearFilters()}>
            Clear filters
          </Button>
          <Button kind={ButtonKind.primary} onClick={() => applyFilters()}>
            Filter
          </Button>
        </div>
      </div>
      <div className="w-full min-w-1200px border border-gray-400 sm:rounded-lg relative overflow-x-auto">
        <div className="absolute h-px top-10 border-b border-gray-400 w-full" />
        <Table
          className="w-full px-4"
          columns={columns}
          data={pharmaciesData ? pharmaciesData[currentPage - 1] : []}
          rowClassName={(record, index: number) =>
            `${index !== 0 ? 'border-t border-gray-400' : ''} ${
              index === 12 ? 'hover:border-t' : 'hover:border-b'
            } hover:border-white  text-base font-body hover:text-blue-700 hover:shadow-insettable hover:bg-blue-50`
          }
          tableLayout={'auto'}
          rowKey={(record: Pharmacy) => `${record.id}`}
        />
        <CustomPagination onChange={setCurrentPage} current={currentPage} total={sortingState.defaultArray?.length} />
      </div>
    </div>
  ) : (
    <div className="h-screen -ml-6 -mt-6 w-screen bg-white z-9999 fixed flex items-center justify-center">
      <div className="flex items-center justify-center flex-wrap max-w-48">
        <img className="flex w-24 -mt-16" src={Spinner} alt="" />
        <br />
        <div className="w-full text-center mx-auto flex justify-center font-body mt-4 text-base text-blue-400 leading-5">
          {'Daten werden geladen.'}
        </div>
      </div>
    </div>
  )
}

export default React.memo(AddressesTable)
