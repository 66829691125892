import React from 'react'

type projectsCardItemProps = {
  project: string
  status: string
}

const ProjectsCardItem: React.FunctionComponent<projectsCardItemProps> = ({ status, project }) => (
  <li className="w-full px-2 2xl:px-4 border-b border-gray-400 last:border-0">
    <div className="flex w-full 2xl:py-4 py-2">
      <div className="flex w-3/6 min-w-130px">
        <div className="text-base leading-6 font-medium">
          <span className="font-medium">{project}</span>
        </div>
      </div>
      <div className="flex w-3/6 items-center">
        <div className="text-base leading-6 font-medium">
          <span className="font-normal italic">{status}</span>
        </div>
      </div>
    </div>
  </li>
)

export default ProjectsCardItem
