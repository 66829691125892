import { PharmacyProjectStatus } from '@aposphaere/core-kit'
import { SectionCard } from '@aposphaere/ui-components'
import React, { useContext, useState } from 'react'
import { CrmContext, ICrmContext, ModalKind } from '../../contexts/crmContext'
import ProjectCardItem from './ProjectCardItem'

export interface ProjectsCardProps {
  showAddButton: boolean
  showSpecialButton: boolean
}

const ProjectsCard: React.FunctionComponent<ProjectsCardProps> = ({ showAddButton, showSpecialButton }) => {
  const {
    onOpenModal,
    pharmacyProjectStatusesHook: { currentPharmacyProjectStatuses },
  } = useContext(CrmContext) as ICrmContext

  const [expanded, setExpanded] = useState<boolean>(false)
  return (
    <SectionCard
      isExpanded={expanded}
      setExpanded={setExpanded}
      title="Status zu Produkten"
      onEdit={() => onOpenModal(ModalKind.ProjectsEdit)}
      showAddButton={showAddButton}
      showSpecialButton={showSpecialButton}
      showAllEntries={true}
    >
      {currentPharmacyProjectStatuses.map((status: PharmacyProjectStatus) => (
        <ProjectCardItem key={status.id} status={status.status.label || ''} project={status.project.name || ''} />
      ))}
    </SectionCard>
  )
}

export default ProjectsCard
