import { Appointment, Pharmacy, Project, Status } from '../models'

// Implementation of the project filtering function
const getFilterByProjectsFunction = (selectedProjects: Project[], and = false) => (pharmacy: Pharmacy): boolean => {
  if (and) {
    const projectIdsInPharmacy = pharmacy.projects.map(({ id }) => id)
    return selectedProjects.every(({ id }) => projectIdsInPharmacy.includes(id))
  }
  return !!pharmacy.projects.filter(({ id }) => selectedProjects.map(({ id: projectId }) => projectId).includes(id)).length
}

// Implementation of the filtering function by have future appointments
const getFilterByFutureAppointments = (appointments: Appointment[]): ((pharmacy: Pharmacy) => boolean) => {
  const arrayOfPharmaciesId = appointments
    ?.filter((appointment) => new Date(appointment.date.toString().replace(/\s/, 'T') || '') > new Date())
    .map(({ pharmacy: appPharmacy }) => appPharmacy?.id || '')
  return (pharmacy: Pharmacy): boolean => !!arrayOfPharmaciesId?.includes(pharmacy.id)
}

// Implementation of the filtering function by Priority
const getFilterByCanceledAppointment = (appointments: Appointment[], statuses: Status[]): ((pharmacy: Pharmacy) => boolean) => {
  const idCanceledStatus = statuses.find((el) => el.label === 'Abgesagt').id
  const arrayOfPharmaciesId = appointments
    ?.filter(
      (appointment) => new Date(appointment.date.replace(/\s/, 'T') || '') > new Date() && `${idCanceledStatus}` === `${appointment.status.id}`,
    )
    .map(({ pharmacy: appPharmacy }) => appPharmacy?.id || '')
  return (pharmacy: Pharmacy): boolean => !!arrayOfPharmaciesId?.includes(pharmacy.id)
}

export default {
  getFilterByProjectsFunction,
  getFilterByFutureAppointments,
  getFilterByCanceledAppointment,
}
