import { gql } from 'graphql-request'

export const allProjects = gql`
  {
    projects {
      id
      name
      contact_goal
      start_date
      end_date
      price
      state
      booked_time_in_seconds
    }
  }
`

export const activeProjects = gql`
  {
    activeProjects {
      id
      name
      contact_goal
      start_date
      end_date
      price
      state
      booked_time_in_seconds
    }
  }
`
