import {
  Appointment,
  AppointmentStatusType,
  DayPart,
  daysOfTheWeek,
  formattedDateString,
  formattedDateTimeString,
  getDateOfISOWeek,
  getWeekNumber,
  Offtime,
  Quarter,
  Reminder,
  weeksBetween,
} from '@aposphaere/core-kit'
import { Calendar, DayItem, PlusSignIcon, TimeSlot, TimeSlotKind, OffTimeMutationVariables, WeekItem } from '@aposphaere/ui-components'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react'
import { CrmContext, ICrmContext, ModalKind } from '../../contexts/crmContext'
import Spinner from '../loader.gif'
import WeekNoteSection from '../WeekNoteSection'
import OfftimeDeleteModal from '../OfftimeDeleteModal'

const dayPartIds: string[] = ['4', '5', '6', '7', '8', '9', '10', '11', '12', '13']

type CrmCalendarProps = {
  onTimeSlotClick: (date: Date, f: boolean) => void
  noRouteSet?: boolean
  selectedQuater: Quarter | undefined
  selectedDay: Date | undefined
  setSelectedDay: React.Dispatch<React.SetStateAction<Date | undefined>>
  appointments: Appointment[] | undefined
  offtimes: Offtime[] | undefined
  reminders?: Reminder[] | null
  selectedTimeslot?: Date
  onCreateOfftime?: (variables: OffTimeMutationVariables) => void
  isOverView?: boolean
}

const CrmCalendar: React.FunctionComponent<CrmCalendarProps> = ({
  onTimeSlotClick,
  noRouteSet,
  selectedQuater,
  selectedDay,
  setSelectedDay,
  appointments,
  offtimes,
  reminders,
  selectedTimeslot,
  onCreateOfftime,
  isOverView,
}) => {
  const [deleteOfftime, setDeleteOfftime] = useState<{ offtimeId: string; date?: Date } | null>(null)
  const [openDeleteOfftimeModal, setOpenDeleteOfftimeModal] = useState<boolean>(false)
  const quarterFrom = useMemo(() => (selectedQuater?.from ? new Date(selectedQuater?.from.toString().replace(/\s/, 'T')) : new Date()), [
    selectedQuater,
  ])
  const quarterTo = useMemo(() => (selectedQuater?.to ? new Date(selectedQuater?.to.toString().replace(/\s/, 'T')) : new Date()), [selectedQuater])
  const calenderContainerRef = useRef<HTMLDivElement>(null)

  const [appointmentsLookUpTable, setAppointmentsLookUpTable] = useState<Record<string, { date: string; kind: TimeSlotKind }> | undefined>(undefined)
  const crmContext = useContext(CrmContext) as ICrmContext
  const {
    onOpenModal,
    setSelectedWeek,
    selectedYear,
    setSelectedYear,
    noteWeeks,
    getNoteWeek,
    isNoteWeeksLoaded,
    offtimeType,
    refreshOfftimes,
    presentNotification,
    searchFilterHook: { filterState, dispatchFilterState },
  } = crmContext

  const currentQuarterAppointments = useMemo(
    () =>
      appointments?.filter(
        (appointment) => appointment.quarter?.id === selectedQuater?.id && appointment.status_id !== AppointmentStatusType.Canceled,
      ),
    [appointments, selectedQuater],
  )

  //this is appointment(trainings) that are not cancelled
  const currentQuarterTrainingAppointments = useMemo(
    () => currentQuarterAppointments?.filter((appointment: Appointment) => `${appointment.appointmentType?.id || 0}` === '1'),
    [currentQuarterAppointments],
  )

  // visits not cancelled
  const currentQuaterVisits = currentQuarterAppointments?.filter((appointment) => `${appointment.appointmentType?.id || 0}` === '4')

  const onDeleteOffime = useCallback(
    (deleteOff: { offtimeId: string; date?: Date }) => {
      setDeleteOfftime(deleteOff)
      setOpenDeleteOfftimeModal(true)
    },
    [setOpenDeleteOfftimeModal, setDeleteOfftime],
  )

  const showVisitPharmacies = useCallback(
    (visits: Appointment[] | undefined) => {
      const currentDayVisitsIds = visits && visits.map((visit) => visit.pharmacy?.id)
      const searchResults = filterState.pharmacies?.filter((pharmacy) => currentDayVisitsIds?.includes(pharmacy?.id))
      dispatchFilterState({ type: 'setFilterResults', payload: { searchResults } })
    },
    [dispatchFilterState, filterState.pharmacies],
  )

  const getTimeSlotOfftime = useCallback(
    (date: Date, dayTime: number): number | undefined => {
      if (offtimes && offtimes.length > 0) {
        const offtimeArr = offtimes.filter((offtime) => {
          if (offtime.date) {
            return moment(offtime.date, 'YYYY-MM-DD-hh-mm-ss').isSame(date, 'day')
          }
          return undefined
        })
        if (offtimeArr.length) {
          return offtimeArr.filter((offtime) => {
            if (offtime.date && offtime.dayParts?.length) {
              const daySlot = offtime.dayParts.findIndex((d) => `${d.id}` === `${dayTime}`)
              if (daySlot !== -1) {
                const dateString = `${offtime.date} ${offtime.dayParts[daySlot].from}`
                const offTimeDate = new Date(dateString)
                return offTimeDate.getTime() === date.getTime()
              }
            }
            return undefined
          })[0]?.id
        }
      }
      return undefined
    },
    [offtimes],
  )

  const isDayOfftimes = useCallback(
    (date: Date): { isOffTime: boolean; offTimeId: number } | undefined => {
      if (offtimes && offtimes.length > 0) {
        const dayOfftimes = offtimes.filter((offtime) => {
          if (offtime.date) {
            return moment(offtime.date, 'YYYY-MM-DD-hh-mm-ss').isSame(date, 'day')
          }
          return undefined
        })
        if (dayOfftimes && dayOfftimes.length) {
          return { isOffTime: Boolean(dayOfftimes.length), offTimeId: dayOfftimes[0].id }
        }
      }
      return undefined
    },
    [offtimes],
  )
  useEffect(() => {
    if (calenderContainerRef.current?.children && appointmentsLookUpTable) {
      const today = new Date()

      const date = new Date(today.setHours(0, 0, 0, 0))
      const el = document.getElementById(`${date.toDateString()}`)
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
      }
    }
  }, [calenderContainerRef, appointmentsLookUpTable])

  const buildCalendarLookupTable = useCallback(() => {
    // we'd like to know on which day a certain daypart
    // is either covered with an appointment offtime
    // or something else. Regulary this means that
    // the person is available.
    // For that we're building a lookup table a simple
    // hashmap. The key is a string in form of "20-01-2020–08-00-00"
    // meaning the 8 o'clok slot on january 20th 2020
    const generateTableKey = (date?: string, dayParts?: DayPart[], wholeDay?: boolean) => {
      if (!date) {
        return
      }

      if (dayParts === undefined || (dayParts?.length === 0 && !wholeDay)) {
        const dateTimeParts = date?.split(' ')
        if (dateTimeParts === undefined) {
          return
        }
        return [`${dateTimeParts[0]}-${dateTimeParts[1].replace(/:/g, '-')}`]
      }
      if (wholeDay) {
        return ['09', '10', '11', '12', '13', '14', '15', '16', '17', '18'].map((timeslot) => `${date?.split(' ')[0]}-${timeslot}-00-00`)
      }

      return dayParts?.map((dayPart) => `${date?.split(' ')[0]}-${dayPart.from.replace(/:/g, '-')}`)
    }

    const generateTableKeyKind = (kind?: Offtime['offtime_type']): TimeSlotKind => {
      switch (kind) {
        case 'Feiertag (orange)':
          return TimeSlotKind.festive
        case 'Home Office (blau)':
          return TimeSlotKind.homeOffice
        case 'Krank (rot)':
          return TimeSlotKind.disease
        case 'Privat (Rosa)':
          return TimeSlotKind.privat
        case 'Tagung (gelb)':
          return TimeSlotKind.conference
        case 'Urlaub (grün)':
          return TimeSlotKind.vacation
        default:
          return TimeSlotKind.available
      }
    }

    const lookUpTable = currentQuarterTrainingAppointments
      ?.map((appointment?: Appointment) => {
        if (appointment !== undefined && appointment?.date !== undefined && !appointment?.dayParts === undefined) {
          return null
        }

        const lookUpKeys = generateTableKey(appointment?.date, appointment?.dayParts)
        if (!lookUpKeys) {
          return null
        }
        const getFullHourDate = (date: string) => date.replace(/\d{2}(-\d{2}$)/, '00$1')
        return lookUpKeys.reduce((obj, item) => {
          const kind = moment(item, 'YYYY-MM-DD-hh-mm-ss').isBefore(new Date())
            ? TimeSlotKind.finished
            : moment(item, 'YYYY-MM-DD-hh-mm-ss').isSame(new Date(), 'day')
            ? TimeSlotKind.today
            : TimeSlotKind.booked
          return {
            ...obj,
            [getFullHourDate(item)]: {
              date: item,
              kind,
            },
          }
        }, {})
      })
      .reduce((obj, tableItem) => ({ ...obj, ...tableItem }), {})

    const offtimesLookUpTable = offtimes
      ?.map((offtime?: Offtime) => {
        if (offtime !== undefined && offtime?.date !== undefined && !offtime?.dayParts === undefined) {
          return null
        }

        const lookUpKeys = generateTableKey(offtime?.date, offtime?.dayParts, offtime?.whole_day)
        if (!lookUpKeys) {
          return null
        }

        return lookUpKeys.reduce(
          (obj, item) => ({
            ...obj,
            [item]: {
              date: item,
              kind: generateTableKeyKind(offtime?.offtime_type),
            },
          }),
          {},
        )
      })
      .reduce((obj, tableItem) => ({ ...obj, ...tableItem }), {})
    const combinedTable = { ...offtimesLookUpTable, ...lookUpTable }
    setAppointmentsLookUpTable(combinedTable)
  }, [offtimes, currentQuarterTrainingAppointments])

  useEffect(() => {
    if (appointmentsLookUpTable === undefined && appointments !== undefined && offtimes !== undefined) {
      buildCalendarLookupTable()
    }
  }, [appointmentsLookUpTable, appointments, offtimes, buildCalendarLookupTable])

  useEffect(() => {
    buildCalendarLookupTable()
    setSelectedDay(undefined)
  }, [setSelectedDay, buildCalendarLookupTable])

  useEffect(() => {
    const year = new Date(quarterFrom).getFullYear()
    setSelectedYear(year)
  }, [quarterFrom, setSelectedYear])

  const determineDayItemKind = (date: Date, hour: number) => {
    if (!appointmentsLookUpTable) {
      return TimeSlotKind.available
    }
    const padDatePart = (h: number) => (h < 10 ? `0${h}` : h)
    const lookUpKey = `${date.getFullYear()}-${padDatePart(date.getMonth() + 1)}-${padDatePart(date.getDate())}-${padDatePart(hour)}-00-00`

    const value = appointmentsLookUpTable[lookUpKey]?.kind
    if (value) {
      return value
    }

    return TimeSlotKind.available
  }

  const determineDayItemHoverText = (kind: TimeSlotKind) => {
    switch (kind) {
      case TimeSlotKind.available:
        return 'verfügbar'
      case TimeSlotKind.blocked:
        return 'nicht verfügbar'
      case TimeSlotKind.booked:
        return 'geplant'
      case TimeSlotKind.conference:
        return 'Tagung'
      case TimeSlotKind.disabled:
        return 'nicht verfügbar'
      case TimeSlotKind.disease:
        return 'krank'
      case TimeSlotKind.festive:
        return 'Feiertag'
      case TimeSlotKind.finished:
        return 'abgeschlossen'
      case TimeSlotKind.homeOffice:
        return 'Home Office'
      case TimeSlotKind.privat:
        return 'privat'
      case TimeSlotKind.today:
        return 'heute'
      case TimeSlotKind.vacation:
        return 'Urlaub'
      default:
        return ''
    }
  }

  const getDaysForGivenWeek = (index: number) => {
    const year = new Date(quarterFrom).getFullYear()
    const weekNumber = getWeekNumber(new Date(quarterFrom), index)
    const dateOfIsoWeek = getDateOfISOWeek(weekNumber, year)
    return daysOfTheWeek(dateOfIsoWeek, false)
  }

  const slotDate = (date: Date, hour: number) => {
    const padDatePart = (h: number) => (h < 10 ? `0${h}` : h)
    const lookUpKey = `${date.getFullYear()}-${padDatePart(date.getMonth() + 1)}-${padDatePart(date.getDate())}-${padDatePart(hour)}-00-00`
    const currentTime = appointmentsLookUpTable && appointmentsLookUpTable[lookUpKey]?.date
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, currentTime ? +currentTime.slice(-5, -3) : 0, 0, 0)
  }

  const selectUnselectDay = (day: Date): void => {
    setSelectedDay((prevDay) => {
      if (prevDay) {
        return formattedDateTimeString(prevDay) === formattedDateTimeString(day) ? undefined : day
      }
      return day
    })
    dispatchFilterState({ type: 'clear' })
  }

  const listOfReminders = useMemo<Reminder[]>(() => {
    if (reminders) {
      return reminders.filter((reminder) => moment(reminder.until).isBetween(quarterFrom, quarterTo))
    }
    return []
  }, [quarterFrom, quarterTo, reminders])

  const createClickHandler = (modalKind: ModalKind) => (index: number) => {
    if (!isNoteWeeksLoaded) {
      return
    }
    const week = getWeekNumber(new Date(quarterFrom), index)
    setSelectedWeek(week)
    onOpenModal(modalKind)
  }

  const handleCreateClick = createClickHandler(ModalKind.NoteWeekPharmacyClusterCreate)
  const handleUpdateClick = createClickHandler(ModalKind.NoteWeekPharmacyClusterUpdate)
  const handleDeleteClick = createClickHandler(ModalKind.NoteWeekPharmacyClusterDelete)

  const renderCreateWeekNoteButton = (index: number) => (
    <button
      onClick={() => handleCreateClick(index)}
      className="flex ml-2 items-center h-fit px-0.5 py-0.5 rounded-md text-gray-400 hover:bg-blue-200 hover:text-blue-700 focus:border-gray-300 active:text-blue-800 focus:outline-none transition ease-in-out duration-150"
    >
      <PlusSignIcon />
    </button>
  )

  const arrOffWeeks = weeksBetween(quarterFrom, quarterTo)
    ? Array.from(new Array(weeksBetween(quarterFrom, quarterTo)), (_, index) => {
        const weekNumber = getWeekNumber(new Date(quarterFrom), index)
        const noteForSelectedWeek = getNoteWeek(weekNumber, selectedYear)?.note

        return (
          <WeekItem
            key={weekNumber}
            weekNumber={weekNumber}
            appendComponent={isNoteWeeksLoaded && !noteForSelectedWeek ? renderCreateWeekNoteButton(index) : undefined}
          >
            {noteWeeks && noteForSelectedWeek && (
              <>
                <WeekNoteSection note={noteForSelectedWeek} onUpdate={() => handleUpdateClick(index)} onDelete={() => handleDeleteClick(index)} />
                <WeekNoteDivider />
              </>
            )}

            {getDaysForGivenWeek(index).map((day, i) => {
              const visits = currentQuaterVisits?.filter(
                (app) => formattedDateString(day) === formattedDateString(new Date(app.date?.replace(/\s/, 'T') || '')),
              )
              const countOfReminders = noRouteSet ? 0 : listOfReminders.filter((reminder) => moment(reminder.until).isSame(day, 'day')).length
              const id = `${new Date(day).toDateString()}`
              return (
                <DayItem
                  countOfReminders={countOfReminders}
                  onClick={() => (noRouteSet ? null : selectUnselectDay(day))}
                  selected={formattedDateTimeString(selectedDay || new Date()) === formattedDateTimeString(day)}
                  key={i}
                  date={new Date(day)}
                  visits={visits?.length || 0}
                  isOverView={isOverView}
                  offtimeType={offtimeType}
                  onCreateOfftime={onCreateOfftime}
                  isDayOfftimes={isDayOfftimes}
                  onDeleteOfftime={onDeleteOffime}
                  id={id}
                  showVisits={() => showVisitPharmacies(visits)}
                >
                  {[9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map((hour, idx) => {
                    const date = slotDate(new Date(day), hour)
                    const disabled = noRouteSet ? moment(new Date()).isSameOrAfter(date) : false
                    const kind = determineDayItemKind(new Date(day), hour)
                    const disabledForSelect =
                      disabled ||
                      ![
                        TimeSlotKind.vacation,
                        TimeSlotKind.homeOffice,
                        TimeSlotKind.privat,
                        TimeSlotKind.festive,
                        TimeSlotKind.disease,
                        TimeSlotKind.conference,
                        TimeSlotKind.vacation,
                        TimeSlotKind.available,
                        TimeSlotKind.finished,
                      ].includes(kind)
                    return (
                      <TimeSlot
                        key={`time-slot-${i}-${hour}`}
                        disabled={disabledForSelect}
                        slotInfo={determineDayItemHoverText(kind)}
                        date={date}
                        dayPart={dayPartIds[idx]}
                        selected={!!selectedTimeslot && moment(selectedTimeslot).isSame(date, 'hour')}
                        kind={disabled ? TimeSlotKind.disabled : kind}
                        onClick={onTimeSlotClick}
                        offtimeType={offtimeType}
                        onCreateOfftime={onCreateOfftime}
                        onDeleteOfftime={onDeleteOffime}
                        getTimeSlotOfftime={getTimeSlotOfftime}
                        isOverView={isOverView}
                        booked={kind === TimeSlotKind.booked}
                        noRouteSet={noRouteSet}
                      />
                    )
                  })}
                </DayItem>
              )
            })}
          </WeekItem>
        )
      })
    : []
  return !appointmentsLookUpTable ? (
    <div className="flex self-center rounded-md p-4 bg-white w-fit mx-auto h-750px">
      <div className="flex items-center justify-center flex-wrap max-w-48">
        <div className="w-full">
          <img className="mx-auto w-16" src={Spinner} alt="" />
        </div>
        <div className="flex flex-wrap w-fit text-center mx-auto justify-center font-body mt-6 text-base text-blue-400 leading-5">
          {'Wird geladen...'}
        </div>
      </div>
    </div>
  ) : (
    <div ref={calenderContainerRef}>
      {deleteOfftime && openDeleteOfftimeModal ? (
        <OfftimeDeleteModal
          deleteOff={deleteOfftime}
          onClose={() => {
            setOpenDeleteOfftimeModal(false)
            setDeleteOfftime(null)
          }}
          refreshOfftimes={refreshOfftimes}
          presentNotification={presentNotification}
        />
      ) : null}
      <Calendar key={`calendar-${String(quarterFrom)}`}>{arrOffWeeks}</Calendar>
    </div>
  )
}

const WeekNoteDivider = () => (
  <div className="flex">
    <div className={`flex w-15pc border-b border-gray-300 ml-2 pb-3 text-base font-body items-center`}></div>
    <div className={`flex flex-wrap w-80pc pb-3 border-b border-gray-300 `}></div>
    <div
      className={`text-base w-5pc flex ml-2 pb-3 border-b border-gray-300 justify-center items-center 
    text-gray-400`}
    ></div>
  </div>
)

export default React.memo(CrmCalendar)
