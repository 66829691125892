import Pagination from 'rc-pagination'
import React from 'react'

interface ICustomPagination {
  onChange: React.Dispatch<React.SetStateAction<number>>
  current: number
  total: number
}

const CustomPagination: React.FC<ICustomPagination> = ({ onChange, current, total }) => (
  <Pagination
    className="relative z-0 inline-flex align-middle justify-end pr-2 shadow-sm -space-x-px border-t border-gray-400 w-full py-4 focus:outline-none"
    onChange={onChange}
    current={current}
    total={total}
    showTotal={(tot, range) => <div className="text-base text-gray-700 p-2 pl-4">{`Einträge ${range[0]} - ${range[1]} von ${tot}`}</div>}
    pageSize={13}
    itemRender={(curr, type, element) => {
      switch (type) {
        case 'prev':
          return (
            <div className="relative inline-flex h-full items-center px-2 py-2 rounded-l-md border border-gray-400 bg-white text-sm font-medium text-gray-500 hover:bg-blue-100 hover:text-blue-700">
              <span className="sr-only">{'Previous'}</span>
              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )
        case 'next':
          return (
            <div className="relative inline-flex h-full items-center px-2 py-2 rounded-r-md border border-gray-400 bg-white text-sm font-medium text-gray-500 hover:bg-blue-100 hover:text-blue-700">
              <span className="sr-only">{'Next'}</span>
              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )
        case 'page':
          return (
            <div
              className={`relative inline-flex h-full h6 cursor-pointer items-center px-4 py-2 border border-gray-400 bg-white text-base font-medium text-gray-700 hover:bg-blue-100 hover:text-blue-700 ${
                curr === current ? 'bg-blue-100 text-blue-700' : ''
              }`}
            >
              {curr}
            </div>
          )
        case 'jump-prev':
        case 'jump-next':
          return (
            <div className="relative inline-flex h-full cursor-pointer items-center px-4 py-2 border border-gray-400 bg-white text-base font-medium text-gray-700 hover:bg-blue-100 hover:text-blue-700">
              {'...'}
            </div>
          )
        default:
          return element
      }
    }}
    showTitle={false}
  />
)

export default CustomPagination
