import { gql } from 'graphql-request'

export const availableReports = gql`
  {
    availableReports
  }
`
export const getActivities = gql`
  query getActivities($quarterId: Int) {
    activities(quarter_id: $quarterId) {
      past_appointments
      past_visits
      past_average_appointments_per_day
      future_appointments
      future_visits
      future_average_appointments_per_day
    }
  }
`

export const getAppointmentsPerProject = gql`
  query getAppointmentsPerProject($quarterId: Int, $projectId: Int) {
    appointments_per_project(quarter_id: $quarterId, project_id: $projectId) {
      past_appointments
      past_slots
      past_participants
      past_average_participants
      past_average_duration_in_minutes
      future_pharmacies_with_appointments
      future_slots
    }
  }
`