import { useCallback } from 'react'
import moment from 'moment'

const useCETTime = () =>
  useCallback((date: string) => {
    const [dateString, timeString] = date.split(' ')
    const dateStringFormat = dateString.replace(/-/g, '/')
    const toDate = new Date(`${dateStringFormat} ${timeString}`)
    // hack to get the correct current local miliseconds
    const localTime = moment(toDate).valueOf() - 360000
    const localOffSet = moment(toDate).utcOffset() * 6000
    const utc = localTime + localOffSet
    // 10000 miliseconds * 60 * 60
    const result = utc + 3600000
    const time = moment(new Date(result)).format('YYYY-MM-DD HH:mm:ss')

    return time.split(' ')
  }, [])
export default useCETTime
