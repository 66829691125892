import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { OfftimeKind } from '../OfftimeLabel'
export enum TimeSlotKind {
  available = 'text-gray-700 bg-white border-gray-400 hover:bg-gray-900 hover:text-white active:bg-gray-900',
  homeOffice = 'text-green-900 bg-striped-green border-green-200b',
  booked = 'text-blue-700 bg-blue-100 border-blue-100 hover:bg-blue-600 hover:text-white active:bg-blue-700',
  vacation = 'text-orange-900 bg-striped-orange border-orange-200',
  blocked = 'text-gray-600 bg-striped-gray border-gray-200',
  conference = 'text-yellow-900 bg-striped-yellow border-yellow-200',
  privat = 'text-brown-900 bg-striped-brown border-brown-200',
  festive = 'text-purple-900 bg-striped-purple border-purple-200b',
  disabled = 'text-gray-600 bg-striped-gray border-gray-200',
  disease = 'text-pink-900 bg-striped-pink border-pink-200',
  today = 'text-red-800 bg-red-300 border-red-300 border-red-400 hover:bg-red-600 hover:text-white active:bg-red-800',
  finished = 'text-green-700 bg-green-100 border-green-100 hover:bg-green-600 hover:text-white active:bg-green-800',
  selected = 'text-blue-gray-800 bg-green-300-hi border-green-300-hi hover:bg-green-300-hi hover:text-white active:bg-green-300-hi',
}
export type OffTimeMutationVariables = {
  offtime_type: string
  whole_day: boolean
  day_parts?: string[]
  date: Date
}

export type TimeSlotProps = {
  kind: TimeSlotKind
  date?: Date
  slotInfo?: string
  onClick?: (date: Date, f: boolean) => void
  disabled?: boolean
  selected: boolean
  dayPart?: string
  isOverView?: boolean
  offtimeType?: OfftimeKind | undefined
  onCreateOfftime?: (variables: OffTimeMutationVariables) => void
  onDeleteOfftime: (variable: DeleteOfftimeType) => void
  getTimeSlotOfftime?: (date: Date, dayTime: number) => number | undefined
  booked?: boolean
  noRouteSet?: boolean
}

export type DeleteOfftimeType = {
  offtimeId: string
  date?: Date
}

const TimeSlot: FunctionComponent<TimeSlotProps> = ({
  disabled,
  kind = TimeSlotKind.available,
  slotInfo,
  onClick,
  date,
  selected,
  dayPart,
  offtimeType,
  onCreateOfftime,
  isOverView,
  onDeleteOfftime,
  getTimeSlotOfftime,
  booked,
  noRouteSet,
}) => {
  const formattedTimeString = (dateForFormat: Date) => {
    const t = dateForFormat
    const hh = new Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false }).format(t)
    const mm = `0${date.getMinutes()}`.slice(-2)
    return `${hh}:${mm}`
  }
  const toNumberDayTime: number = useMemo(() => parseInt(dayPart, 10), [dayPart])
  const handleOnclick = useCallback(
    (e: React.SyntheticEvent) => {
      const offTimeToDeleteId = getTimeSlotOfftime(date, toNumberDayTime)
      if (offTimeToDeleteId) {
        e.stopPropagation()
        onDeleteOfftime({ offtimeId: `${offTimeToDeleteId}`, date })
        return
      }
      if (offtimeType !== undefined && onCreateOfftime && isOverView && !offTimeToDeleteId && !booked) {
        e.stopPropagation()
        const variables: OffTimeMutationVariables = {
          offtime_type: offtimeType,
          whole_day: false,
          day_parts: [dayPart],
          date,
        }
        onCreateOfftime(variables)
        return
      }
      onClick(date, true)
    },
    [onClick, offtimeType, date, isOverView, getTimeSlotOfftime, onDeleteOfftime, dayPart, onCreateOfftime, booked, noRouteSet],
  )

  const timeString = formattedTimeString(date)
  return (
    <div
      title={`${slotInfo}`}
      onClick={(e) => (disabled ? null : handleOnclick(e))}
      className={`flex justify-center items-center text-center font-body text-base cursor-pointer w-17pc border border-solid m-1 rounded py-0.5 transition duration-300 ease-in-out hover:shadow-md ${kind} ${
        selected ? TimeSlotKind['selected'] : ''
      }`}
    >
      {timeString}
    </div>
  )
}

export default TimeSlot
