import { debounce, Pharmacy, Project, IPAD_BREAK_POINT } from '@aposphaere/core-kit'
import {
  FilterIcon,
  IconButton,
  IconButtonKind,
  InputSearchFilter,
  InputSearchNoResults,
  InputSearchPanel,
  InputSearchPanelKind,
  InputSearchSuggestions,
  ListViewIcon,
  LocationMarkerInfoIcon,
  MapIcon,
  MaximizeIcon,
  SearchInputMarkerInfo,
  TagToggleButton,
} from '@aposphaere/ui-components'
import { isEmptyArray } from 'formik'
import React, { useCallback, useContext, useState } from 'react'
import { CrmContext, ICrmContext } from '../../contexts/crmContext'
import { useViewport } from '../../hooks/useViewPort'

const ActionPanel: React.FC = () => {
  const [showMax, setShowMax] = useState<boolean>(true)
  const [isTyping, setIsTyping] = useState<boolean>(false)

  const { width } = useViewport()

  const crmContext = useContext(CrmContext) as ICrmContext
  const {
    activePharmaciesTableHook: { listViewedActivePharmaciesTable, setListViewedActivePharmaciesTable },
    setActivePharmacy,
    setSelectedPharmacyId,
    selectedPharmacyId,
    searchFilterHook: { filterState, dispatchFilterState },
    filterOnMapHook: { filterOnMapState, dispatchFilterOnMapState },
    onAllProjectClick,
    activeProjects,
    routeHook: { setRouteResponse },
    selectedDayHook: { setSelectedDay },
  } = crmContext
  const resultStyle = 'w-full h-fit my-0 rounded text-base p-2 cursor-pointer hover:bg-blue-50'
  const [showSearchSuggestions, setShowSearchSuggestions] = useState(
    () => !!(!listViewedActivePharmaciesTable && filterState.searchResults.length && !filterState.selectedPharmacy),
  )
  const [showCardLegend, setShowCardLegend] = useState(false)

  const onSearchInput = debounce<string[]>((input: string) => {
    if (input.length >= 1) {
      const searchResults = filterState.pharmacies?.filter((pharmacy) => pharmacy?.name?.toLowerCase().includes(input.toLowerCase()))
      dispatchFilterState({ type: 'setFilterResults', payload: { inputValue: input, searchResults } })
      checkActivePharmacyExistOnMap(searchResults)
      setShowSearchSuggestions(true)
    } else {
      dispatchFilterState({ type: 'clear' })
      setShowSearchSuggestions(false)
    }
  }, 1000)

  const checkActivePharmacyExistOnMap = (arr: Pharmacy[]) => {
    if (!arr.find((pharmacy) => pharmacy.id === selectedPharmacyId)) {
      setActivePharmacy(undefined)
      setSelectedPharmacyId(undefined)
    }
  }

  const onSearchSuggestionClicked = (pharmacy: Pharmacy) => {
    dispatchFilterState({ type: 'setPharmacy', payload: { selectedPharmacy: pharmacy } })
    checkActivePharmacyExistOnMap([pharmacy])
    setShowSearchSuggestions(false)
    setSelectedDay(undefined)
    setRouteResponse(null)
  }

  // render expanded content
  const ExpandedContent = () => {
    let renderedContent = null
    if (isEmptyArray(filterState.searchResults)) {
      renderedContent = <InputSearchNoResults />
    } else {
      renderedContent = listViewedActivePharmaciesTable ? null : (
        <InputSearchSuggestions>
          {filterState.searchResults.map((pharmacy) => (
            <div key={`${pharmacy.id}`} className={`${resultStyle}`} onClick={() => onSearchSuggestionClicked(pharmacy)}>
              {`${pharmacy.name || ''}, ${pharmacy.address?.address_name || ''}, ${pharmacy.address?.zipcode || ''} ${pharmacy.address?.city || ''}`}
            </div>
          ))}
        </InputSearchSuggestions>
      )
    }
    return renderedContent
  }

  const setFilterByProjectOption = useCallback(
    (optionAndActive: boolean) => dispatchFilterOnMapState({ type: 'setAndOption', payload: { optionAndActive } }),
    [dispatchFilterOnMapState],
  )

  /* Checking if Project is Selected */
  const isProjectSelected = (project: Project): boolean => !!filterOnMapState.selectedProjects?.some((pr) => pr.id === project.id)
  const areAllSelected = (): boolean => filterOnMapState.selectedProjects?.length === activeProjects?.length

  /* Input */
  const InputFilter: React.FC = () => (
    <InputSearchFilter setFilterByProjectOption={setFilterByProjectOption} optionAndActive={filterOnMapState.optionAndActive}>
      <div className={'flex-1 border-r mr-4 border-gray-400 border-solid'}>
        <TagToggleButton
          key={`projectAllFutureProject'}`}
          onClick={() => dispatchFilterOnMapState({ type: 'onActiveProject' })}
          customCSS="mr-2 mb-2"
          selected={filterOnMapState.onActiveProject}
        >
          {'Termin vereinbart'}
        </TagToggleButton>
        <TagToggleButton key={`projectAll}`} onClick={onAllProjectClick} customCSS="mr-2 mb-2" selected={areAllSelected()}>
          {'Alle'}
        </TagToggleButton>
        {activeProjects?.map((project) => (
          <TagToggleButton
            key={`project-${project.id}`}
            onClick={() => {
              crmContext.onProjectClick(project)
            }}
            customCSS="mr-2 mb-2"
            selected={isProjectSelected(project)}
          >
            {project.name}
          </TagToggleButton>
        ))}{' '}
      </div>
      <div className={'flex-1'}>
        <TagToggleButton
          key={`Nur Prio'}`}
          onClick={() => dispatchFilterOnMapState({ type: 'onPotential' })}
          customCSS="mr-2 mb-2"
          selected={filterOnMapState.onPotential}
        >
          {'Nur Prio'}
        </TagToggleButton>
        <TagToggleButton
          key={`inkl. Absager'}`}
          onClick={() => dispatchFilterOnMapState({ type: 'onPriority' })}
          customCSS="mr-2 mb-2"
          selected={filterOnMapState.onPriority}
        >
          {'inkl. Absager'}
        </TagToggleButton>
      </div>
    </InputSearchFilter>
  )

  const determineExpandedContent = () => {
    if (showCardLegend) {
      return <SearchInputMarkerInfo />
    }
    if (filterState.showInputSearchFilter) {
      return <InputFilter />
    }

    if (showSearchSuggestions) {
      return <ExpandedContent />
    }

    return null
  }
  return (
    <>
      {showMax ? (
        <InputSearchPanel
          selectedPharmacy={filterState.inputValue}
          kind={InputSearchPanelKind.overlay}
          placeholderText="Standort per ID oder Name suchen."
          onType={onSearchInput}
          expandedContent={determineExpandedContent()}
          isListViewedActivePharmaciesTable
          handleOnBlur={() => setIsTyping(false)}
          handleOnClick={() => setIsTyping(true)}
        >
          {!isTyping ? (
            <>
              <span className="text-gray-700 hidden xl:inline text-base ml-4">{'Ansicht:'}</span>
              <IconButton
                kind={IconButtonKind.custom}
                selected={listViewedActivePharmaciesTable}
                icon={<ListViewIcon dimension={width && width <= IPAD_BREAK_POINT ? '30' : '36'} />}
                additionalCss="ml-2 focus:outline-none text-gray-500 hover:bg-gray-300 hover:text-blue-800
        rounded p-1 active:bg-blue-400 active:text-white transition ease-in-out duration-150"
                onClick={() => setListViewedActivePharmaciesTable((prev) => !prev)}
              />
              <IconButton
                kind={IconButtonKind.custom}
                selected={!listViewedActivePharmaciesTable}
                icon={<MapIcon dimension={width && width <= IPAD_BREAK_POINT ? '30' : '36'} />}
                additionalCss="mr-2 focus:outline-none text-gray-700 hover:bg-gray-300 hover:text-blue-800
        rounded p-1 active:bg-blue-400 active:text-white transition ease-in-out duration-150"
                onClick={() => setListViewedActivePharmaciesTable((prev) => !prev)}
              />
              <div className="inline-flex border-l border-gray-400 border-solid w-px h-10 mx-0" />
              <IconButton
                kind={IconButtonKind.custom}
                selected={filterState.showInputSearchFilter}
                isExpandable={true}
                icon={<FilterIcon dimension={width && width <= IPAD_BREAK_POINT ? '30' : '36'} />}
                additionalCss="mx-2 focus:outline-none text-gray-700 hover:bg-gray-300 hover:text-blue-800
        rounded p-1 active:bg-blue-400 active:text-white transition ease-in-out duration-150"
                onClick={() => dispatchFilterState({ type: 'toggleShowInputSearchFilter' })}
              />
              <div className="inline-flex border-l border-gray-400 border-solid w-px h-10 mx-0" />
              <IconButton
                kind={IconButtonKind.custom}
                selected={showCardLegend}
                isExpandable={true}
                icon={<LocationMarkerInfoIcon dimension={width && width <= IPAD_BREAK_POINT ? '30' : '36'} />}
                additionalCss="mx-2 focus:outline-none text-gray-700 hover:bg-gray-300 hover:text-blue-800 rounded
        p-1 active:bg-blue-400 active:text-white transition ease-in-out duration-150 "
                onClick={() => setShowCardLegend(!showCardLegend)}
              />
              <div className="inline-flex border-l border-gray-400 border-solid w-px h-10 mx-0" />
              <IconButton
                kind={IconButtonKind.custom}
                selected={false}
                icon={<MaximizeIcon dimension={width && width <= IPAD_BREAK_POINT ? '30' : '36'} />}
                additionalCss="mx-2 xl:my-1.5 focus:outline-none text-gray-700 hover:bg-gray-300 hover:text-blue-800 rounded
        p-1 active:bg-blue-400 active:text-white transition ease-in-out duration-150 "
                onClick={() => setShowMax(!showMax)}
              />
            </>
          ) : null}
        </InputSearchPanel>
      ) : (
        <div title="maximize action panel" className="bg-white w-12 absolute  right-0 flex justify-center item center">
          <IconButton
            kind={IconButtonKind.custom}
            selected={false}
            icon={<MaximizeIcon dimension={width && width < IPAD_BREAK_POINT ? '30' : '36'} />}
            additionalCss="mx-2 xl:my-1.5 focus:outline-none text-gray-700 hover:bg-gray-300 hover:text-blue-800 rounded
        p-1 active:bg-blue-400 active:text-white transition ease-in-out duration-150 "
            onClick={() => setShowMax(!showMax)}
          />
        </div>
      )}
    </>
  )
}

export default ActionPanel
