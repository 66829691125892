import { Pharmacy } from '@aposphaere/core-kit'

export interface IFilterState {
  searchResults: Pharmacy[]
  pharmacies: Pharmacy[]
  selectedPharmacy: Pharmacy | undefined
  inputValue: string
  showInputSearchFilter: boolean
}

export interface IFilterAction {
  type: IFilterType
  payload?: {
    searchResults?: Pharmacy[]
    pharmacies?: Pharmacy[]
    selectedPharmacy?: Pharmacy
    inputValue?: string
  }
}

type IFilterType = 'clear' | 'setFilterResults' | 'setPharmacy' | 'setPharmacies' | 'toggleShowInputSearchFilter' | 'updateFilterResults'

export const filterReducer = <S extends IFilterState, A extends IFilterAction>(state: S, action: A): IFilterState => {
  switch (action.type) {
    case 'clear':
      return {
        ...initialFilterState,
        pharmacies: state.pharmacies,
      }
    case 'setFilterResults':
      return {
        ...state,
        searchResults: action.payload?.searchResults,
        selectedPharmacy: undefined,
        inputValue: action.payload?.inputValue,
        showInputSearchFilter: false,
      }
    case 'setPharmacy':
      return {
        ...state,
        searchResults: [action.payload?.selectedPharmacy],
        inputValue: action.payload?.selectedPharmacy?.name,
        selectedPharmacy: action.payload?.selectedPharmacy,
      }
    case 'setPharmacies':
      return {
        ...state,
        pharmacies: action?.payload?.pharmacies,
      }
    case 'toggleShowInputSearchFilter':
      return {
        ...state,
        showInputSearchFilter: !state.showInputSearchFilter,
      }
    case 'updateFilterResults':
      return {
        ...state,
        searchResults: action.payload?.searchResults,
      }
    default:
      return { ...state }
  }
}

export const initialFilterState: IFilterState = {
  searchResults: [],
  pharmacies: [],
  selectedPharmacy: undefined,
  inputValue: '',
  showInputSearchFilter: false,
}
