import { gql } from 'graphql-request'

export const allOfftimes = gql`
  {
    offtimes {
      id
    }
  }
`
export const userOfftimes = gql`
  query userOfftimes($userId: ID) {
    offtimes(user_id: $userId) {
      id
      date
      whole_day
      offtime_type
      dayParts {
        id
        from
        till
      }
    }
  }
`
