import {
  createAppointment,
  deleteAppointment,
  formattedDateTimeString,
  Project,
  updateAppointment,
  useAbilities,
  useAuth,
  formattedDateString,
  Quarter,
} from '@aposphaere/core-kit'
import {
  Button,
  ButtonKind,
  DeleteIcon,
  EditIcon,
  IconButton,
  IconButtonKind,
  InputLabel,
  InputMessage,
  InputMessageKind,
  Modal,
  ModalKind,
  NotificationBannerKind,
  TextAreaInput,
  TextInput,
  Checkbox,
} from '@aposphaere/ui-components'
import { Formik, Form } from 'formik'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useMemo, useState, useLayoutEffect } from 'react'
import * as Yup from 'yup'
import { CrmContext, ICrmContext } from '../../contexts/crmContext'
import CrmCalendar from '../CrmCalendar'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'

import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'
import InputWithValidation from '../TexInputHOC/TexInputWithValidation'
import TerminationReasonModal from './TerminationReasonModal'

/* German localisation  */
const MONTHS = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
const WEEKDAYS_LONG = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
const WEEKDAYS_SHORT = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']

type AppointmentFormValues = {
  pharmacyId?: number
  name: string
  salutation: string
  contactPerson: string
  appointmentTypeId: string
  statusId: string
  note: string
  sendConfirmation: boolean
  customEmail?: string
}

type OrderDetailsItem = {
  project_id: string
  amount: string
  length_in_seconds: number
}

/* Hold all selected project Items */

const AppointmentModal: React.FC = () => {
  const context = useContext(CrmContext) as ICrmContext
  const {
    appointmentHook: { setCurrentAppointment, currentlySelectedAppointment },
    projects,
    activeProjects,
    statuses,
    appointmentTypes,
    currentlyActivePharmacy,
    selectPharmacyBranchHook: { pharmacyBranchActive, setPharmacyBranchActive, pharmacyDetails },
    quarters,
    selectedDayHook: { selectedDay, setSelectedDay },
    appointments,
    offtimes,
    updatePharmacies,
  } = context
  const auth = useAuth()
  const { delete_appointments } = useAbilities()
  const [endTimeLabel, setEndTimeLabel] = useState<string>('')
  const [isCustomEmail, setIsCustomEmail] = useState<boolean>(false)

  const mutationCreate = useAuthenticatedMutation(createAppointment)
  const mutationUpdate = useAuthenticatedMutation(updateAppointment)
  const mutationDelete = useAuthenticatedMutation(deleteAppointment)

  const activePharmacy = pharmacyBranchActive ? pharmacyDetails : currentlyActivePharmacy
  const [title, setTitle] = useState<string>('Neuer Termin')
  const [displayCalendar, setDisplayCalendar] = useState<boolean>(false)
  const [selectedTimeslot, setSelectedTimeSlot] = useState<Date | undefined>(undefined)
  const [selectedTimeSlotManual, setSelectedTimeSlotManual] = useState<Date | undefined>(undefined)
  const [selectedDaySlotManual, setSelectedDaySlotManual] = useState<Date | undefined>(undefined)
  const [timeSlotEntryInvalid, setTimeSlotEntryInvalid] = useState<boolean | undefined>(undefined)
  const [selectedOrderItems, setSelectedOrderItems] = useState<OrderDetailsItem[]>([])
  const [selectedQuarter, setSelectedQuarter] = useState<Quarter | null>(null)
  const [isOccupied, setIsOccupied] = useState<boolean>(false)
  const [isTerminationReasonModalOpen, setTerminationReasonModalOpen] = useState(false)
  const validQuarters = useMemo<Quarter[]>(() => {
    if (quarters) {
      return quarters.filter((quarter) => new Date() < new Date(quarter.to?.toString().replace(/\s/, 'T') || ''))
    }
    return []
  }, [quarters])

  const endDate = useMemo(
    () => new Date(Math.max(...validQuarters.map((quarter) => new Date(quarter.to?.toString().replace(/\s/, 'T') || '').getTime()))),
    [validQuarters],
  )
  const isValidEmail = useMemo(() => {
    const emailPattern = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,4}$/
    return emailPattern.test(activePharmacy?.email || '')
  }, [activePharmacy])

  useLayoutEffect(() => {
    if (currentlySelectedAppointment) {
      setTitle('Termin aktualisieren')
    }
  }, [currentlySelectedAppointment])

  useEffect(() => {
    if (currentlySelectedAppointment && projects) {
      setSelectedTimeSlot(new Date(currentlySelectedAppointment.date?.toString().replace(/\s/, 'T') || ''))
      const orderItems: OrderDetailsItem[] = currentlySelectedAppointment.order_items.map((item) => ({
        amount: `${item.amount}`,
        project_id: `${item.project_id}`,
        length_in_seconds: projects?.find((project) => `${project.id}` === `${item.project_id}`)?.booked_time_in_seconds || 1800,
      }))
      setSelectedOrderItems(orderItems)
    } else {
      setSelectedTimeSlot(undefined)
    }
  }, [currentlySelectedAppointment, projects])

  const intialFormValues: AppointmentFormValues = {
    pharmacyId: activePharmacy ? activePharmacy.id : undefined,
    name: activePharmacy ? activePharmacy.name || '' : '',
    salutation: 'Frau',
    contactPerson: '',
    appointmentTypeId: currentlySelectedAppointment
      ? `${currentlySelectedAppointment?.appointmentType?.id || 1}`
      : appointmentTypes
      ? appointmentTypes[0].id.toString()
      : '',
    statusId: currentlySelectedAppointment ? `${currentlySelectedAppointment?.status?.id || 0}` : statuses ? statuses[0].id.toString() : '',
    note: currentlySelectedAppointment?.note || '',
    sendConfirmation: activePharmacy?.email ? true : false,
  }

  const AppointmentValidationScheme = Yup.object().shape({
    name: Yup.string().required('Pflichtfeld'),
    contactPerson: Yup.string().required('Pflichtfeld'),
    salutation: Yup.string().min(1, 'Zu kurz').required('Pflichtfeld'),
    appointmentTypeId: Yup.string().required('Bitte auswählen'),
    statusId: Yup.string().required('Bitte auswählen'),
    customEmail: isCustomEmail ? Yup.string().email().required('Pflichtfeld') : Yup.string(),
  })

  const onFormSubmit = async (values: AppointmentFormValues) => {
    if (!selectedTimeslot) {
      setTimeSlotEntryInvalid(true)
    } else {
      setTimeSlotEntryInvalid(false)
    }
    // clear UTC times for us =)
    selectedTimeslot && selectedTimeslot.setUTCHours(selectedTimeslot?.getHours(), selectedTimeslot?.getMinutes(), 0, 0)
    const appointmentVariables = {
      pharmacy_id: values.pharmacyId,
      quarter_id: selectedQuarter?.id || currentlySelectedAppointment?.quarter?.id,
      status_id: values.statusId,
      appointment_type_id: values.appointmentTypeId,
      creator_id: auth.user?.id,
      date: selectedTimeslot?.toISOString(),
      note: values.note === '' ? '– empty – ' : values.note,
      order_items: selectedOrderItems.map((item) => ({
        project_id: item.project_id,
        amount: item.amount,
      })),
      id: currentlySelectedAppointment?.id,
      send_confirmation: values.sendConfirmation,
      confirmation_email_address: isCustomEmail ? values.customEmail : '',
    }

    try {
      currentlySelectedAppointment ? await mutationUpdate(appointmentVariables) : await mutationCreate(appointmentVariables)
      const updatedPharmacy = await context?.getFreshPharmacyDetails(values.pharmacyId || 0, pharmacyBranchActive)
      updatePharmacies(updatedPharmacy)
      if (pharmacyBranchActive) {
        setPharmacyBranchActive(false)
      }
      context.refreshAppointments()
      closeModal()
      context.presentNotification({
        title: currentlySelectedAppointment ? 'Termin erfolgreich eritiert' : 'Termin erfolgreich angelegt',
        content: '',
        kind: NotificationBannerKind.success,
      })
    } catch (err) {
      context.presentNotification({
        title: 'Zu dieser Zeit ist kein Termin möglich.',
        content: '',
        kind: NotificationBannerKind.danger,
      })
    }
  }

  const onAppointmentDeleteBtnClick = () => {
    setTerminationReasonModalOpen(true)
  }

  const handleDeleteAppointment = async (terminationReasonID: number) => {
    const response = await mutationDelete({
      id: currentlySelectedAppointment?.id,
      terminationReasonID: terminationReasonID,
    })
    if (response.errors !== undefined) {
      console.warn(response.errors)
      return
    }
    context.refreshAppointments()
    closeModal()
    context.presentNotification({
      title: 'Termin erfolgreich gelöscht',
      content: '',
      kind: NotificationBannerKind.success,
    })
  }

  const handleTerminationReasonConfirm = async (terminationReasonID: number) => {
    await handleDeleteAppointment(terminationReasonID)
  }

  const closeModal = () => {
    context.onCloseModal()
    setCurrentAppointment(null)
  }
  const onOpenCalendar = () => {
    setTitle('Datum und Uhrzeit festlegen')
    setDisplayCalendar(true)
  }

  const onCloseCalendar = () => {
    setTitle('Neuer Termin')
    setDisplayCalendar(false)
  }

  /* Convert seconds into hours and minutes */
  const calculateTotalAppointmentLength = useCallback(() => {
    if (selectedTimeslot) {
      // nothing is selected
      const actualOrders = selectedOrderItems.filter((orderItem) => parseInt(orderItem.amount, 10) > 0)

      if (actualOrders.length === 0) {
        setEndTimeLabel('')
        return
      }

      const endTime = new Date(selectedTimeslot)
      selectedOrderItems.forEach((orderItem) => {
        if (parseInt(orderItem.amount, 10) > 0) {
          endTime.setSeconds(endTime.getSeconds() + orderItem.length_in_seconds)
        }
      })

      const calculatedEndTime = new Intl.DateTimeFormat('en', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }).format(endTime)
      setEndTimeLabel(' bis ' + calculatedEndTime)
    }
  }, [selectedOrderItems, selectedTimeslot])

  useEffect(() => calculateTotalAppointmentLength(), [calculateTotalAppointmentLength])
  /* OrderItem method to get Project details */
  const updateOrderItems = (amount: string, projectid: string, project: Project) => {
    const updatedOrderItems: OrderDetailsItem[] = Object.assign([], selectedOrderItems)
    // check if item is already in list
    const itemExists = selectedOrderItems.filter((item) => item.project_id === projectid)

    if (itemExists.length > 0) {
      updatedOrderItems.forEach((item) => {
        if (item.project_id === projectid) {
          item.amount = amount
        }
      })
      setSelectedOrderItems(updatedOrderItems)
      return
    }

    const orderItem: OrderDetailsItem = {
      project_id: projectid,
      amount,
      length_in_seconds: project.booked_time_in_seconds,
    }
    updatedOrderItems.push(orderItem)
    setSelectedOrderItems(updatedOrderItems)
  }

  const onTimeSlotSelected = (date: Date, fromCalendar = true) => {
    const settedQuarter = validQuarters.find(
      (quarter) => moment(date).isSameOrAfter(quarter.from, 'day') && moment(date).isSameOrBefore(quarter.to, 'day'),
    )
    setSelectedQuarter(settedQuarter || null)
    if (fromCalendar) {
      setSelectedTimeSlot(date)
      onCloseCalendar()
    } else {
      setSelectedDaySlotManual(date)
    }
  }

  const getAvailableStatuses = () => {
    const activeStatuses = statuses?.filter((status) => status.is_active) || []
    const oldStatuses = statuses?.filter((status) => !status.is_active) || []
    if (currentlySelectedAppointment) {
      if (moment(currentlySelectedAppointment.date).isSameOrAfter('2021-01-01')) {
        return activeStatuses
      }
      return oldStatuses
    }
    return activeStatuses
  }
  /* OnSelect to get Selected Projects with Amount and Id */
  const getAvailableProjects = (): Project[] => {
    if (currentlySelectedAppointment) {
      return context.projects?.filter((project) => moment(currentlySelectedAppointment.date).isBetween(project.start_date, project.end_date)) || []
    }
    return activeProjects || []
  }

  const [selected, setSelected] = React.useState('slot')

  const handleInputChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setSelected(e.target.value)
  }

  const unavailableTimes: string[] = useMemo(() => {
    const filteredAppointments =
      appointments?.filter((appointment) => moment(appointment.date).isBetween(selectedQuarter?.from, selectedQuarter?.to)) || []
    return filteredAppointments.map((appointment) => appointment.date || '')
  }, [selectedQuarter, appointments])

  const onChangeTimeCheck = useCallback((value: string) => !value.match(/(?!:)\D/g) || value === '', [])

  const timeSchema = useMemo(() => Yup.string().matches(/^(09|1[0-8]):[0-5]\d$/, { excludeEmptyString: true }), [])

  const validateAndSetTime = useCallback(
    (value: string, schema: boolean): string | void => {
      const clearManualTimeslot = () => setSelectedTimeSlotManual(undefined)
      setIsOccupied(false)
      if (!schema) {
        clearManualTimeslot()
        return `Verwenden Sie das Format HH:MM`
      } else if (value) {
        const [hours, minutes] = value.split(':')
        const date = selectedDaySlotManual || new Date()
        date.setHours(parseInt(hours, 10))
        date.setMinutes(parseInt(minutes, 10))
        // check available time with another appointments
        const filteredUnavailable = unavailableTimes.filter((time) => moment(time).isSame(date, 'hour'))
        if (filteredUnavailable.length) {
          const dateMiliSec = Date.parse(date.toISOString())
          if (currentlySelectedAppointment && currentlySelectedAppointment.date) {
            const currentlySelectedAppointmentTimeMiliSec = Date.parse(currentlySelectedAppointment.date)
            if (dateMiliSec === currentlySelectedAppointmentTimeMiliSec) {
              setIsOccupied(false)
              return
            }
            setIsOccupied(true)
            return `Diese Zeit ist bereits belegt`
          } else {
            setIsOccupied(true)
            return `Diese Zeit ist bereits belegt`
          }
        }
        // check available time with offtimes
        if (
          offtimes?.filter((offtime) => {
            if (offtime.whole_day) {
              return moment(offtime.date).isSame(date, 'day')
            }
            return moment(offtime.date).isSame(date, 'hour')
          }).length
        ) {
          clearManualTimeslot()
          return 'Unmöglich zu diesem Zeitpunkt'
        }
        // check the currently time is gone
        if (moment(date).isSameOrBefore(new Date(), 'hour')) {
          clearManualTimeslot()
          return 'Diese Zeit ist bereits gekommen'
        }
        // set value if everything clear
        setSelectedTimeSlotManual(date)
      }
    },
    [offtimes, selectedDaySlotManual, setIsOccupied, currentlySelectedAppointment, unavailableTimes],
  )
  useEffect(() => {
    if (!displayCalendar) {
      setSelectedDaySlotManual(undefined)
    }
  }, [displayCalendar])

  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  return (
    <>
      <Modal
        kind={ModalKind.md}
        title={title}
        onClose={closeModal}
        headerAdditionalButtons={
          <>
            {currentlySelectedAppointment && delete_appointments && (
              <div className="border-r border-solid border-gray-400 pr-4 mr-4">
                <IconButton kind={IconButtonKind.default} icon={<DeleteIcon />} onClick={onAppointmentDeleteBtnClick} />
              </div>
            )}
          </>
        }
        headerBackButton={displayCalendar}
        onBack={onCloseCalendar}
        noPadding={true}
      >
        {displayCalendar ? (
          <div className="w-full">
            <div className="flex flex-wrap w-full sticky top-17 bg-white z-40">
              <div className={`flex flex-col w-full py-3 pb-3 bg-white z-40`}>
                <div className={`flex w-full border-b border-gray-400 justify-between py-8 px-6`}>
                  <div className="flex items-center  w-1/4">
                    <input
                      id="radio-manual"
                      name="manual-time"
                      value="manual"
                      type="radio"
                      checked={selected === 'manual'}
                      onChange={handleInputChange}
                      className="focus:ring-blue-600 h-4 w-4 border-2 text-blue-600 border-blue-600"
                    />
                    <label htmlFor="radio-manual" className="ml-3">
                      <span className="font-body text-base font-medium text-blue-700">Manuelle Angabe:</span>
                    </label>
                  </div>
                  <div className={`flex items-start w-3/4 ${selected === 'manual' ? ' ' : 'opacity-45'}`}>
                    <div className="flex w-1/3 rounded-md justify-items-start items-center mr-8">
                      <span className="block text-sm font-medium tracking-wide uppercase mr-4">Datum:</span>
                      <DayPickerInput
                        formatDate={formattedDateString}
                        placeholder={'DD.MM.YYYY'}
                        dayPickerProps={{
                          onDayClick: (day, { disabled }) => {
                            if (!disabled) {
                              onTimeSlotSelected(day, false)
                            }
                          },
                          disabledDays: [
                            { before: new Date().getHours() > 18 ? tomorrow : new Date() },
                            { daysOfWeek: [0, 6] },
                            { after: endDate || new Date() },
                          ],
                          firstDayOfWeek: 1,
                          months: MONTHS,
                          weekdaysLong: WEEKDAYS_LONG,
                          weekdaysShort: WEEKDAYS_SHORT,
                          locale: 'de',
                        }}
                        inputProps={{
                          readOnly: true,
                          className:
                            'form-input font-body block w-full h-10 bg-gray-100 rounded-md px-4 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5',
                        }}
                      />
                    </div>
                    <div className="flex w-1/3 rounded-md justify-items-start items-center mr-8">
                      <span className="block text-sm font-medium tracking-wide uppercase mr-4">Uhrzeit:</span>
                      <div className="rounded-md -mt-1">
                        <InputWithValidation
                          key={`${selectedDaySlotManual?.toDateString() || ''}`}
                          placeholder="HH:MM"
                          onChangeCheck={onChangeTimeCheck}
                          schema={timeSchema}
                          getErrorOrSetValue={validateAndSetTime}
                          debounceTime={500}
                          disabled={!selectedDaySlotManual}
                        />
                      </div>
                    </div>

                    <div className={`flex w-1/3 justify-end items-center`}>
                      <div className={`flex rounded-md justify-end items-center ${selected === 'manual' ? 'block' : 'hidden'} `}>
                        <Button
                          kind={ButtonKind.primary}
                          disabled={currentlySelectedAppointment ? false : isOccupied}
                          onClick={() => {
                            if (selectedTimeSlotManual) {
                              setSelectedTimeSlot(selectedTimeSlotManual)
                              onCloseCalendar()
                            }
                          }}
                        >
                          Zeit speichern
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`flex w-full items-center py-5 px-8 xl:px-10 ${selected === 'slot' ? 'shadow-scrollarea' : ''}`}>
                  <input
                    id="radio-slot"
                    name="slot-time"
                    value="slot"
                    checked={selected === 'slot'}
                    onChange={handleInputChange}
                    type="radio"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  />
                  <label htmlFor="radio-slot" className="ml-3">
                    <span className="block text-base font-body font-medium text-blue-700">Oder einen Slot wählen:</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap w-full">
              <div className={`flex w-full -mt-2 pb-4 px-4 xl:px-4 ${selected === 'slot' ? '' : 'opacity-45'}`}>
                {validQuarters.map((quarter, index) => (
                  <CrmCalendar
                    key={index}
                    onTimeSlotClick={onTimeSlotSelected}
                    noRouteSet
                    selectedQuater={quarter}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    appointments={appointments}
                    offtimes={offtimes}
                  />
                ))}
              </div>
            </div>
          </div>
        ) : null}
        <Formik
          key="appointment-creation-form"
          initialValues={intialFormValues}
          onSubmit={onFormSubmit}
          validationSchema={AppointmentValidationScheme}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting, submitForm }) => (
            <Form onSubmit={handleSubmit}>
              <div className={`flex flex-wrap w-full px-4 xl:px-6 pt-2 ${displayCalendar ? 'hidden' : ''}`}>
                <div className="flex flex-wrap w-full pb-24">
                  <div className="w-1/2 p-4">
                    <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-6">
                        <InputLabel>{'Standort:'}</InputLabel>
                        <div>
                          <div className="flex flex-wrap relative">
                            <TextInput disabled={true} value={values.name} onChange={() => console.log('Klick')} type="text" />
                            {errors.name && touched.name ? <InputMessage kind={InputMessageKind.error}>{errors.name}</InputMessage> : null}
                          </div>
                        </div>
                      </div>
                      <div className="sm:col-span-6">
                        <InputLabel disabled={true}>{'Adresse:'}</InputLabel>
                        <div className="mt-1 rounded-md">
                          <TextInput
                            value={activePharmacy ? activePharmacy.address.address_name || '' : ''}
                            disabled={true}
                            onChange={() => console.log('Klick')}
                            type="text"
                            placeholder="Straße"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-3 -mt-2">
                        <div className="mt-1 rounded-md">
                          <TextInput
                            value={activePharmacy ? activePharmacy.address.zipcode || '' : ''}
                            disabled={true}
                            onChange={() => console.log('Klick')}
                            type="text"
                            placeholder="PLZ"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3 -mt-2">
                        <div className="mt-1 rounded-md">
                          <TextInput
                            value={activePharmacy ? activePharmacy.address.city || '' : ''}
                            disabled={true}
                            onChange={() => console.log('Klick')}
                            type="text"
                            placeholder="Ort"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-6">
                        <InputLabel disabled={true}>{'Kontakt:'}</InputLabel>
                        <div className="mt-1 rounded-md">
                          <TextInput
                            value={activePharmacy && activePharmacy.contact_person ? activePharmacy.contact_person : ''}
                            disabled={true}
                            onChange={() => console.log('Klick')}
                            type="text"
                            placeholder="Person"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-3 -mt-2">
                        <div className="mt-1 rounded-md">
                          <TextInput
                            value={activePharmacy ? activePharmacy.email || '' : ''}
                            disabled={true}
                            onChange={() => console.log('Klick')}
                            type="text"
                            placeholder="E-Mail"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3 -mt-2">
                        <div className="mt-1 rounded-md">
                          <TextInput
                            value={activePharmacy ? activePharmacy.phone || '' : ''}
                            disabled={true}
                            onChange={() => console.log('Klick')}
                            type="text"
                            placeholder="Telefon"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <InputLabel>{'Anrede:'}</InputLabel>
                        <div className="mt-1 rounded-md">
                          <select
                            value={values.salutation}
                            onChange={(event: React.FormEvent<HTMLSelectElement>) => setFieldValue('salutation', event.currentTarget.value)}
                            className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
                          >
                            <option>{'Frau'}</option>
                            <option>{'Herr'}</option>
                          </select>
                          {errors.salutation && touched.salutation ? (
                            <InputMessage kind={InputMessageKind.error}>{errors.salutation}</InputMessage>
                          ) : null}
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <InputLabel>{'Gesprächsperson:'}</InputLabel>
                        <div className="mt-1 rounded-md">
                          <TextInput
                            value={values.contactPerson}
                            onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('contactPerson', event.currentTarget.value)}
                            type="text"
                            placeholder=""
                          />
                          {errors.contactPerson && touched.contactPerson ? (
                            <InputMessage kind={InputMessageKind.error}>{errors.contactPerson}</InputMessage>
                          ) : null}
                        </div>
                      </div>
                      <div className="sm:col-span-6 flex">
                        <div className="mr-2">
                          <Checkbox
                            name="sendConfirmation"
                            label="E-Mail Benachrichtigung Senden"
                            checked={values.sendConfirmation}
                            onChange={(checked: boolean) => {
                              setFieldValue('sendConfirmation', checked)
                              if (!checked) {
                                setIsCustomEmail(false)
                                setFieldValue('customEmail', '')
                              }
                            }}
                          />
                          {values.sendConfirmation && !isValidEmail && !values.customEmail ? (
                            <InputMessage kind={InputMessageKind.error}>
                              {'Bitte fügen Sie eine E-Mail hinzu, um eine Terminbestätigung zu senden'}
                            </InputMessage>
                          ) : null}
                        </div>
                        {values.sendConfirmation ? (
                          <div>
                            <Checkbox
                              name="customEmail"
                              label="Benutzerdefinierte E-Mail-Adresse hinzufügen"
                              checked={isCustomEmail}
                              onChange={() => {
                                if (isCustomEmail) {
                                  setFieldValue('customEmail', '')
                                }
                                setIsCustomEmail(!isCustomEmail)
                              }}
                            />
                          </div>
                        ) : null}
                      </div>
                      {isCustomEmail && values.sendConfirmation ? (
                        <div className="sm:col-span-6">
                          <InputLabel>{'E-Mail:'}</InputLabel>
                          <div className="mt-1 rounded-md">
                            <TextInput
                              value={values.customEmail || ''}
                              onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('customEmail', event.currentTarget.value)}
                              type="text"
                            />
                            {errors.customEmail && touched.customEmail ? (
                              <InputMessage kind={InputMessageKind.error}>{errors.customEmail}</InputMessage>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="w-1/2 p-4">
                    <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-6">
                        {!selectedTimeslot ? (
                          <React.Fragment>
                            <InputLabel>{'Zeitslot wählen:'}</InputLabel>
                            <div className="mt-1 rounded-md">
                              <Button
                                onClick={onOpenCalendar}
                                disabled={!!currentlySelectedAppointment && moment(new Date()).isSameOrBefore(currentlySelectedAppointment.date)}
                                kind={ButtonKind.primary}
                              >
                                {'Datum und Uhrzeit festlegen'}
                              </Button>
                            </div>
                          </React.Fragment>
                        ) : (
                          <div className="mt-1 rounded-md">
                            <InputLabel>{'Gewählter Zeitslot:'}</InputLabel>
                            <div className="flex mt-1 rounded-md relative">
                              <TextInput
                                onChange={() => console.log('Klick')}
                                type="text"
                                placeholder=""
                                value={`${formattedDateTimeString(selectedTimeslot || new Date())} ${endTimeLabel}`}
                              />
                              <div className="flex absolute right-0 items-center h-10 mt-1 pl-1 pr-1.5 border-l border-gray-400">
                                <IconButton
                                  kind={IconButtonKind.custom}
                                  additionalCss="h-fit items-center px-0.5 py-0.5 rounded-md text-gray-700 hover:text-blue-700 focus:border-gray-300 active:text-blue-800 focus:outline-none transition ease-in-out duration-150"
                                  icon={<EditIcon />}
                                  disabled={!!currentlySelectedAppointment && moment(new Date()).isSameOrAfter(currentlySelectedAppointment.date)}
                                  onClick={onOpenCalendar}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {timeSlotEntryInvalid === true ? (
                          <InputMessage kind={InputMessageKind.error}>{'Bitte wählen Sie einen Zeitslot aus'}</InputMessage>
                        ) : null}
                      </div>
                      <div className="sm:col-span-3">
                        <InputLabel>{'Termintyp:'}</InputLabel>
                        <div className="mt-1 rounded-md">
                          <select
                            defaultValue={values.appointmentTypeId}
                            onChange={(event: React.FormEvent<HTMLSelectElement>) => setFieldValue('appointmentTypeId', event.currentTarget.value)}
                            className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
                          >
                            {context?.appointmentTypes?.map((type) => (
                              <option key={`type-${type.id}`} value={type.id}>
                                {type.label}
                              </option>
                            ))}
                          </select>
                          {errors.appointmentTypeId && touched.appointmentTypeId ? (
                            <InputMessage kind={InputMessageKind.error}>{errors.appointmentTypeId}</InputMessage>
                          ) : null}
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <InputLabel>{'Terminstatus:'}</InputLabel>
                        <div className="mt-1 rounded-md">
                          <select
                            defaultValue={values.statusId}
                            onChange={(event: React.FormEvent<HTMLSelectElement>) => setFieldValue('statusId', event.currentTarget.value)}
                            className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
                          >
                            {getAvailableStatuses().map((status) => (
                              <option key={`status-${status.id}`} value={status.id}>
                                {status.label}
                              </option>
                            ))}
                          </select>
                          {errors.statusId && touched.statusId ? <InputMessage kind={InputMessageKind.error}>{errors.statusId}</InputMessage> : null}
                        </div>
                      </div>
                      <div className="sm:col-span-6">
                        <InputLabel>{'Notiz:'}</InputLabel>
                        <div className="mt-1 rounded-md">
                          <TextAreaInput
                            initialValue={values.note}
                            onChange={(event: React.FormEvent<HTMLTextAreaElement>) => setFieldValue('note', event.currentTarget.value)}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-6">
                        <InputLabel>{'Produkte:'}</InputLabel>
                        {!selectedOrderItems?.length && Number(values.statusId) === 2 && (
                          <InputMessage kind={InputMessageKind.error}>{'Bitte wählen sie ein Produkt aus'}</InputMessage>
                        )}
                      </div>
                      {getAvailableProjects().map((project) => {
                        const setItem = selectedOrderItems.find((item) => `${item.project_id}` === `${project.id}`)
                        return (
                          <div key={`project-${project.id}`} className="sm:col-span-3 flex flex-wrap">
                            <div className="w-2/3 flex items-center">
                              <span className="text-base font-body text-gray-700">{`${project.name} (${project.contact_goal})`}</span>
                            </div>
                            <div className="w-1/3 pl-4">
                              <select
                                value={setItem?.amount}
                                onChange={(event: React.FormEvent<HTMLSelectElement>) => {
                                  updateOrderItems(event.currentTarget.value, event.currentTarget.id, project)
                                }}
                                id={project.id.toString()}
                                className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
                              >
                                {['0', '1', '2', '3', '4', '5', '6'].map((amount) => (
                                  <option key={`project-goal-${project.id}-${amount}`} id={project.id.toString()} value={amount}>
                                    {amount}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-between p-4 pt-8 pb-6 place-items-stretch">
                  <Button kind={ButtonKind.secondary} onClick={context ? context?.onCloseModal : () => console.log('close')}>
                    {'Abbrechen'}
                  </Button>
                  <div className="space-x-4">
                    {currentlySelectedAppointment && delete_appointments && (
                      <Button kind={ButtonKind.outlinedDanger} onClick={onAppointmentDeleteBtnClick}>
                        {'Termin löschen'}
                      </Button>
                    )}
                    <Button
                      kind={ButtonKind.primary}
                      disabled={
                        isSubmitting ||
                        (!isValidEmail && !values.customEmail) ||
                        (!selectedOrderItems?.length && Number(values.statusId) === 2) ||
                        !selectedTimeslot
                      }
                      onClick={submitForm}
                    >
                      {currentlySelectedAppointment ? 'Änderungen speichern' : 'Termin anlegen'}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      <TerminationReasonModal
        isOpen={isTerminationReasonModalOpen}
        onClose={() => setTerminationReasonModalOpen(false)}
        onConfirmReason={handleTerminationReasonConfirm}
      />
    </>
  )
}

export default AppointmentModal
