/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Appointment, formattedDateString, formattedTimeString, Pharmacy, useAbilities, AppointmentStatusType } from '@aposphaere/core-kit'
import { InfoWindow } from '@react-google-maps/api'
import React, { useContext, useMemo, useCallback } from 'react'
import { CrmContext, ICrmContext, ModalKind } from '../../contexts/crmContext'
import QuickActions from './QuickActions'
import { EditIcon, IconButton, IconButtonKind } from '@aposphaere/ui-components'
import { IPointType } from '../PharmacyDataSheetPanel/PharmacyDataSheetTypes'

type InfoWindowContainerProps = {
  pharmacyId: number
  onClose: () => void
  branch: boolean
}

const InfoWindowContainer: React.FunctionComponent<InfoWindowContainerProps> = ({ onClose, branch = false }) => {
  const {
    currentlyActivePharmacy,
    selectPharmacyBranchHook: { pharmacyDetails },
    activeProjects,
    appointments,
    pharmacies,
    selectPharmacyBranchHook: { setSelectedPharmacyBranchId },
    onOpenModal,
    appointmentHook: { setCurrentAppointment },
    selectedQuater,
    onOpenPharmacyDetails,
    currentPointHook: { setCurrentPoint },
    infoRef,
    filialenRef,
    termineRef,
    projekteRef,
    windowRef,
  } = useContext(CrmContext) as ICrmContext
  const abilities = useAbilities()

  const pharmacy = branch ? pharmacyDetails : currentlyActivePharmacy
  const pharmacyProjectsId = pharmacy?.projects.map(({ id }) => id) || []
  const pharmacyActiveProjects = activeProjects?.filter((project) => pharmacyProjectsId.includes(project.id)) || []
  const pharmacyBranchesLength = pharmacy?.branches?.length

  const openRelatedTab = (ref: React.RefObject<HTMLHeadingElement>, point: IPointType) => {
    onOpenPharmacyDetails()
    setCurrentPoint(point)
    setTimeout(() => {
      if (ref && windowRef && ref.current && windowRef.current) {
        windowRef.current.scroll({ behavior: 'smooth', top: ref.current.offsetTop - 20 })
      }
    }, 500)
  }

  const mainBranch = useMemo<Pharmacy | undefined>(() => {
    if (!pharmacies || !pharmacy || !pharmacy.parent?.id) {
      return undefined
    }
    let left = 0
    let right = pharmacies.length - 1
    let mid = Math.floor((left + right) / 2)

    if (`${pharmacies[mid].id}` === `${pharmacy.parent.id}`) {
      return pharmacies[mid]
    }
    while (pharmacies[mid].id !== pharmacy.parent.id && left <= right) {
      if (parseInt(`${pharmacies[mid].id}`) < parseInt(`${pharmacy.parent.id}`)) {
        left = mid + 1
      } else {
        right = mid - 1
      }
      mid = Math.floor((left + right) / 2)
    }
    return pharmacies[mid].id === pharmacy.parent.id ? pharmacies[mid] : undefined
  }, [pharmacy, pharmacies])

  const futureAppointments = useMemo<Appointment[] | undefined>(() => {
    if (pharmacy === undefined) {
      return undefined
    }
    const pharmacyAppointments: Appointment[] = appointments?.filter((appointment) => appointment.pharmacy?.id === pharmacy?.id) || []
    const future = pharmacyAppointments.filter((appointment) => new Date(appointment?.date?.toString().replace(/\s/, 'T') || '') > new Date())
    return future.reverse()
  }, [pharmacy, appointments])

  const pharmacyQuarterVisits = useMemo<Appointment[] | undefined>(() => {
    if (pharmacy === undefined) {
      return undefined
    }
    const currentQuarterAppointments = appointments?.filter(
      (appointment) => appointment.quarter?.id === selectedQuater?.id && appointment.status_id !== AppointmentStatusType.Canceled,
    )
    const currentVisits = currentQuarterAppointments?.filter((appointment) => `${appointment.appointmentType?.id || 0}` === '4') || []
    const pharmacyVisits: Appointment[] = currentVisits?.filter((appointment) => appointment.pharmacy?.id === pharmacy?.id) || []
    return pharmacyVisits
  }, [appointments, pharmacy, selectedQuater])

  const addAndSet = useCallback(
    (visit: Appointment) => {
      setCurrentAppointment(visit)
      onOpenModal(ModalKind.Appointment)
    },
    [setCurrentAppointment, onOpenModal],
  )
  return (
    <React.Fragment>
      {pharmacy ? (
        <InfoWindow
          position={{
            lng: pharmacy?.address?.longitude,
            lat: pharmacy?.address?.latitude,
          }}
          options={{ pixelOffset: { height: -50 } }}
          onCloseClick={onClose}
        >
          <div className="font-body font-normal max-h-96 overflow-y-auto text-base leading-6 p-1 text-blue-700">
            <span className="font-medium text-lg block mb-0.5 pr-4">{pharmacy.name}</span>
            <p>{`(ID: ${pharmacy.id} / ${pharmacy.okid || ''})`}</p>
            <p>{`${pharmacy.address?.address_name || ''}, ${pharmacy.address?.zipcode || ''} ${pharmacy.address.city || ''}`}</p>
            <p className={'mt-2'}>
              <span className="font-medium pr-2">
                <a href={`tel:${pharmacy.phone}`}>{'Tel.:'}</a>
              </span>
              {`${pharmacy.phone || '_'}`}
            </p>
            <p>
              <span className="font-medium pr-2">
                <a href={`mailto:${pharmacy.email}`}>{'E-Mail:'}</a>
              </span>
              {`${pharmacy.email || '_'}`}
            </p>
            <p>
              <span className="font-medium pr-2 cursor-pointer" onClick={() => openRelatedTab(infoRef, 'info')}>
                {'Ansprechpartner:'}
              </span>
              {`${pharmacy.contact_person || '_'}`}
            </p>
            <p>
              <span className="font-medium pr-2 cursor-pointer" onClick={() => openRelatedTab(projekteRef, 'projekte')}>
                {'Projekte:'}
              </span>
              {pharmacyActiveProjects.length
                ? pharmacyActiveProjects.map((el, i) => (
                    <span key={`${i}${el.name}`}>{`${el.name}${i !== pharmacyActiveProjects.length - 1 ? ',' : ''}`}</span>
                  ))
                : '_'}
            </p>
            <p>
              <span className="font-medium pr-2 cursor-pointer" onClick={() => openRelatedTab(termineRef, 'termine')}>
                {'Nächster Termin:'}
              </span>
              {futureAppointments && futureAppointments.length > 0 ? (
                <>
                  <span className="font-normal">
                    {futureAppointments[0].date ? formattedDateString(new Date(futureAppointments[0].date.toString().replace(/\s/, 'T'))) : ''}
                    {' – '}
                    {futureAppointments[0].date ? formattedTimeString(new Date(futureAppointments[0].date.toString().replace(/\s/, 'T'))) : ''}
                    {' Uhr'}
                  </span>
                  <span className="font-medium "></span>
                </>
              ) : (
                '_'
              )}
            </p>
            <p>
              <span className="font-medium pr-2">{'Registrierungscode:'}</span>
              {`${pharmacy.campus_registration_code || '_'}`}
            </p>
            <div>
              <span className="font-medium pr-2 cursor-pointer" onClick={() => openRelatedTab(filialenRef, 'filialen')}>
                {mainBranch ? 'Haupt Fililal:' : 'Filialen:'}
              </span>
              <ul className="divide-y divide-gray-200">
                {pharmacyBranchesLength ? (
                  <>
                    {pharmacy.branches?.map((pharm) => (
                      <li key={pharm.id} className="w-full border-b border-gray-400 last:border-0">
                        <div onClick={() => setSelectedPharmacyBranchId(pharm.id)} className="flex w-full py-1 cursor-pointer">
                          <span>{`${pharm.name}(${pharm.address?.address_name || ''})`}</span>
                        </div>
                      </li>
                    ))}
                  </>
                ) : null}
              </ul>
              <div onClick={() => setSelectedPharmacyBranchId(mainBranch?.id)} className="flex w-full py-1 cursor-pointer">
                {mainBranch ? `${mainBranch.name} (${mainBranch.address.city})` : null}
              </div>
            </div>
            {pharmacyQuarterVisits && pharmacyQuarterVisits.length ? (
              <div className="w-full border-t">
                <h3 className="font-medium pr-2">{'Besuche:'}</h3>

                {pharmacyQuarterVisits.map((item) => {
                  const time = item.date ? formattedTimeString(new Date(item.date.toString().replace(/\s/, 'T'))) : ''
                  const date = item.date ? formattedDateString(new Date(item.date.toString().replace(/\s/, 'T'))) : ''

                  return (
                    <div key={item.id} className="flex hover:bg-gray-50 border-b justify-between text-base leading-6 font-medium">
                      <div className="w-5/6 flex items-center">
                        <span className="font-medium">{`${date} ${time}`}</span>
                      </div>
                      {abilities.edit_appointments && (
                        <div className="flex w-1/6 justify-end items-center">
                          {abilities.edit_appointments && (
                            <IconButton kind={IconButtonKind.default} icon={<EditIcon />} onClick={() => addAndSet(item)} />
                          )}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            ) : null}
            <div className={`mt-6 mb-4 h-1px ${!pharmacyQuarterVisits?.length ? 'border-t border-gray-400 border-solid' : ''}`} />
            <QuickActions pharmacy={pharmacy} branch={branch} />
          </div>
        </InfoWindow>
      ) : (
        <span>
          {'Loading'}
          {console.log('problem')}
        </span>
      )}
    </React.Fragment>
  )
}

export default InfoWindowContainer
