import { formattedDateString, formattedDateTimeString, Reminder, useAbilities } from '@aposphaere/core-kit'
import { DeleteIcon, EditIcon, IconButton, IconButtonKind, TextInput, Button, ButtonKind } from '@aposphaere/ui-components'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import Table from 'rc-table'
import React, { useCallback, useState, useEffect } from 'react'
import Spinner from '../loader.gif'
import moment from 'moment'
import { ExpandedDisplayList, ExpandIcon } from './Expand'
interface IReminderTable {
  editModalOpen: (reminder: Reminder) => void
  deleteModalOpen: (reminder: Reminder) => void
  reminders: Reminder[] | null
  isRemindersLoaded: boolean
}

const MONTHS = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
const WEEKDAYS_LONG = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
const WEEKDAYS_SHORT = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']

const ReminderTable: React.FC<IReminderTable> = ({ reminders, editModalOpen, deleteModalOpen, isRemindersLoaded }) => {
  const [sortedReminders, setSortedReminders] = useState<Reminder[] | null>([])

  const [filterName, setFilterName] = useState<string>('')

  const [filterDate, setFilterDate] = useState<string>('')

  const [filterReason, setFilterReason] = useState<string>('')

  const [filterCity, setFilterCity] = useState<string>('')

  const abilities = useAbilities()
  const titleClassName = 'h-11 pb-2 pr-4 py-3 text-gray-600 text-left text-xs font-medium capitalize font-body'
  const CETTime = useCallback((date: string) => {
    const [dateString, timeString] = date.split(' ')
    const dateStringFormat = dateString.replace(/-/g, '/')
    const toDate = new Date(`${dateStringFormat} ${timeString}`)
    // hack to get the correct current local miliseconds
    const localTime = moment(toDate).valueOf() - 360000
    const localOffSet = moment(toDate).utcOffset() * 6000
    const utc = localTime + localOffSet
    // 10000 miliseconds * 60 * 60
    const result = utc + 3600000
    const time = new Date(result)

    return formattedDateTimeString(time)
  }, [])

  const applyFilters = () => {
    if (reminders) {
      const filteredReminders = reminders?.filter((reminder) => {
        if (
          (filterName.length ? reminder?.pharmacy.name?.includes(filterName) : true) &&
          (filterCity.length ? reminder?.pharmacy.address?.city?.includes(filterCity) : true) &&
          (filterReason.length ? reminder?.note?.includes(filterReason) : true) &&
          (filterDate.length ? reminder?.until && formattedDateString(new Date(reminder?.until)).includes(filterDate) : true)
        ) {
          return reminder
        } else {
          return null
        }
      })
      const sortedItems = sortReminders(filteredReminders)
      sortedItems && setSortedReminders(sortedItems)
    }
  }

  const clearFilters = () => {
    setFilterName('')
    setFilterCity('')
    setFilterDate('')
    setFilterReason('')
    const sortedItems = sortReminders(reminders)
    sortedItems && setSortedReminders(sortedItems)
  }

  const columns = [
    {
      title: <div className={`${titleClassName} pl-2`}>{'Datum'}</div>,
      width: '10%',
      dataIndex: 'until',
      key: 'date',
      render: (value: string) => <div className={'-pl-4 xl:pr-6 py-4 whitespace-nowrap w-16'}>{formattedDateString(new Date(value))}</div>,
    },
    {
      title: <div className={titleClassName}>{'Apotheke'}</div>,
      width: '20%',
      dataIndex: 'pharmacy',
      key: 'pharmacyName',
      render: (pharmacy: { id: string; name: string }) => <div className={'pr-4 py-4 whitespace-nowrap'}>{pharmacy ? pharmacy.name : 'Not set'}</div>,
    },
    {
      title: <div className={titleClassName}>{'Ort'}</div>,
      width: '10%',
      dataIndex: 'pharmacy',
      key: 'pharmacyCity',
      render: (pharmacy: { address: { city: string } }) => (
        <div className={'pr-4 py-4 whitespace-nowrap'}>{pharmacy ? pharmacy.address.city : ' unknown'}</div>
      ),
    },
    {
      title: <div className={titleClassName}>{'Notiz'}</div>,
      width: '35%',
      dataIndex: 'note',
      key: 'noteContent',
      render: (value: string) => <div className={'pr-4 py-4 whitespace-nowrap'}>{value}</div>,
    },
    {
      title: <div className={titleClassName}>{'Erfasser'}</div>,
      width: '10%',
      dataIndex: 'user',
      key: 'userName',
      render: (user: { name: string }) => <div className={'pr-6 py-4 whitespace-nowrap'}>{user ? user.name : ' unknown'}</div>,
    },
    {
      title: <div className={titleClassName}>{'Erstellt am'}</div>,
      width: '20%',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value: string) => <div className={'pr-4 py-4 whitespace-nowrap'}>{CETTime(value)} Uhr</div>,
    },
    {
      title: <div className={`${titleClassName} pl-4`}>{'Quickactions'}</div>,
      key: 'quickactions',
      render: (record: Reminder) => (
        <div className="flex justify-center border-l border-solid border-gray-400 pl-4">
          {abilities.delete_tasks && <IconButton icon={<DeleteIcon />} onClick={() => deleteModalOpen(record)} kind={IconButtonKind.default} />}
          {abilities.edit_tasks && <IconButton icon={<EditIcon />} onClick={() => editModalOpen(record)} kind={IconButtonKind.default} />}
        </div>
      ),
    },
  ]

  const sortReminders = (items: Reminder[] | null) => {
    if (items) {
      const newSortedArray = [...items]
      newSortedArray.sort((remA: Reminder, remB: Reminder) => {
        const dateA = new Date(remA.until || '')
        const dateB = new Date(remB.until || '')
        if (dateA > dateB) {
          return 1
        }
        return -1
      })
      return newSortedArray
    }
  }

  useEffect(() => {
    if (reminders) {
      const sortedItems = sortReminders(reminders)
      sortedItems && setSortedReminders(sortedItems)
    }
  }, [reminders])

  return isRemindersLoaded ? (
    <div>
      <div className="flex flex-col">
        <div className="flex flex-row gap-2">
          <TextInput
            value={filterName}
            disabled={false}
            onChange={(e) => setFilterName(e.currentTarget.value)}
            type="text"
            placeholder="Apotheke"
          />
          <DayPickerInput
            formatDate={formattedDateString}
            placeholder={'Datum'}
            value={filterDate}
            dayPickerProps={{
              onDayClick: (day) => {
                setFilterDate(formattedDateString(new Date(day)))
              },
              firstDayOfWeek: 1,
              months: MONTHS,
              weekdaysLong: WEEKDAYS_LONG,
              weekdaysShort: WEEKDAYS_SHORT,
              locale: 'de',
            }}
            inputProps={{
              readOnly: true,
              className:
                'mt-1 relative rounded-md form-input font-body block w-max h-10 bg-gray-100 rounded-md px-4 py-2 text-base min-w-200px leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5',
            }}
          />
          <TextInput value={filterCity} disabled={false} onChange={(e) => setFilterCity(e.currentTarget.value)} type="text" placeholder="Stadt" />
          <TextInput
            value={filterReason}
            disabled={false}
            onChange={(e) => setFilterReason(e.currentTarget.value)}
            type="text"
            placeholder="Grund der Wiedervorlage"
          />
        </div>
        <div className="flex flex-row-reverse my-3 gap-2">
          <Button kind={ButtonKind.secondary} onClick={() => clearFilters()}>
            Filter löschen
          </Button>
          <Button kind={ButtonKind.primary} onClick={() => applyFilters()}>
            Filter
          </Button>
        </div>
      </div>
      <div className="w-full border border-gray-400 sm:rounded-lg relative">
        <div className="absolute h-11 top-0 border-b border-gray-400 w-full" />
        {sortedReminders && (
          <Table
            className="w-full px-4"
            columns={columns}
            data={sortedReminders}
            rowClassName={(record, index: number) =>
              `${
                index !== 0 ? 'border-t border-gray-400' : ''
              } text-base font-body hover:text-blue-700 hover:border-white hover:border-b hover:shadow-insettable hover:bg-blue-50`
            }
            tableLayout={'auto'}
            rowKey={(record: Reminder) => `${record.note || ''}${record.id}`}
            expandable={{
              expandIcon: ExpandIcon,
              expandRowByClick: true,
              expandedRowRender: (record, index, indent, expanded) =>
                expanded ? <ExpandedDisplayList {...{ record, expanded, canCreateAppointment: abilities.create_appointments }} /> : null,
            }}
          />
        )}
      </div>
    </div>
  ) : (
    <div className="h-screen -ml-6 -mt-6 w-screen bg-white z-9999 fixed flex items-center justify-center">
      <div className="flex items-center justify-center flex-wrap max-w-48">
        <img className="flex w-24 -mt-16" src={Spinner} alt="" />
        <br />
        <div className="w-full text-center mx-auto flex justify-center font-body mt-4 text-base text-blue-400 leading-5">
          {'Daten werden geladen.'}
        </div>
      </div>
    </div>
  )
}

export default React.memo(ReminderTable)
