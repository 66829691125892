/**
 * Get Number of weeks between two dates
 * @param startDate The start date
 * @param endDate The end date
 */
export const weeksBetween = (startDate: Date, endDate: Date) =>
  Math.round((new Date(endDate).valueOf() - new Date(startDate).valueOf()) / (7 * 24 * 60 * 60 * 1000))

/**
 * Get The number of a week from year
 * @param date
 * @param weekDay day in a week e.g monday or tuesday represented with a number 1 - 7
 */
export const getWeekNumber = (date: Date, weekDay: number) => {
  const millisecondsPerDay = 86400000
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1)
  const pastDaysOfYear = (date.valueOf() - firstDayOfYear.valueOf()) / millisecondsPerDay
  return weekDay + Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
}

/**
 * Get week start date
 * @param weekNumber
 * @param year
 */
export const getDateOfISOWeek = (weekNumber: number, year: number) => {
  const simple = new Date(year.valueOf(), 0, 1 + (weekNumber - 1) * 7)
  const dow = simple.getDay()
  const ISOweekStart = simple
  if (dow <= 4) {
    ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1)
  } else {
    ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay())
  }
  return ISOweekStart
}

/**
 * Get list of days in a given week
 * @param weekStartDate
 * @param weekends
 */
export const daysOfTheWeek = (weekStartDate: Date, weekends: boolean): Date[] => {
  const week: Date[] = []
  // Starting Monday not Sunday
  weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay() + 1)
  const daysPerWeek = weekends ? 7 : 5
  for (let i = 0; i < daysPerWeek; i++) {
    week.push(new Date(weekStartDate))
    weekStartDate.setDate(weekStartDate.getDate() + 1)
  }
  return week
}

/**
 * Formats the given date to a readable string hh:mm
 * @param date
 */
export const formattedTimeString = (date: Date) => {
  const t = date
  const hh = new Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false }).format(t)
  const mm = `0${date.getMinutes()}`.slice(-2)
  return `${hh}:${mm}`
}

/**
 * Formats the given date to a readable string
 * @param date
 */
export const formattedDateTimeString = (date: Date) => {
  const t = date
  const hh = new Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false }).format(t)
  const mm = `0${date.getMinutes()}`.slice(-2)
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} – ${hh}:${mm}`
}

export const formattedDateString = (date: Date) => `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
