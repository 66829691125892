import { Reminder } from '@aposphaere/core-kit'
import React, { useCallback, useContext } from 'react'
import ReminderTable from '../components/RemindersTable'
import { CrmContext, ICrmContext, ModalKind } from '../contexts/crmContext'

const ReminderItemsPage: React.FC = () => {
  const {
    selectReminderHook: { setCurrentlySelectedReminder },
    onOpenModal,
    setActivePharmacy,
    remindersHook: { reminders },
    isRemindersLoaded,
  } = useContext(CrmContext) as ICrmContext

  const deleteModalOpen = useCallback(
    (reminder: Reminder) => {
      setActivePharmacy(reminder.pharmacy)
      setCurrentlySelectedReminder(reminder)
      onOpenModal(ModalKind.ReminderDelete)
    },
    [setActivePharmacy, setCurrentlySelectedReminder, onOpenModal],
  )

  const editModalOpen = useCallback(
    (reminder: Reminder) => {
      setActivePharmacy(reminder.pharmacy)
      setCurrentlySelectedReminder(reminder)
      onOpenModal(ModalKind.ReminderEdit)
    },
    [setActivePharmacy, setCurrentlySelectedReminder, onOpenModal],
  )

  return (
    <div className="flex z-40 w-full h-auto bg-white px-2 xl:px-4 py-4">
      <ReminderTable deleteModalOpen={deleteModalOpen} editModalOpen={editModalOpen} reminders={reminders} isRemindersLoaded={isRemindersLoaded} />
    </div>
  )
}

export default ReminderItemsPage
