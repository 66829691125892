import {
  Button,
  ButtonKind,
  IconButton,
  IconButtonKind,
  LeftSidebar,
  MaximizeIcon,
  PlusSignIcon,
  TabBar,
  TabBarItem,
  TabBarItemKind,
  OfftimeLabel,
  NotificationBannerKind,
  OffTimeMutationVariables,
  TagToggleButton,
} from '@aposphaere/ui-components'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { CrmContext, ICrmContext } from '../../contexts/crmContext'
import CrmCalendar from '../CrmCalendar'
import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'
import { createOfftime } from '@aposphaere/core-kit'

const offtimeLabels: { value: string; name: string }[] = [
  { value: 'Home Office (blau)', name: 'Home Office' },
  { value: 'Urlaub (grün)', name: 'Urlaub' },
  { value: 'Privat (Rosa)', name: 'Privat' },
  { value: 'Tagung (gelb)', name: 'Tagung' },
  { value: 'Krank (rot)', name: 'Krank' },
]
const CalenderPanel: React.FC = () => {
  const {
    currentlyActivePharmacy,
    setLeftSideBoardStatus,
    selectedQuater,
    selectedDayHook: { selectedDay, setSelectedDay },
    appointments,
    offtimes,
    remindersHook: { reminders },
    offtimeType,
    presentNotification,
    setOfftimeType,
    refreshOfftimes,
    mapInstance,
    zoomToLocation,
    minimizeLeftPaneHook: { showLeftPane, setShowLeftPane },
  } = useContext(CrmContext) as ICrmContext

  const mutationCreateOfftime = useAuthenticatedMutation(createOfftime)

  const [disabledAppointmentCreationButton, setEnableAppointmentCreationButton] = useState<boolean>(true)
  const [showBlockers, setShowBlockers] = useState<boolean>(false)

  useEffect(() => {
    if (currentlyActivePharmacy !== undefined) {
      setEnableAppointmentCreationButton(false)
    } else {
      setEnableAppointmentCreationButton(true)
    }
  }, [currentlyActivePharmacy, setEnableAppointmentCreationButton])

  const onNewAppointmentClick = () => {
    setLeftSideBoardStatus('appointmentCreation')
    if (mapInstance) {
      if (currentlyActivePharmacy?.address?.longitude && currentlyActivePharmacy?.address?.latitude) {
        const locationObject = {
          longitude: currentlyActivePharmacy.address.longitude || 0,
          latitude: currentlyActivePharmacy.address.latitude || 0,
        }
        zoomToLocation(locationObject, mapInstance)
      }
    }
  }
  const createUserTimeOff = useCallback(
    async (variables: OffTimeMutationVariables) => {
      const response = await mutationCreateOfftime(variables)
      if (response.errors !== undefined) {
        alert(response.errors)
        return
      }
      refreshOfftimes()
      presentNotification({
        title: 'Auszeit wurde erfolgreich erstellt',
        content: '',
        kind: NotificationBannerKind.success,
      })
    },
    [mutationCreateOfftime, refreshOfftimes, presentNotification],
  )
  return (
    <LeftSidebar
      headerContent={
        showLeftPane ? (
          <div className="w-full px-1">
            <div className="flex border-b border-solid border-gray-400 justify-between py-4 w-full items-center">
              <div className="hidden xl:flex">
                <TabBar>
                  <TabBarItem kind={TabBarItemKind.secondary} selected={true}>
                    {'Kalender'}
                  </TabBarItem>
                </TabBar>
              </div>
              <TagToggleButton customCSS="my-1 mx-1 text-sm xl:text-base" selected={showBlockers} onClick={() => setShowBlockers((prev) => !prev)}>
                {showBlockers ? 'Auszeiten verbergen' : 'Auszeiten anlegen'}
              </TagToggleButton>
              <div className="flex text-gray-700 items-center">
                <Button
                  disabled={disabledAppointmentCreationButton}
                  kind={ButtonKind.outlinedPrimary}
                  icon={<PlusSignIcon />}
                  onClick={onNewAppointmentClick}
                >
                  {'Termin buchen'}
                </Button>
              </div>
              <div className="inline-flex border-l border-gray-400 border-solid h-10 ml-1 mr-1" />
              <div className="flex justify-end">
                <IconButton kind={IconButtonKind.default} icon={<MaximizeIcon />} onClick={() => setShowLeftPane(!showLeftPane)} />
              </div>
            </div>
            {showBlockers && (
              <div className="flex py-4 w-full flex-wrap justify-center items-center">
                {offtimeLabels.map(({ value, name }) => (
                  <OfftimeLabel offtimeType={offtimeType} onClick={setOfftimeType} key={name} kind={value} labelInfo={name} />
                ))}
              </div>
            )}
          </div>
        ) : null
      }
      showMax={showLeftPane}
    >
      {showLeftPane ? (
        <div className="relative">
          <CrmCalendar
            onTimeSlotClick={() => console.log('click')}
            selectedQuater={selectedQuater}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            appointments={appointments}
            offtimes={offtimes}
            reminders={reminders}
            onCreateOfftime={createUserTimeOff}
            isOverView={true}
          />
        </div>
      ) : null}
    </LeftSidebar>
  )
}
export default CalenderPanel
