import { createAppointment, Project, useAuth, formattedDateString, Quarter, formattedTimeString, Appointment } from '@aposphaere/core-kit'
import {
  Button,
  ButtonKind,
  CloseIcon,
  IconButton,
  IconButtonKind,
  InputLabel,
  InputMessage,
  InputMessageKind,
  LeftSidebar,
  NotificationBannerKind,
  TextAreaInput,
  TextInput,
  Checkbox,
} from '@aposphaere/ui-components'
import { Formik, Form } from 'formik'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { CrmContext, ICrmContext } from '../../contexts/crmContext'
import CrmCalendar from '../CrmCalendar'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import InputWithValidation from '../TexInputHOC/TexInputWithValidation'
import moment from 'moment'

import * as Yup from 'yup'

import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'

type AppointmentFormValues = {
  pharmacyId?: number
  name: string
  contactPerson: string
  appointmentTypeId: string
  statusId: string
  note: string
  sendConfirmation: boolean
  customEmail?: string
}

type OrderDetailsItem = {
  project_id: string
  amount: string
  length_in_seconds: number
}

const CalenderPanelAppointment: React.FC = () => {
  const context = useContext(CrmContext) as ICrmContext
  const {
    appointmentHook: { currentlySelectedAppointment },
    projects,
    activeProjects,
    statuses,
    appointmentTypes,
    currentlyActivePharmacy,
    selectPharmacyBranchHook: { pharmacyBranchActive, setPharmacyBranchActive, pharmacyDetails },
    quarters,
    selectedQuater,
    selectedDayHook: { selectedDay, setSelectedDay },
    appointments,
    offtimes,
    updatePharmacies,
    appointmentCreationHook: { setTemporaryAppointment },
    setSelectedPharmacyId,
    selectedPharmacyId,
    getFreshPharmacyDetails,
    setLeftSideBoardStatus,
    nextPossibleDate,
  } = context
  const auth = useAuth()

  const mutationCreate = useAuthenticatedMutation(createAppointment)

  const activePharmacy = pharmacyBranchActive ? pharmacyDetails : currentlyActivePharmacy
  const [selectedTimeslot, setSelectedTimeSlot] = useState<Date | undefined>(undefined)
  const [selectedOrderItems, setSelectedOrderItems] = useState<OrderDetailsItem[]>([])
  const [selectedQuarter, setSelectedQuarter] = useState<Quarter | null>(null)
  const [isOccupied, setIsOccupied] = useState<boolean>(false)
  const [isCustomEmail, setIsCustomEmail] = useState<boolean>(false)

  const validQuarters = useMemo<Quarter[]>(() => {
    if (quarters) {
      return quarters.filter((quarter) => new Date() < new Date(quarter.to?.toString().replace(/\s/, 'T') || ''))
    }
    return []
  }, [quarters])

  const isValidEmail = useMemo(() => {
    const emailPattern = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,4}$/
    return emailPattern.test(activePharmacy?.email || '')
  }, [activePharmacy])

  const endDate = useMemo(
    () => new Date(Math.max(...validQuarters.map((quarter) => new Date(quarter.to?.toString().replace(/\s/, 'T') || '').getTime()))),
    [validQuarters],
  )
  useEffect(() => {
    if (currentlySelectedAppointment && projects) {
      setSelectedTimeSlot(new Date(currentlySelectedAppointment.date?.toString().replace(/\s/, 'T') || ''))
      const orderItems: OrderDetailsItem[] = currentlySelectedAppointment.order_items.map((item) => ({
        amount: `${item.amount}`,
        project_id: `${item.project_id}`,
        length_in_seconds: projects?.find((project) => `${project.id}` === `${item.project_id}`)?.booked_time_in_seconds || 1800,
      }))
      setSelectedOrderItems(orderItems)
    } else {
      setSelectedTimeSlot(undefined)
    }
  }, [currentlySelectedAppointment, projects])

  const intialFormValues: AppointmentFormValues = {
    pharmacyId: activePharmacy ? activePharmacy.id : undefined,
    name: activePharmacy ? activePharmacy.name || '' : '',
    contactPerson: '',
    appointmentTypeId: currentlySelectedAppointment
      ? `${currentlySelectedAppointment?.appointmentType?.id || 0}`
      : appointmentTypes
      ? appointmentTypes[0].id.toString()
      : '',
    statusId: currentlySelectedAppointment ? `${currentlySelectedAppointment?.status?.id || 0}` : statuses ? statuses[0].id.toString() : '',
    note: currentlySelectedAppointment?.note || '',
    sendConfirmation: activePharmacy?.email ? true : false,
  }

  const AppointmentValidationScheme = Yup.object().shape({
    name: Yup.string().required('Pflichtfeld'),
    contactPerson: Yup.string(),
    appointmentTypeId: Yup.string().required('Bitte auswählen'),
    statusId: Yup.string().required('Bitte auswählen'),
    customEmail: isCustomEmail ? Yup.string().email().required('Pflichtfeld') : Yup.string(),
  })

  const onClose = () => {
    setSelectedPharmacyId(currentlyActivePharmacy?.id)
    setLeftSideBoardStatus('calendar')
  }

  const onFormSubmit = async (values: AppointmentFormValues) => {
    // clear UTC times for us =)
    selectedTimeslot && selectedTimeslot.setUTCHours(selectedTimeslot?.getHours(), selectedTimeslot?.getMinutes(), 0, 0)
    const appointmentVariables = {
      pharmacy_id: values.pharmacyId,
      quarter_id: selectedQuarter?.id || currentlySelectedAppointment?.quarter?.id,
      status_id: values.statusId,
      appointment_type_id: values.appointmentTypeId,
      creator_id: auth.user?.id,
      date: selectedTimeslot?.toISOString(),
      note: values.note === '' ? '– empty – ' : values.note,
      send_confirmation: values.sendConfirmation,
      order_items: selectedOrderItems.map((item) => ({
        project_id: item.project_id,
        amount: item.amount,
      })),
      id: currentlySelectedAppointment?.id,
      confirmation_email_address: isCustomEmail ? values.customEmail : '',
    }
    try {
      await mutationCreate(appointmentVariables)
      const updatedPharmacy = await getFreshPharmacyDetails(values.pharmacyId || 0, pharmacyBranchActive)
      updatePharmacies(updatedPharmacy)
      if (pharmacyBranchActive) {
        setPharmacyBranchActive(false)
      }
      onClose()

      context.refreshAppointments()
      context.presentNotification({
        title: currentlySelectedAppointment ? 'Termin erfolgreich editiert' : 'Termin erfolgreich angelegt',
        content: '',
        kind: NotificationBannerKind.success,
      })
    } catch (error) {
      setActiveDetails(!isActiveDetails)
      context.presentNotification({
        title: 'Zu dieser Zeit ist kein Termin möglich.',
        content: '',
        kind: NotificationBannerKind.danger,
      })
    }
  }
  /* OrderItem method to get Project details */
  const updateOrderItems = (amount: string, projectid: string, project: Project) => {
    const updatedOrderItems: OrderDetailsItem[] = Object.assign([], selectedOrderItems)
    // check if item is already in list
    const itemExists = selectedOrderItems.filter((item) => item.project_id === projectid)

    if (itemExists.length > 0) {
      updatedOrderItems.forEach((item, index, object) => {
        if (item.project_id === projectid) {
          Number(amount) === 0 ? object.splice(index, 1) : (item.amount = amount)
        }
      })
      setSelectedOrderItems(updatedOrderItems)
      return
    }

    const orderItem: OrderDetailsItem = {
      project_id: projectid,
      amount,
      length_in_seconds: project.booked_time_in_seconds,
    }
    updatedOrderItems.push(orderItem)
    setSelectedOrderItems(updatedOrderItems)
  }
  const timeSchema = useMemo(
    () =>
      Yup.string().matches(/^(09|1[0-8]):[0-5]\d$/, {
        excludeEmptyString: true,
      }),
    [],
  )

  const unavailableTimes: string[] = useMemo(() => {
    const filteredAppointments =
      appointments?.filter((appointment) => moment(appointment.date).isBetween(selectedQuarter?.from, selectedQuarter?.to)) || []
    return filteredAppointments.map((appointment) => appointment.date || '')
  }, [selectedQuarter, appointments])

  const validateAndSetTime = useCallback(
    (value: string, schema: boolean, checkCurrent?: boolean): string | void => {
      setIsOccupied(false)
      if (!schema) {
        return `Verwenden Sie das Format HH:MM`
      } else if (value) {
        const date = new Date(selectedTimeslot || new Date())
        if (!checkCurrent) {
          const [hours, minutes] = value.split(':')
          date.setHours(parseInt(hours, 10))
          date.setMinutes(parseInt(minutes, 10))
        }
        // check available time with another appointments
        if (unavailableTimes.filter((time) => moment(time).isSame(date, 'hour')).length) {
          setIsOccupied(true)
          return `Diese Zeit ist bereits belegt`
        }
        // check available time with offtimes
        if (
          offtimes?.filter((offtime) => {
            if (offtime.whole_day) {
              return moment(offtime.date).isSame(date, 'day')
            }
            return moment(offtime.date).isSame(date, 'hour')
          }).length
        ) {
          return 'Unmöglich zu diesem Zeitpunkt'
        }
        // check the currently time is gone
        if (moment(date).isSameOrBefore(new Date(), 'hour')) {
          return 'Diese Zeit ist bereits gekommen'
        }
        // set value if everything clear
        if (!checkCurrent) {
          // Necessary evil in the condition of setting the initial value of the HOK input
          if (!moment(date).isSame(selectedTimeslot, 'minutes')) {
            setSelectedTimeSlot(date)
          }
        }
        return undefined
      }
    },
    [offtimes, selectedTimeslot, setIsOccupied, unavailableTimes],
  )

  const onTimeSlotSelected = useCallback(
    (date: Date, fromCalendar = true) => {
      const settedQuarter = validQuarters.find(
        (quarter) => moment(date).isSameOrAfter(quarter.from, 'day') && moment(date).isSameOrBefore(quarter.to, 'day'),
      )
      setSelectedQuarter(settedQuarter || null)
      if (!fromCalendar) {
        date.setHours(selectedTimeslot?.getHours() || 12)
        date.setMinutes(selectedTimeslot?.getMinutes() || 0)
      }
      const time = formattedTimeString(date)
      validateAndSetTime(time, timeSchema.isValidSync(time))
      setSelectedTimeSlot(date)
    },
    [selectedTimeslot, timeSchema, validQuarters, validateAndSetTime],
  )

  const getAvailableStatuses = () => {
    const activeStatuses = statuses?.filter((status) => status.is_active) || []
    const oldStatuses = statuses?.filter((status) => !status.is_active) || []
    if (currentlySelectedAppointment) {
      if (moment(currentlySelectedAppointment.date).isSameOrAfter('2021-01-01')) {
        return activeStatuses
      }
      return oldStatuses
    }
    return activeStatuses
  }
  /* OnSelect to get Selected Projects with Amount and Id */
  const getAvailableProjects = (): Project[] => {
    if (currentlySelectedAppointment) {
      return context.projects?.filter((project) => moment(currentlySelectedAppointment.date).isBetween(project.start_date, project.end_date)) || []
    }
    return activeProjects || []
  }
  /* German localisation  */
  const MONTHS = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
  const WEEKDAYS_LONG = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
  const WEEKDAYS_SHORT = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']

  const [selected, setSelected] = React.useState('slot')

  const handleInputChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setSelected(e.target.value)
  }

  const onChangeTimeCheck = useCallback((value: string) => !value.match(/(?!:)\D/g) || value === '', [])

  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)

  const [isActiveDetails, setActiveDetails] = useState(true)

  const showDetailLayer = () => {
    setActiveDetails(!isActiveDetails)
  }

  const createTemporaryAppointment = useCallback((): Appointment => {
    const temporaryAppointment: Partial<Appointment> = {
      pharmacy: currentlyActivePharmacy,
      id: Math.PI,
      quarter: selectedQuarter || undefined,
      date: selectedTimeslot?.toISOString(),
      order_items: [],
      note: 'test_appointments',
    }
    return temporaryAppointment as Appointment
  }, [currentlyActivePharmacy, selectedQuarter, selectedTimeslot])

  useEffect(() => {
    if (selectedTimeslot) {
      setTemporaryAppointment(createTemporaryAppointment())
      setSelectedDay(selectedTimeslot) // render route
      setSelectedPharmacyId(undefined) // hide info window
    }
  }, [selectedTimeslot, setSelectedDay, setSelectedPharmacyId, createTemporaryAppointment, setTemporaryAppointment])

  useEffect(() => () => setTemporaryAppointment(null), [setTemporaryAppointment])

  useEffect(() => {
    if (!selectedDay && currentlyActivePharmacy && !selectedPharmacyId) {
      setTemporaryAppointment(null)
      setSelectedPharmacyId(currentlyActivePharmacy.id) // show info window
    }
  }, [setTemporaryAppointment, currentlyActivePharmacy, selectedDay, selectedPharmacyId, setSelectedPharmacyId])

  const nextPossibleDateMessage = nextPossibleDate ? `– nächst möglicher Termin ${nextPossibleDate}` : ``
  return (
    <LeftSidebar
      headerContent={
        <div className="flex w-full flex-wrap font-body bg-white">
          <div className="flex w-full flex-wrap bg-green-50 border-b border-green-300">
            <div className="flex w-full h-16 2xl:h-20 items-center pl-4">
              <div className="flex w-10/12 pl-2">
                <h1 className={`text-base 2xl:text-lg text-green-600 leading-6 font-medium`}>
                  <span>{`Neuer Termin:`}</span>
                  <br />
                  {currentlyActivePharmacy?.name && (
                    <span>
                      {currentlyActivePharmacy?.name || ''} {nextPossibleDateMessage}
                    </span>
                  )}
                </h1>
              </div>
              <div className="flex w-2/12 justify-end pr-2 text-blue-800 items-center">
                <IconButton kind={IconButtonKind.default} icon={<CloseIcon />} onClick={onClose} />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full sticky top-17 bg-white z-40">
            <div className={`flex w-full pl-2 py-1 border-b border-gray-400 bg-white z-40`}>
              <div className={`flex w-full gap-1 flex-wrap justify-between`}>
                <div className="flex w-full items-center">
                  <input
                    id="radio-manual"
                    name="manual-time"
                    value="manual"
                    type="radio"
                    checked={selected === 'manual'}
                    onChange={handleInputChange}
                    className="focus:ring-blue-600 h-4 w-4  my-1 border-2 text-blue-600 border-blue-600"
                  />
                  <label htmlFor="radio-manual" className="ml-1">
                    <span className="font-body text-sm font-medium text-blue-700">{`Manuelle Angabe:`}</span>
                  </label>
                </div>
                <div className={`flex w-full items-start ${selected === 'manual' ? ' ' : 'opacity-45'}`}>
                  <div className="flex flex-col ml-1 xl:ml-0 rounded-md justify-items-start mr-2">
                    <span className="block text-sm font-medium tracking-wide capitalize mt-3">{`Datum:`}</span>
                    <DayPickerInput
                      formatDate={formattedDateString}
                      placeholder={'dd.mm.yyyy'}
                      dayPickerProps={{
                        onDayClick: (day, { disabled }) => {
                          if (!disabled) {
                            onTimeSlotSelected(day, false)
                          }
                        },
                        disabledDays: [
                          {
                            before: new Date().getHours() > 18 ? tomorrow : new Date(),
                          },
                          { daysOfWeek: [0, 6] },
                          { after: endDate || new Date() },
                        ],
                        firstDayOfWeek: 1,
                        months: MONTHS,
                        weekdaysLong: WEEKDAYS_LONG,
                        weekdaysShort: WEEKDAYS_SHORT,
                        locale: 'de',
                      }}
                      inputProps={{
                        readOnly: true,
                        className:
                          'form-input font-body block w-full h-10 bg-gray-100 rounded-md px-4 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5',
                      }}
                      value={selectedTimeslot}
                    />
                  </div>
                  <div className="flex flex-col mt-3 rounded-md justify-items-start mr-2">
                    <span className="block text-sm font-medium tracking-wide capitalize">{`Uhrzeit:`}</span>
                    <div className="rounded-md">
                      <InputWithValidation
                        key={`${selectedTimeslot?.toDateString() || ''}`}
                        placeholder="hh:mm"
                        onChangeCheck={onChangeTimeCheck}
                        schema={timeSchema}
                        getErrorOrSetValue={validateAndSetTime}
                        debounceTime={500}
                        disabled={!selectedTimeslot}
                        initialValue={selectedTimeslot ? formattedTimeString(selectedTimeslot) : undefined}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`flex w-full items-center py-3 pl-2 ${selected === 'slot' ? 'shadow-scrollarea' : ''}`}>
              <input
                id="radio-slot"
                name="slot-time"
                value="slot"
                checked={selected === 'slot'}
                onChange={handleInputChange}
                type="radio"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
              <label htmlFor="radio-slot" className="ml-3">
                <span className="block text-base font-body font-medium text-blue-700">{`Oder einen Slot wählen:`}</span>
              </label>
            </div>
          </div>
        </div>
      }
      showMax
    >
      <Formik key="appointment-creation-form" initialValues={intialFormValues} onSubmit={onFormSubmit} validationSchema={AppointmentValidationScheme}>
        {({ values, errors, touched, submitForm, handleSubmit, setFieldValue, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <div className={`flex w-full -mt-2 ${selected === 'slot' ? '' : 'opacity-45'}`}>
              <CrmCalendar
                onTimeSlotClick={onTimeSlotSelected}
                noRouteSet
                selectedQuater={selectedQuater}
                selectedTimeslot={selectedTimeslot}
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                appointments={appointments}
                offtimes={offtimes}
              />
            </div>
            <div
              className={`flex flex-wrap sticky bg-white self-end bottom-0 w-full max-h-55vh place-items-stretch shadow-top overflow-scroll ${
                isActiveDetails && selectedTimeslot && !isOccupied ? 'flex' : 'hidden'
              }`}
            >
              <div className="flex w-full justify-between items-center py-2 pl-6 pr-4 sticky top-0 z-50 bg-white border-b border-gray-300">
                <div className="flex items-center">
                  <p className={`text-md font-medium font-body pt-1 text-blue-700`}>{`Weitere Angaben zum Termin:`}</p>
                </div>
                <div className="flex cursor-pointer z-50 text-gray-700 -mr-2">
                  <IconButton kind={IconButtonKind.default} onClick={showDetailLayer} icon={<CloseIcon />} />
                </div>
              </div>
              <div className="flex w-full jp-4 px-6 pt-4 pb-8">
                <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <InputLabel>{'Gesprächsperson:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        value={values.contactPerson}
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('contactPerson', event.currentTarget.value)}
                        type="text"
                        placeholder=""
                      />
                      {errors.contactPerson && touched.contactPerson ? (
                        <InputMessage kind={InputMessageKind.error}>{errors.contactPerson}</InputMessage>
                      ) : null}
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'Kontaktdaten:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        value={activePharmacy ? activePharmacy.email || '' : ''}
                        disabled={true}
                        onChange={() => null}
                        type="text"
                        placeholder="E-Mail"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'Termintyp:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <select
                        onChange={(event: React.FormEvent<HTMLSelectElement>) => setFieldValue('appointmentTypeId', event.currentTarget.value)}
                        className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
                      >
                        {appointmentTypes?.map((type) => (
                          <option key={`type-${type.id}`} value={type.id}>
                            {type.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'Terminstatus:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <select
                        defaultValue={values.statusId}
                        onChange={(event: React.FormEvent<HTMLSelectElement>) => setFieldValue('statusId', event.currentTarget.value)}
                        className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
                      >
                        {getAvailableStatuses().map((status) => (
                          <option key={`status-${status.id}`} value={status.id}>
                            {status.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <InputLabel>{'Notiz:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextAreaInput
                        initialValue={values.note}
                        onChange={(event: React.FormEvent<HTMLTextAreaElement>) => setFieldValue('note', event.currentTarget.value)}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <InputLabel>{'Produkte:'}</InputLabel>
                    {!selectedOrderItems?.length && Number(values.statusId) === 2 && (
                      <InputMessage kind={InputMessageKind.error}>{'Bitte wählen sie ein Produkt aus'}</InputMessage>
                    )}
                  </div>
                  {getAvailableProjects().map((project) => {
                    const setItem = selectedOrderItems.find((item) => `${item.project_id}` === `${project.id}`)
                    return (
                      <div key={`project-${project.id}`} className="sm:col-span-3 flex flex-wrap">
                        <div className="w-2/3 flex items-center">
                          <span className="text-base font-body text-gray-700 whitespace-no-wrap">{`${project.name} (${project.contact_goal})`}</span>
                        </div>
                        <div className="w-2/4 pl-4">
                          <select
                            value={setItem?.amount}
                            onChange={(event: React.FormEvent<HTMLSelectElement>) => {
                              updateOrderItems(event.currentTarget.value, event.currentTarget.id, project)
                            }}
                            id={project.id.toString()}
                            className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
                          >
                            {['0', '1', '2', '3', '4', '5', '6'].map((amount) => (
                              <option key={`project-goal-${project.id}-${amount}`} id={project.id.toString()} value={amount}>
                                {amount}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )
                  })}
                  <div className="sm:col-span-6 flex">
                    <div className="mr-2">
                      <Checkbox
                        name="sendConfirmation"
                        label="E-Mail Benachrichtigung Senden"
                        checked={values.sendConfirmation}
                        onChange={(checked: boolean) => {
                          setFieldValue('sendConfirmation', checked)
                          if (!checked) {
                            setIsCustomEmail(false)
                            setFieldValue('customEmail', '')
                          }
                        }}
                      />
                      {values.sendConfirmation && !isValidEmail && !values.customEmail ? (
                        <InputMessage kind={InputMessageKind.error}>
                          {'Bitte fügen Sie eine E-Mail hinzu, um eine Terminbestätigung zu senden'}
                        </InputMessage>
                      ) : null}
                    </div>
                    {values.sendConfirmation ? (
                      <div>
                        <Checkbox
                          name="customEmail"
                          label="Benutzerdefinierte E-Mail-Adresse hinzufügen"
                          checked={isCustomEmail}
                          onChange={() => {
                            if (isCustomEmail) {
                              setFieldValue('customEmail', '')
                            }
                            setIsCustomEmail(!isCustomEmail)
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                  {isCustomEmail && values.sendConfirmation ? (
                    <div className="sm:col-span-6">
                      <InputLabel>{'E-Mail:'}</InputLabel>
                      <div className="mt-1 rounded-md">
                        <TextInput
                          value={values.customEmail || ''}
                          onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('customEmail', event.currentTarget.value)}
                          type="text"
                        />
                        {errors.customEmail && touched.customEmail ? (
                          <InputMessage kind={InputMessageKind.error}>{errors.customEmail}</InputMessage>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex w-full border-t bg-white border-gray-300 h-20 self-end" />
            </div>
            <div className="flex sticky bg-gradient-to-t from-white to-transparent-opacity-0 self-end bottom-0 w-full justify-start p-1 pt-8 pb-6 place-items-stretch gap-1 xl:gap-2">
              <Button kind={ButtonKind.secondary} onClick={onClose}>
                {'Abbrechen'}
              </Button>
              {!isActiveDetails ? (
                <Button kind={ButtonKind.secondaryGreen} onClick={showDetailLayer}>
                  {`Details bearbeiten`}
                </Button>
              ) : null}
              {selectedTimeslot && !isOccupied && (
                <div className="gap-1 xl:gap-2 flex">
                  <Button
                    kind={ButtonKind.primary}
                    disabled={
                      isSubmitting ||
                      isOccupied ||
                      (!isValidEmail && !values.customEmail) ||
                      (!selectedOrderItems?.length && Number(values.statusId) === 2)
                    }
                    onClick={submitForm}
                  >
                    {'Termin anlegen'}
                  </Button>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </LeftSidebar>
  )
}
export default CalenderPanelAppointment
