import { DayPart, Pharmacy, Quarter, User } from '.'
import { AppointmentType } from './appointment_type'

export interface Appointments {
  appointments: Appointment[]
}
export interface AppointmentStatus {
  id: string
}
export enum AppointmentStatusType {
  Canceled = 7,
}
export interface AppointmentResponse {
  appointment: Appointment
}

export interface AppointmentOrderItem {
  project_id: number
  amount: string
}

export interface Appointment {
  id: number
  pharmacy?: Pharmacy
  quarter?: Quarter
  creator?: User
  date?: string
  note?: string
  dayParts?: DayPart[]
  appointmentType?: AppointmentType
  status?: AppointmentStatus
  order_items: AppointmentOrderItem[]
  status_id?: number
}

export interface AppointmentSummary {
  past: number
  future: number
  quarter_id: number
}
export interface AppointmentSummaryInProject {
  name: string
  past: number
  future: number
  contact_goal: number
  amount_summary: number
}
export interface SummaryAppointmentsInQuarter {
  summary: AppointmentSummary
  projects?: AppointmentSummaryInProject[]
}

export interface AppointmentTerminationReason {
  id: number
  note_de: string
  note_en?: string
}

export interface AppointmentTerminationReasonsResponse {
  termination_reasons: AppointmentTerminationReason[]
}
