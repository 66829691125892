import React from 'react'
import { AppointmentSummaryInProject } from '../../../../core-kit/build/models/appointment'
import { IAppointmentsPerProjectType } from '../../pages/OverviewPageTypes'
import { AppointmentSummaryCard } from '../AppointmentsCard/AppointmentSummaryCard'

export function AppointmentSummaryCardWithSwitcher({
  projects,
  selectedProjectIndex,
  setSelectedProjectIndex,
  appointmentsPerProject,
}: {
  projects: AppointmentSummaryInProject[]
  selectedProjectIndex: number
  setSelectedProjectIndex: React.Dispatch<React.SetStateAction<number>>,
  appointmentsPerProject: IAppointmentsPerProjectType
}) {
  function handleSelect(event: any) {
    setSelectedProjectIndex(Number(event.target.value))
  }
  return (
    <div className="h-fit relative w-full bg-white overflow-hidden rounded-md border border-solid border-gray-400 my-6 font-body">
      <div className="bg-white px-4 py-2 border-b border-gray-400 sm:pl-4 sm:pr-4 flex justify-between">
        <div className="flex w-full items-center">
          <h3 className="flex text-base leading-6 font-medium text-gray-700">{'Zusammenfassung über alle Termine aktiver Projekte'}</h3>
        </div>
        <select
          value={selectedProjectIndex}
          onChange={(event) => handleSelect(event)}
          className="w-1/3 form-select font-body h-10 text-gray-700 bg-white block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-gray-700"
        >
          {projects?.map((project: any, index: number) => (
            <option key={index} value={index}>
              {project.name}
            </option>
          ))}
        </select>
      </div>
      <div className="flex w-full flex-wrap items-stretch min-h-150 p-1">
        <AppointmentSummaryCard project={projects[selectedProjectIndex]} appointmentsPerProject={appointmentsPerProject} />
      </div>
    </div>
  )
}

export default AppointmentSummaryCardWithSwitcher
