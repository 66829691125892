import { Reminder } from '@aposphaere/core-kit'
import { ArrowUpIcon, IconButton, IconButtonKind, DropdownArrowIcon, PlusSignIcon } from '@aposphaere/ui-components'
import { CrmContext, ICrmContext, ModalKind } from '../../contexts/crmContext'

import React, { useContext } from 'react'

type ExpandTypes = {
  expanded?: boolean
  record: Reminder
  onExpand?: (record: Reminder, event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  canCreateAppointment?: boolean
}

export const ExpandIcon: React.FC<ExpandTypes> = ({ expanded, record, onExpand }) => {
  const handleOnClick = (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    if (e) {
      onExpand && onExpand(record, e)
    }
  }
  let Icon
  if (expanded) {
    Icon = ArrowUpIcon
  } else {
    Icon = DropdownArrowIcon
  }

  return <IconButton icon={<Icon />} onClick={(e) => handleOnClick(e)} kind={IconButtonKind.default} />
}

export const ExpandedDisplayList: React.FC<ExpandTypes> = ({ record, canCreateAppointment }) => {
  const { onOpenModal, setActivePharmacy } = useContext(CrmContext) as ICrmContext
  const onCreateAppointment = () => {
    setActivePharmacy(record.pharmacy)
    onOpenModal(ModalKind.Appointment)
  }
  return (
    <div className="bg-gray-100 overflow-hidden">
      <div className="flex px-2 xl:px-4 py-2 2xl:py-4">
        <div className="flex flex-col px-0 border-r w-3/12 border-gray-500">
          <h4 className="text-gray-700 border-b px-2 py-1">Name</h4>
          <div className="text-base leading-6 px-2">
            <span>{record.pharmacy.name || ''} </span>
            <br />
            <span className="font-normal text-sm italic text-gray-700">{`(ID: ${record.pharmacy.id} / ${record.pharmacy.okid || ''})`}</span>
          </div>
        </div>
        <div className="flex w-4/12 px-0 flex-col border-r border-gray-500">
          <h4 className="text-gray-700 border-b px-2 py-1">Kontaktdaten</h4>
          <div className="flex flex-col text-base leading-6 px-2 justify-center h-full">
            <p className="truncate">
              <span>
                <a href={`tel:${record.pharmacy.phone || ''}`}>{record.pharmacy.phone || ''}</a>
              </span>
            </p>
            <p className="mt-1 flex items-center text-gray-700">
              <span className="truncate">
                <a href={`mailto:${record.pharmacy.email || ''}`}>{record.pharmacy.email || ''}</a>
              </span>
            </p>
          </div>
        </div>
        <div className="flex w-4/12 px-0 flex-col border-r border-gray-500">
          <h4 className="text-gray-700 border-b px-2 py-1">Adresse</h4>
          <div className="flex text-base h-full leading-6 px-2 items-center">
            <p className="flex items-center">
              <span className="truncate">{`${record.pharmacy.address?.address_name || ''}, ${record.pharmacy.address?.zipcode || ''} ${
                record.pharmacy.address.city || ''
              }`}</span>
            </p>
          </div>
        </div>
        <div className="px-0 xl:flex xl:w-1/12 flex-col border-gray-500">
          <h4 className="text-gray-700 border-b px-2 py-1">Status</h4>
          <div className="flex text-base h-full leading-6 px-2 items-center">
            <p className="truncate">{record.pharmacy.pharmacy_status || ''}</p>
            {canCreateAppointment && <IconButton kind={IconButtonKind.default} icon={<PlusSignIcon />} onClick={onCreateAppointment} />}
          </div>
        </div>
      </div>
    </div>
  )
}
