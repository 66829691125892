import { Note, Reminder, useAbilities } from '@aposphaere/core-kit'
import { SectionCard } from '@aposphaere/ui-components'
import React, { useContext, useState, useMemo } from 'react'
import { CrmContext, ICrmContext, ModalKind } from '../../contexts/crmContext'
import NoteCardItem from './NotesCardItem'
import ReminderCardItem from './ReminderCardItem'
export interface NotesCardProps {
  notes?: Note[]
  reminders?: Reminder[]
  showAddButton: boolean
}

const NotesCard: React.FunctionComponent<NotesCardProps> = ({ notes, reminders, showAddButton }) => {
  const sortedNote = useMemo<Note[] | undefined>(() => notes?.slice().sort((a, b) => Date.parse(b.created_at) + Date.parse(a.created_at)), [notes])

  const {
    selectNoteHook: { setCurrentlySelectedNote },
    onOpenModal,
  } = useContext(CrmContext) as ICrmContext
  const {
    selectReminderHook: { setCurrentlySelectedReminder },
  } = useContext(CrmContext) as ICrmContext

  const [expanded, setExpanded] = useState<boolean>(false)

  const deleteNote = () => onOpenModal(ModalKind.NoteDeletion)
  const editNote = () => onOpenModal(ModalKind.NoteEditing)

  const deleteReminder = () => onOpenModal(ModalKind.ReminderDelete)
  const editReminder = () => onOpenModal(ModalKind.ReminderEdit)

  const abilities = useAbilities()

  return (
    <>
      {sortedNote !== undefined ? (
        <SectionCard
          isExpanded={expanded}
          setExpanded={setExpanded}
          title="Notizen"
          badgeValue={sortedNote.length}
          onAdd={() => onOpenModal(ModalKind.NoteCreature)}
          showAddButton={showAddButton}
          showAllEntries={sortedNote.length <= 6}
        >
          {sortedNote.length === 0 ? (
            <p className="w-full py-2 text-base text-gray-600 text-center">{'Keine Einträge vorhanden'}</p>
          ) : (
            sortedNote.map((note) => (
              <NoteCardItem
                setCurrentlySelectedNote={setCurrentlySelectedNote}
                onOpenModal={[editNote, deleteNote]}
                canEditNotes={abilities.edit_notes}
                canDeleteNotes={abilities.delete_notes}
                note={note}
                key={note.id}
              />
            ))
          )}
        </SectionCard>
      ) : null}

      {reminders !== undefined ? (
        <SectionCard
          isExpanded={expanded}
          setExpanded={setExpanded}
          title="Aufgaben"
          badgeValue={reminders.length}
          onAdd={() => onOpenModal(ModalKind.ReminderCreate)}
          showAddButton={showAddButton}
          showAllEntries={reminders.length <= 6}
        >
          {reminders.length === 0 ? (
            <p className="w-full py-2 text-base text-gray-600 text-center">{'Keine Einträge vorhanden'}</p>
          ) : (
            reminders.map((reminder) => (
              <ReminderCardItem
                setCurrentlySelectedReminder={setCurrentlySelectedReminder}
                onOpenModal={[editReminder, deleteReminder]}
                canEditReminders={abilities.edit_notes}
                canDeleteReminders={abilities.delete_notes}
                reminder={reminder}
                key={reminder.id}
              />
            ))
          )}
        </SectionCard>
      ) : null}
    </>
  )
}

export default NotesCard
