import React, { FunctionComponent } from 'react'

export type TagToggleButtonProps = {
  selected: boolean
  onClick: () => void
  children?: React.ReactNode
  customCSS?: string
}

const TagToggleButton: FunctionComponent<TagToggleButtonProps> = ({ selected, onClick, children, customCSS }) => {
  const tagStyle = selected
    ? 'bg-blue-50 text-blue-700 border-blue-600 border border-solid hover:bg-blue-600 hover:text-white'
    : 'bg-white text-gray-700 border-gray-400 border border-solid hover:bg-gray-300 hover:text-gray-700'
  return (
    <button
      type="button"
      onClick={onClick}
      className={`text-base rounded-md py-2 px-1 2xl:px-2 focus:outline-none active:bg-blue-600 active:text-white active:border-blue-600 ${tagStyle} ${customCSS}`}
    >
      <span className="-mt-px">{children}</span>
    </button>
  )
}

export default TagToggleButton
