import { Appointment, formattedDateString, formattedTimeString, Project } from '@aposphaere/core-kit'
import { EditIcon, IconButton, IconButtonKind } from '@aposphaere/ui-components'
import React from 'react'
// import { IconButton, IconButtonKind } from "@aposphaere/ui-components"
// import { DeleteIcon, EditIcon } from "@aposphaere/ui-components"

type AppointmentsCardItemProps = {
  appointment: Appointment
  canEditDelete: boolean
  setCurrentAppointment: () => void
  canEditAppointment: boolean
  projects: Project[]
}

const AppointmentsCardItem: React.FunctionComponent<AppointmentsCardItemProps> = ({
  canEditAppointment,
  setCurrentAppointment,
  appointment,
  canEditDelete,
  projects,
}) => {
  // Set class depending on status appointment.kind === "pending" ? 'w-4/6' : 'w-5/6'
  const additionalCssClass = ''
  return (
    <li className="w-full px-2 2xl:px-4 border-b border-gray-400 last:border-0">
      <div className="grid grid-cols-7 xl:grid-cols-6 gap-2 w-full py-4">
        <div className="flex col-span-1 min-w-130px">
          <div className="text-base leading-6 font-medium">
            {/* Date  */}
            <span className="font-medium">
              {appointment.date ? formattedDateString(new Date(appointment.date.toString().replace(/\s/, 'T'))) : ''}
            </span>
            <br />
            {/* Time  */}
            <span className="font-medium ">
              {appointment.date ? formattedTimeString(new Date(appointment.date.toString().replace(/\s/, 'T'))) : ''}
            </span>
          </div>
        </div>
        <div className="col-span-1 xl:hidden" />
        <div className={`flex ${additionalCssClass} col-span-4 items-center`}>
          <div className="text-base leading-6 font-medium">
            {/* <appointment-type> (name-of-the-products-as-comma-seperated-list) */}
            <span className="font-normal">
              {`${appointment.pharmacy?.name || ''} (ID:${appointment.pharmacy?.id || ''} / ${appointment.pharmacy?.okid || ''})`}
            </span>
            <br />
            <span className="font-normal">
              {`${appointment.pharmacy?.address.address_name || ''}, ${appointment.pharmacy?.address.zipcode || ''} ${
                appointment.pharmacy?.address.city || ''
              }.`}
            </span>
            <br />
            {/* 10 – Number of booked items //  Referent: <name-of-the-user-in-that-region> */}
            {projects.map((project, index) => (
              <span key={project.name} className="font-normal">{`${project.name} ${
                appointment.order_items.find((item) => item.project_id === +project.id)?.amount || ''
              } - Teilnehmer${index === projects.length - 1 ? '' : ','} `}</span>
            ))}
            <br />
          </div>
        </div>
        {canEditDelete && (
          <>
            <div className="xl:flex hidden col-span-1 justify-end">
              {canEditAppointment && <IconButton kind={IconButtonKind.default} icon={<EditIcon />} onClick={setCurrentAppointment} />}
            </div>
            <div className="flex xl:hidden col-span-1 justify-end">
              {canEditAppointment && <IconButton kind={IconButtonKind.default} icon={<EditIcon dimension="30" />} onClick={setCurrentAppointment} />}
            </div>
          </>
        )}
      </div>
    </li>
  )
}

export default AppointmentsCardItem
