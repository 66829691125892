import { PTA } from '@aposphaere/core-kit'
import { SectionCard } from '@aposphaere/ui-components'
import React, { useContext, useState } from 'react'
import { CrmContext, ICrmContext, ModalKind } from '../../contexts/crmContext'
import PersonenCardItem from './PersonenCardItem'

export interface PersonenCardProps {
  showAddButton: boolean
  employees: PTA[]
}
const PersonenCard: React.FunctionComponent<PersonenCardProps> = ({ showAddButton, employees }) => {
  const {
    onOpenModal,
    selectEmployeeHook: { setCurrentlySelectedEmployee },
  } = useContext(CrmContext) as ICrmContext

  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <SectionCard
      isExpanded={expanded}
      setExpanded={setExpanded}
      title="Personen"
      showAddButton={showAddButton}
      showAllEntries={employees.length <= 6}
    >
      {employees?.length === 0 ? (
        <p className="w-full py-2 text-base text-gray-600 text-center">{'Keine Einträge vorhanden'}</p>
      ) : (
        employees?.map((employee) => (
          <PersonenCardItem
            setCurrentlySelectedEmployee={setCurrentlySelectedEmployee}
            key={employee.id}
            employee={employee}
            onOpenModal={() => onOpenModal(ModalKind.PersonenEdit)}
            canEditEmployee
          />
        ))
      )}
    </SectionCard>
  )
}
export default PersonenCard
