import React, { useContext } from 'react'
import { CrmContext, ICrmContext } from '../../contexts/crmContext'

const QuarterSwitcher: React.FC = () => {
  const { quarters, onQuarterChange, selectedQuater } = useContext(CrmContext) as ICrmContext
  return (
    <select
      value={selectedQuater?.id}
      onChange={(event) => onQuarterChange(event.target.value)}
      className="w-full form-select font-body h-10 text-blue-800 bg-blue-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-blue-700 focus:border-blue-400"
    >
      {quarters?.map((quarter) => (
        <option key={quarter.id} value={quarter.id}>
          {quarter.label}
        </option>
      ))}
    </select>
  )
}

export default QuarterSwitcher
