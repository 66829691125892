import {
  Appointment,
  AppointmentType,
  IRouteResponse,
  Note,
  NoteWeek,
  Offtime,
  Pharmacy,
  PharmacyClusterUsers,
  PharmacyProjectStatus,
  Project,
  PTA,
  Quarter,
  Reminder,
  Status,
  UserAddress,
  ILocation,
} from '@aposphaere/core-kit'
import { OfftimeKind } from '@aposphaere/ui-components'
import React, { createContext } from 'react'
import Notification from '../components/NotificationManager/notification'
import { IPointType } from '../components/PharmacyDataSheetPanel/PharmacyDataSheetTypes'
import { useCrmProvider } from '../hooks/useCrmProvider'
import { IActivitiesType, IAppointmentsPerProjectType } from '../pages/OverviewPageTypes'
import { initialsStates, reducers, types } from './reducers'

export enum ModalKind {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Appointment,
  Export,
  NoteCreature,
  NoteEditing,
  NoteDeletion,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Pharmacy,
  ReminderCreate,
  ReminderEdit,
  ReminderDelete,
  PharmacyUpdate,
  ProjectsEdit,
  PersonenEdit,

  NoteWeekPharmacyClusterCreate,
  NoteWeekPharmacyClusterUpdate,
  NoteWeekPharmacyClusterDelete,
}

/**
 * Definition for the CRM Context
 */
export interface ICrmContext {
  /**
   * The user itself. If the person is not authenticated the object is null
   */
  pharmacies?: Pharmacy[]

  /**
   * The function for update array of pharmacies after some action
   */
  updatePharmacies: (pharmacy: Pharmacy | undefined, deactivate?: boolean) => void

  /**
   * List of selected Projects Pharmacies
   */
  selectedPharmacies: Pharmacy[]

  /**
   * currently selected pharmacy id
   */
  selectedPharmacyId?: number

  /**
   * Sets which pharmacy id is currently selected
   */
  setSelectedPharmacyId: React.Dispatch<React.SetStateAction<number | undefined>>

  /**
   * Hook to set pharmacy branch, get pharmacy branch details, set and get active pharmacy branch
   */
  selectPharmacyBranchHook: {
    selectedPharmacyBranchId?: number
    setSelectedPharmacyBranchId: React.Dispatch<React.SetStateAction<number | undefined>>
    pharmacyDetails: Pharmacy | undefined
    clear: () => void
    pharmacyBranchActive: boolean
    setPharmacyBranchActive: React.Dispatch<React.SetStateAction<boolean>>
  }

  /**
   * A list of appointments
   */
  appointments?: Appointment[]

  /**
   * A list of notes
   */
  notes?: Note[]

  /**
   * A list of Reminder
   */
  reminders?: Reminder[]

  /**
   * Reminders loading state
   */
  isRemindersLoaded: boolean

  /**
   * A list of offtimes
   */
  offtimes?: Offtime[]

  /**
   * A list of all available user Pharmacy Clusters
   */
  userPharmacyClusters?: PharmacyClusterUsers[]

  /**
   * Selected Quater
   */
  selectedQuater?: Quarter

  currentlyOpenedModalKind?: null | ModalKind

  /**
   * Calles when a user Pharmacy Cluster changes
   */
  userPharmacyClusterChange: (id: string) => void

  selectedPharmacyClusterId: string | undefined
  /**
   * A list of all available regions
   */
  quarters?: Quarter[]

  /**
   * Calles when a region changes
   */
  onQuarterChange: (id: string) => void

  /**
   * Opens the specified modal
   */
  onOpenModal: (kind: ModalKind) => void

  /**
   * Closes the current modal
   */
  onCloseModal: () => void

  /**
   * Sets which pharmacy is currently handled
   */
  setActivePharmacy: (pharmacy?: Pharmacy) => void

  /**
   * The currently active pharmacy
   */
  currentlyActivePharmacy?: Pharmacy

  /**
   * List of avialable statuses for making appointments
   */
  statuses?: Status[]

  /**
   * List of avialable statuses for making appointments
   */
  appointmentTypes?: AppointmentType[]

  /**
   * List of aposphäre wirde projects
   */
  projects?: Project[]
  activeProjects?: Project[]

  onProjectClick: (project: Project) => void
  onAllProjectClick: () => void

  /**
   * Retrieves an updated list of Appointments
   */
  refreshAppointments: () => void

  /**
   * Adds A notification to the app
   */
  presentNotification: (notification: Notification) => void

  notification?: Notification
  /**
   * Clear Notifications to zero
   */
  clearNotification: () => void
  /**
   * List of sorted Appointments and pharmacies when day selected
   */
  routeMap: { sortedAppointmentsToday: Appointment[] | null; pharmaciesHasAppointmentsToday: Pharmacy[] | null }

  /**
   * Opens the left side panel with the selected pharmacy
   */
  onOpenPharmacyDetails: () => void

  /**
   * Closes the left side panel with the selected pharmacy
   */
  onClosePharmacyDetails: () => void

  /**
   * Retrieves an updated Pharmacy
   */
  getFreshPharmacyDetails: (id: number, branch: boolean) => Promise<Pharmacy | undefined>

  /**
   * Retrieves an updated activities
   */
  getFreshActivities: (quarterId: number) => Promise<IActivitiesType | undefined>

  /**
   * Retrieves an updated appointments per project
   */
  getFreshAppointmentsPerProject: (quarterId: number, projectId: number) => Promise<IAppointmentsPerProjectType | undefined>

  /**
   * Hook to select note, and show selected note
   */
  selectNoteHook: { currentlySelectedNote: Note | null; setCurrentlySelectedNote: React.Dispatch<React.SetStateAction<Note | null>> }
  /**
   * Hook to select note, and show selected note
   */
  selectReminderHook: {
    currentlySelectedReminder: Reminder | null
    setCurrentlySelectedReminder: React.Dispatch<React.SetStateAction<Reminder | null>>
  }
  /**
   * Hook to select Appointment, and show selected Appointment
   */
  appointmentHook: {
    currentlySelectedAppointment: Appointment | null
    setCurrentAppointment: React.Dispatch<React.SetStateAction<Appointment | null>>
  }
  /**
   * Hook to select Day, and show selected Day
   */
  selectedDayHook: { selectedDay: Date | undefined; setSelectedDay: React.Dispatch<React.SetStateAction<Date | undefined>> }
  /**
   * Hook to set routResponse, and show routResponse data
   */
  routeHook: { routeResponse: IRouteResponse | null; setRouteResponse: React.Dispatch<React.SetStateAction<IRouteResponse | null>> }

  /**
   * Hook to refetch Reminders, and take it
   */
  remindersHook: { reminders: Reminder[] | null; refetchReminders: () => void }
  /**
   * current User Address from selected userPharmacyClusters
   */
  currentUserAddress: UserAddress | undefined
  /**
   * hook to show or hide list viewed Pharmacies Table
   */
  activePharmaciesTableHook: {
    listViewedActivePharmaciesTable: boolean
    setListViewedActivePharmaciesTable: React.Dispatch<React.SetStateAction<boolean>>
  }
  /**
   * hook to work with filter input
   */
  searchFilterHook: {
    filterState: types['IFilterState']
    dispatchFilterState: React.Dispatch<types['IFilterAction']>
  }

  /**
   * hook to work with filter on map
   */
  filterOnMapHook: {
    filterOnMapState: types['IOnMapFilterState']
    dispatchFilterOnMapState: React.Dispatch<types['IOnMapFilterAction']>
  }
  /**
   * hook to work with pharmacyProjectStatuses
   */
  pharmacyProjectStatusesHook: {
    updatePharmacyProjectStatuses: (status: PharmacyProjectStatus, addMode: boolean, deleteMode: boolean) => void
    pharmacyProjectStatuses: PharmacyProjectStatus[] | undefined
    currentPharmacyProjectStatuses: PharmacyProjectStatus[]
  }
  /**
   * function to toggle available status fot call center and update pharmacy details
   */
  toggleAvailableForCallCenter: (arg0: boolean, arg1?: Pharmacy) => Promise<void | Pharmacy>
  /**
   * hook to control appointmentCreationProcess
   */
  appointmentCreationHook: {
    setTemporaryAppointment: React.Dispatch<Appointment | null>
  }
  leftSideBoardStatus: 'calendar' | 'pharmacyDetails' | 'appointmentCreation'
  setLeftSideBoardStatus: React.Dispatch<'calendar' | 'pharmacyDetails' | 'appointmentCreation'>
  nextPossibleDate: string | undefined
  /**
   * selected KW week
   */
  selectedWeek?: number

  /**
   * set week when we click on KW title
   */
  setSelectedWeek: React.Dispatch<React.SetStateAction<number | undefined>>
  /**
   * selected year
   */
  selectedYear?: number

  /**
   * set year when we click on KW title
   */
  setSelectedYear: React.Dispatch<React.SetStateAction<number | undefined>>

  noteWeeks: NoteWeek[] | undefined

  selectedNoteWeek: NoteWeek | undefined

  getNoteWeek: (week?: number, year?: number) => NoteWeek | undefined

  refreshNoteWeeks: () => void

  zoomToLocation: (location: ILocation, mapInstance: { fitBounds: (arg0: any) => void }) => void

  isNoteWeeksLoaded: boolean
  setOfftimeType: React.Dispatch<React.SetStateAction<OfftimeKind | undefined>>
  offtimeType: OfftimeKind | undefined
  /**
   * Retrieves an updated list of useOfftimes
   */
  refreshOfftimes: () => void

  mapInstance: { fitBounds: (arg0: any) => void } | null

  setMapInstance: React.Dispatch<React.SetStateAction<{ fitBounds: (arg0: any) => void } | null>>
  selectEmployeeHook: {
    currentlySelectedEmployee: null | PTA
    setCurrentlySelectedEmployee: React.Dispatch<React.SetStateAction<null | PTA>>
  }

  fitToMarkers: (mapInstance: { fitBounds: (arg0: any) => void }, markers: Pharmacy[]) => void
  /**
   * Retrieves an updated list of clusters
   */
  refreshClusters: () => void

  currentPointHook: {
    currentPoint: IPointType
    setCurrentPoint: React.Dispatch<React.SetStateAction<IPointType>>
  }
  infoRef: React.RefObject<HTMLHeadingElement>
  notizenRef: React.RefObject<HTMLHeadingElement>
  aufgabenRef: React.RefObject<HTMLHeadingElement>
  filialenRef: React.RefObject<HTMLHeadingElement>
  termineRef: React.RefObject<HTMLHeadingElement>
  projekteRef: React.RefObject<HTMLHeadingElement>
  personenRef: React.RefObject<HTMLHeadingElement>

  windowRef: React.RefObject<HTMLDivElement>
  minimizeLeftPaneHook: {
    showLeftPane: boolean
    setShowLeftPane: React.Dispatch<React.SetStateAction<boolean>>
  }
}

interface CrmProviderProps {
  children?: React.ReactNode
}

export const CrmContext = createContext<ICrmContext | null>(null)

/**
 * here we export reducers and initialStates
 *
 */
export const { filterReducer, filterOnMapReducer } = reducers
export const { initialFilterState, initialOnMapFilterState } = initialsStates

/**
 * Populates an implementation of AuthContext to its children.
 *
 */
const CrmProvider: React.FunctionComponent<CrmProviderProps> = ({ children }) => {
  const crmData = useCrmProvider()
  return <CrmContext.Provider value={crmData}>{children}</CrmContext.Provider>
}

export default CrmProvider
