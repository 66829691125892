import {
  Button,
  ButtonKind,
  InputLabel,
  InputMessage,
  InputMessageKind,
  Modal,
  ModalKind,
  NotificationBannerKind,
  TextAreaInput,
  TextInput,
} from '@aposphaere/ui-components'

import { createNote, updatePharmacy, PharmacyResponse, useAuth } from '@aposphaere/core-kit'
import { Formik } from 'formik'
import React, { useContext, useState } from 'react'
import * as Yup from 'yup'

import { CrmContext, ICrmContext } from '../../contexts/crmContext'
import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'

type DeactivatePharmacyFormValues = {
  pharmacyId?: number
  note: string
  reason?: string
  pharmacy_status?: string
  other_reason?: string
}

export enum PharmacyStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum Reason {
  Closed = 'Apotheke dauerhaft geschlossen',
  NoTraining = 'Apotheke will keine Inhouse-Schulungen',
  others = 'Andere',
}

const DeactivatePharmacy: React.FC = () => {
  const context = useContext(CrmContext) as ICrmContext
  const auth = useAuth()
  const [isOtherReason, setIsOtherReason] = useState<boolean>(false)

  const {
    selectPharmacyBranchHook: { pharmacyBranchActive, pharmacyDetails },
    updatePharmacies,
  } = context

  const createNoteMutation = useAuthenticatedMutation(createNote)
  const updatePharmacyMutation = useAuthenticatedMutation<PharmacyResponse>(updatePharmacy)

  const activePharmacy = pharmacyBranchActive ? pharmacyDetails : context?.currentlyActivePharmacy

  const intialFormValues: DeactivatePharmacyFormValues = {
    pharmacyId: activePharmacy ? activePharmacy.id : undefined,
    note: '',
    pharmacy_status: activePharmacy?.pharmacy_status || PharmacyStatus.Active,
    reason: activePharmacy?.reason_for_deactivation || Reason.Closed,
  }

  const PharmacyValidationScheme = Yup.object().shape({
    other_reason: isOtherReason ? Yup.string().required() : Yup.string().nullable(),
    note: Yup.string().min(3, 'Zu kurz'),
  })

  const onFormSubmit = async (values: DeactivatePharmacyFormValues) => {
    const noteVariables = {
      pharmacy_id: values.pharmacyId,
      user_id: auth.user?.id,
      note_type: 'deactivation/activation',
      title: values.pharmacy_status,
      content: values.note,
    }

    const pharmacyVariables = {
      id: values.pharmacyId,
      pharmacy_status: values.pharmacy_status,
      reason: values.reason === Reason.others ? values.other_reason : values.reason,
    }

    if (noteVariables.content.length) {
      const responseNote = await createNoteMutation(noteVariables)
      if (responseNote.errors !== undefined) {
        alert(responseNote.errors)
        return
      }
    }

    const responsePharmacy = await updatePharmacyMutation(pharmacyVariables)
    if (responsePharmacy.errors !== undefined) {
      alert(responsePharmacy.errors)
      return
    }
    const PharmacyId = activePharmacy?.id || 0
    const details = await context?.getFreshPharmacyDetails(PharmacyId, false)
    updatePharmacies(details, false)

    context.onCloseModal()
    context.presentNotification({
      title: 'Die Apotheke wurde erfolgreich deaktiviert',
      content: '',
      kind: NotificationBannerKind.success,
    })
  }

  return (
    <Modal
      kind={ModalKind.sm}
      title={'Standort deaktivieren'}
      onClose={context ? context?.onCloseModal : () => console.log('close')}
      onBack={() => null}
    >
      <Formik key="pharmacy-deactivate-form" initialValues={intialFormValues} onSubmit={onFormSubmit} validationSchema={PharmacyValidationScheme}>
        {({ errors, touched, values, handleSubmit, setFieldValue, isSubmitting }) => (
          <div className="flex flex-wrap w-full">
            <div className="flex flex-wrap w-full">
              <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <InputLabel>{'Apothekenstatus:'}</InputLabel>
                  <div className="mt-1 rounded-md">
                    <select
                      defaultValue={values.pharmacy_status}
                      onChange={(event: React.FormEvent<HTMLSelectElement>) => {
                        if (isOtherReason && event.currentTarget.value === PharmacyStatus.Active) {
                          setIsOtherReason(false)
                        }
                        setFieldValue('pharmacy_status', event.currentTarget.value)
                      }}
                      className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
                    >
                      <option value={PharmacyStatus.Active}>Aktiv</option>
                      <option value={PharmacyStatus.Inactive}>Inaktiv</option>
                    </select>
                  </div>
                </div>
                {values.pharmacy_status === PharmacyStatus.Inactive ? (
                  <div className="sm:col-span-6">
                    <InputLabel>{'Grund für die Deaktivierung:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <select
                        defaultValue={values.reason}
                        onChange={(event: React.FormEvent<HTMLSelectElement>) => {
                          if (event.currentTarget.value === Reason.others) {
                            setIsOtherReason(true)
                          } else {
                            setIsOtherReason(false)
                          }
                          setFieldValue('reason', event.currentTarget.value)
                        }}
                        className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md pr-10 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-1 border-solid border-gray-400 focus:border-blue-400"
                      >
                        <>
                          <option>{Reason.Closed}</option>
                          <option>{Reason.NoTraining}</option>
                          <option>{Reason.others}</option>
                        </>
                      </select>
                    </div>
                  </div>
                ) : null}
                {values.reason === Reason.others ? (
                  <div className="sm:col-span-6">
                    <InputLabel>{'anderer Grund:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('other_reason', event.currentTarget.value)}
                        type="text"
                        value={values.other_reason || ''}
                      />
                      {values.reason === 'Andere' && errors.other_reason && touched.other_reason ? (
                        <InputMessage kind={InputMessageKind.error}>{errors.other_reason}</InputMessage>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className="sm:col-span-6">
                  <InputLabel>{'Kommentar zur Deaktivierung:'}</InputLabel>
                  <div className="mt-1 rounded-md">
                    <TextAreaInput
                      onChange={(event: React.FormEvent<HTMLTextAreaElement>) => setFieldValue('note', event.currentTarget.value)}
                      placeholder=""
                    />
                    {errors.note && touched.note ? <InputMessage kind={InputMessageKind.error}>{errors.note}</InputMessage> : null}
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-between p-4 pt-8 pb-6 place-items-stretch">
              <Button kind={ButtonKind.outlinedSecondary} onClick={context ? context?.onCloseModal : () => console.log('close')}>
                {'Abbrechen'}
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={isSubmitting || (isOtherReason && !values.other_reason)}
                kind={values.pharmacy_status === PharmacyStatus.Inactive ? ButtonKind.danger : ButtonKind.primary}
              >
                {'Deaktivierung bestätigen'}
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  )
}

export default DeactivatePharmacy
