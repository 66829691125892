import { createNoteForWeekPharmacyCluster, updateNoteWeekForPharmacyCluster, deleteNoteWeekForPharmacyCluster } from '@aposphaere/core-kit'
import {
  Button,
  ButtonKind,
  InputLabel,
  InputMessage,
  InputMessageKind,
  Modal,
  ModalKind,
  NotificationBannerKind,
  TextAreaInput,
} from '@aposphaere/ui-components'
import { Formik } from 'formik'
import React, { useContext, useState } from 'react'
import { CrmContext, ICrmContext } from '../../contexts/crmContext'
import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'
import * as Yup from 'yup'

type NoteWeekFormValues = {
  note: string
}
type NoteWeekCreate = {
  year: string
  week: string
  pharmacy_cluster_id: string
  note: string
}
type NoteWeekUpdate = {
  id: number | undefined
  note: string
}

type NoteWeekDelete = {
  id: number
}

type INoteWeekPharmacyClusterModalProps = {
  mode: 'create' | 'edit' | 'delete'
}

const NoteWeekPharmacyClusterModal: React.FC<INoteWeekPharmacyClusterModalProps> = ({ mode }) => {
  const context = useContext(CrmContext) as ICrmContext
  const { selectedWeek, selectedYear, selectedPharmacyClusterId, getNoteWeek, refreshNoteWeeks } = context

  const mutationCreate = useAuthenticatedMutation<NoteWeekCreate>(createNoteForWeekPharmacyCluster)
  const mutationUpdate = useAuthenticatedMutation<NoteWeekCreate>(updateNoteWeekForPharmacyCluster)
  const mutationDelete = useAuthenticatedMutation<NoteWeekDelete>(deleteNoteWeekForPharmacyCluster)

  const [title, setTitle] = useState<string>(selectedWeek ? `KW ${selectedWeek}` : 'KW Nicht gefundet')

  const noteWeek = getNoteWeek(selectedWeek, selectedYear)
  const existedNote = noteWeek?.note
  const intialFormValues: NoteWeekFormValues = {
    note: existedNote || '',
  }

  const onFormSubmit = async (values: NoteWeekFormValues) => {
    if (!selectedPharmacyClusterId || !selectedWeek || !selectedYear) {
      console.error(`can't create note`)
      return
    }

    let mutation
    let notificationTitle = ''

    if (mode === 'create') {
      const noteWeekCreate: NoteWeekCreate = {
        note: values.note,
        pharmacy_cluster_id: selectedPharmacyClusterId,
        week: selectedWeek.toString(),
        year: selectedYear.toString(),
      }
      mutation = mutationCreate(noteWeekCreate)
      notificationTitle = 'Notiz gespeichert'
    }

    if (mode === 'edit') {
      const NoteWeekUpdate: NoteWeekUpdate = {
        id: noteWeek?.id,
        note: values.note ?? '',
      }
      mutation = mutationUpdate(NoteWeekUpdate)
      notificationTitle = 'Notiz aktualisiert'
    }

    if (mode === 'delete') {
      if (!noteWeek?.id) {
        return
      }
      const NoteWeekDelete: NoteWeekDelete = {
        id: noteWeek.id,
      }
      mutation = mutationDelete(NoteWeekDelete)
      notificationTitle = 'Notiz gelöscht'
    }

    if (!mutation) {
      throw new Error()
    }

    const response = await mutation
    if (response.errors !== undefined) {
      alert(response.errors)
      return
    }
    refreshNoteWeeks()
    closeModal()

    context.presentNotification({
      title: notificationTitle,
      content: '',
      kind: NotificationBannerKind.success,
    })
  }
  const closeModal = () => {
    context.onCloseModal()
  }

  const onCloseCalendar = () => {
    setTitle('Neuer Termin')
  }
  const NoteWeekSchema = Yup.object().shape({
    note: Yup.string().required('Pflichtfeld'),
  })
  return (
    <Modal kind={ModalKind.sm} title={title} onClose={closeModal} onBack={onCloseCalendar} noPadding={true}>
      <Formik key="note-week-pharmacy-creation-form" initialValues={intialFormValues} onSubmit={onFormSubmit} validationSchema={NoteWeekSchema}>
        {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
          <>
            <div className={`flex flex-wrap w-full px-4 xl:px-6 pt-2`}>
              <div className="flex flex-wrap w-full">
                <div className="w-full p-4">
                  <div className="sm:col-span-6">
                    <InputLabel>{'Notiz:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextAreaInput
                        disabled={mode === 'delete'}
                        initialValue={values.note}
                        onChange={(event: React.FormEvent<HTMLTextAreaElement>) => setFieldValue('note', event.currentTarget.value)}
                        placeholder=""
                      />
                    </div>
                    {errors.note ? <InputMessage kind={InputMessageKind.error}>{errors.note}</InputMessage> : null}
                  </div>
                  <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-between p-4 pt-8 pb-6 place-items-stretch">
                    <Button kind={ButtonKind.secondary} onClick={context ? context?.onCloseModal : () => console.log('close')}>
                      {'Abbrechen'}
                    </Button>
                    <div className="space-x-4">
                      <Button kind={mode === 'delete' ? ButtonKind.danger : ButtonKind.primary} disabled={isSubmitting} onClick={handleSubmit}>
                        {mode === 'create' && 'Notiz speichern'}
                        {mode === 'edit' && 'Änderungen speichern'}
                        {mode === 'delete' && 'Notiz löschen'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </Modal>
  )
}

export default NoteWeekPharmacyClusterModal
