import { updatePTA } from '@aposphaere/core-kit'
import {
  Button,
  ButtonKind,
  InputLabel,
  InputMessage,
  InputMessageKind,
  Modal,
  ModalKind,
  NotificationBannerKind,
  TextInput,
} from '@aposphaere/ui-components'
import { Formik } from 'formik'
import React, { useContext, useCallback } from 'react'
import * as Yup from 'yup'

import { CrmContext, ICrmContext } from '../../contexts/crmContext'
import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'

type PersonenFormValues = {
  pharmacy_id?: number
  first_name?: string
  last_name?: string
  email?: string
  address?: string
  zipcode?: string
  gender?: string
}

interface IPersonenModal {
  editing?: boolean
  deleting?: boolean
  creating?: boolean
}

const PersonenModal: React.FC<IPersonenModal> = ({ deleting = false, editing = false }) => {
  const context = useContext(CrmContext) as ICrmContext

  const {
    selectEmployeeHook: { currentlySelectedEmployee },
    currentlyActivePharmacy,
    selectPharmacyBranchHook: { pharmacyBranchActive, pharmacyDetails, setPharmacyBranchActive },
  } = context

  const updatePTAMutation = useAuthenticatedMutation(updatePTA)

  const getMutationWithVariables = (values: PersonenFormValues) => {
    const mutation = updatePTAMutation
    const variables = deleting
      ? { id: currentlySelectedEmployee?.id }
      : editing
      ? {
          ...values,
          id: currentlySelectedEmployee?.id,
        }
      : values
    return { mutation, variables }
  }

  const activePharmacy = pharmacyBranchActive ? pharmacyDetails : currentlyActivePharmacy

  const initialFormValues: PersonenFormValues = {
    pharmacy_id: activePharmacy ? activePharmacy.id : undefined,
    first_name: currentlySelectedEmployee?.first_name,
    last_name: currentlySelectedEmployee?.last_name,
    email: currentlySelectedEmployee?.email,
    gender: currentlySelectedEmployee?.gender,
    zipcode: currentlySelectedEmployee?.zipcode,
    address: currentlySelectedEmployee?.address,
  }

  const PersonenValidationScheme = Yup.object().shape({
    first_name: Yup.string().required('Pflichtfeld'),
    last_name: Yup.string().required('Pflichtfeld'),
    email: Yup.string().required('Pflichtfeld'),
    gender: Yup.string().required('Pflichtfeld'),
    zipcode: Yup.string().required('Pflichtfeld'),
    address: Yup.string().required('Pflichtfeld'),
  })

  const onFormSubmit = async (values: PersonenFormValues) => {
    const { mutation, variables } = getMutationWithVariables(values)

    const noteResponse = await mutation(variables)
    if (noteResponse.errors !== undefined) {
      alert(noteResponse.errors)
      return
    }
    const { pharmacy_id = 0 } = values

    await context?.getFreshPharmacyDetails(pharmacy_id, pharmacyBranchActive)
    if (pharmacyBranchActive) {
      setPharmacyBranchActive(false)
    }
    const { notificationText } = getTextObject()

    closeModal()
    context.presentNotification({
      title: notificationText,
      content: '',
      kind: NotificationBannerKind.success,
    })
  }

  const closeModal = () => {
    context.onCloseModal()
    context.selectEmployeeHook.setCurrentlySelectedEmployee(null)
  }

  const getTextObject = useCallback(() => {
    if (deleting) {
      return {
        titleModalText: 'Personal löschen',
        submitButtonText: 'Löschen bestätigen',
        notificationText: 'Personal erfolgreich gelöscht',
      }
    } else if (editing) {
      return {
        titleModalText: 'Personal speichern',
        submitButtonText: 'Personal speichern',
        notificationText: 'Personal erfolgreich gespeichert',
      }
    }
    return {
      titleModalText: 'Personal anlegen',
      submitButtonText: 'Personal anlegen',
      notificationText: 'Personal erfolgreich erstellt',
    }
  }, [deleting, editing])

  return (
    <Modal kind={ModalKind.ms} title={getTextObject().titleModalText} onClose={closeModal} onBack={() => null}>
      <Formik key="personal-creation-form" initialValues={initialFormValues} onSubmit={onFormSubmit} validationSchema={PersonenValidationScheme}>
        {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
          <div className="flex flex-wrap w-full">
            <div className="flex flex-wrap w-full">
              <div className="w-full p-4">
                <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <InputLabel>{'Vorname:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        value={values.first_name || ''}
                        type="text"
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('first_name', event.currentTarget.value)}
                      />
                      {errors.first_name && touched.first_name ? (
                        <InputMessage kind={InputMessageKind.error}>{errors.first_name}</InputMessage>
                      ) : null}
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <InputLabel>{'Nachname:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        value={values.last_name || ''}
                        type="text"
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('last_name', event.currentTarget.value)}
                      />
                      {errors.last_name && touched.last_name ? <InputMessage kind={InputMessageKind.error}>{errors.last_name}</InputMessage> : null}
                    </div>
                  </div>
                  <div className="sm:col-span-4">
                    <InputLabel>{'Email:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        value={values.email || ''}
                        type="text"
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('email', event.currentTarget.value)}
                      />
                      {errors.email && touched.email ? <InputMessage kind={InputMessageKind.error}>{errors.email}</InputMessage> : null}
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <InputLabel>{'Geschlecht:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        value={values.gender || ''}
                        type="text"
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('gender', event.currentTarget.value)}
                      />
                      {errors.gender && touched.gender ? <InputMessage kind={InputMessageKind.error}>{errors.gender}</InputMessage> : null}
                    </div>
                  </div>

                  <div className="sm:col-span-5">
                    <InputLabel>{'die Anschrift:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        value={values.address || ''}
                        type="text"
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('address', event.currentTarget.value)}
                      />
                      {errors.address && touched.address ? <InputMessage kind={InputMessageKind.error}>{errors.address}</InputMessage> : null}
                    </div>
                  </div>
                  <div className="sm:col-span-1">
                    <InputLabel>{'Postleitzahl:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        value={values.zipcode || ''}
                        type="text"
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('zipcode', event.currentTarget.value)}
                      />
                      {errors.zipcode && touched.zipcode ? <InputMessage kind={InputMessageKind.error}>{errors.zipcode}</InputMessage> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-between p-4 pt-8 pb-6 place-items-stretch">
              <Button kind={ButtonKind.outlinedSecondary} onClick={closeModal}>
                {'Abbrechen'}
              </Button>
              <Button kind={deleting ? ButtonKind.danger : ButtonKind.primary} onClick={handleSubmit} disabled={isSubmitting}>
                {getTextObject().submitButtonText}
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  )
}

export default PersonenModal
