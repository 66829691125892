import { useAuth } from '@aposphaere/core-kit'
import { Button, ButtonKind, InputLabel, InputMessage, InputMessageKind, NotificationBannerKind, TextInput } from '@aposphaere/ui-components'
import { Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { CrmContext, ICrmContext } from '../contexts/crmContext'
import { CrmLogoColor } from '../Icons'
import { START_PAGE_ROUTE } from '../routes'

type LoginFormValues = {
  username: string
  password: string
}

const LoginValidationScheme = Yup.object().shape({
  username: Yup.string().email('Ungültige E-Mail').required('Pflichtfeld'),
  password: Yup.string().min(8, 'Zu kurz').required('Pflichtfeld'),
})

const intialFormValues: LoginFormValues = {
  username: '',
  password: '',
}

const LoginPage: React.FC = () => {
  const auth = useAuth()
  const { presentNotification } = useContext(CrmContext) as ICrmContext
  const history = useHistory()
  const [signInSucessfull, setSignInSucessfull] = useState<boolean>(false)

  useEffect(() => {
    if (signInSucessfull) {
      history.push(START_PAGE_ROUTE)
    }
  })

  const onFormSubmit = async (values: LoginFormValues) => {
    const success: boolean = await auth.signIn(values.username, values.password)
    setSignInSucessfull(success)
    if (!success) {
      presentNotification({
        title: 'Der Login ist fehlgeschlagen. Bitte überprüfen Sie ihre E-Mail und das Passwort',
        content: '',
        kind: NotificationBannerKind.danger,
      })
    }
    return success
  }
  return (
    <div className="w-full h-screen flex flex-wrap overflow-hidden bg-orange-100">
      <div className="bg-white h-64 w-110 transform -rotate-3 -mt-32 px-64 -ml-3" />
      <div className="absolute ml-16 mt-16">
        <img className="h-11 w-auto" src={`${CrmLogoColor}`} alt="aposphäre CRM" />
      </div>
      <div className="flex absolute w-full h-full bg-no-repeat bg-center justify-center items-center">
        <img className="absolute -ml-56 mt-20 animate-spin-slow" src="/login-rotate-1.svg" alt="" />
        <img className="absolute -mr-450 -mt-350 animate-wiggle" src="/login-rotate-2.svg" alt="" />
        <img className="absolute" src="/bg-login.svg" alt="" />
        <Formik initialValues={intialFormValues} onSubmit={onFormSubmit} validationSchema={LoginValidationScheme}>
          {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
            <div
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit()
                }
              }}
              className="flex flex-wrap z-50 w-450px bg-white shadow-2xl rounded-lg h-fit justify-center pt-12 pb-12"
            >
              <h1 className="text-blue-700 text-xl font-medium text-center w-full mb-8">{'Bitte loggen Sie sich ein:'}</h1>
              <div className="w-2/3">
                <InputLabel>{'Username'}</InputLabel>
                <TextInput
                  type="text"
                  value={values.username}
                  onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('username', event.currentTarget.value)}
                />
                {errors.username && touched.username ? <InputMessage kind={InputMessageKind.error}>{errors.username}</InputMessage> : null}
              </div>
              <div className="w-2/3 mt-8">
                <InputLabel>{'Passwort'}</InputLabel>
                <TextInput
                  type="password"
                  value={values.password}
                  onChange={(event: React.FormEvent<HTMLInputElement>) => setFieldValue('password', event.currentTarget.value)}
                />
                {errors.password && touched.password ? <InputMessage kind={InputMessageKind.error}>{errors.password}</InputMessage> : null}
              </div>
              <div className="flex w-full mt-8 justify-center">
                <Button kind={ButtonKind.primary} onClick={handleSubmit} disabled={isSubmitting ? true : false}>
                  {'Anmelden'}
                </Button>
              </div>
            </div>
          )}
        </Formik>
      </div>
      <div className="flex h-64 transform -rotate-2 rounded-xl bg-blue-700 text-white fixed w-110 bottom-0 -mb-40 -ml-4 text-sm font-body py-4 px-8 justify-between" />
      <footer className="flex text-blue-300 fixed w-full bottom-0 text-sm font-body py-6 px-8">
        <div className="w-full flex justify-end">
          <div className="flex pr-6 border-r border-blue-300 ">{'aposphäre - an IQVIA business'}</div>
          <a className="ml-6 mr-6 hover:text-white hover:underline" href="https://www.aposphaere.de/#" target="_blank" rel="noopener noreferrer">
            {'Impressum'}
          </a>
          <a className="mr-6 hover:text-white hover:underline" href="https://www.aposphaere.de/#" target="_blank" rel="noopener noreferrer">
            {'Datenschutz'}
          </a>
          <a className="hover:text-white hover:underline" href="https://www.aposphaere.de/#" target="_blank" rel="noopener noreferrer">
            {'AGB'}
          </a>
        </div>
      </footer>
    </div>
  )
}

export default LoginPage
