import { gql } from 'graphql-request'

export const allNotes = gql`
  {
    notes {
      pharmacy {
        id
      }
      user {
        id
      }
      title
      content
      note_type
      created_at
    }
  }
`
