import React, { FunctionComponent, useRef, useCallback } from 'react'
import { OffTimeMutationVariables } from '../TimeSlot'
import { OfftimeKind } from '../OfftimeLabel'
import DeleteIcon from '../Icons/DeleteIcon'
import { DeleteOfftimeType } from '../TimeSlot/TimeSlot'
export type DayItemProps = {
  selected?: boolean
  onClick?: () => void
  disabled?: boolean
  visits: number
  date: Date
  countOfReminders: number
  children?: React.ReactNode
  id?: string
  onCreateOfftime?: (variables: OffTimeMutationVariables) => void
  isOverView?: boolean
  offtimeType?: OfftimeKind | undefined
  isDayOfftimes?: (date: Date) => { isOffTime: boolean; offTimeId: number } | undefined
  onDeleteOfftime: (variable: DeleteOfftimeType) => void
  showVisits: () => void
}

const DayItem: FunctionComponent<DayItemProps> = ({
  countOfReminders,
  date,
  visits,
  selected,
  onClick,
  children,
  id,
  isOverView,
  offtimeType,
  onCreateOfftime,
  isDayOfftimes,
  onDeleteOfftime,
  showVisits,
}) => {
  const ref = useRef<HTMLDivElement>()
  const getWeekdayName = (dateForWeekDayName: Date) => {
    const weekday = dateForWeekDayName.getDay()
    switch (weekday) {
      case 0:
        return 'So'
      case 1:
        return 'Mo'
      case 2:
        return 'Di'
      case 3:
        return 'Mi'
      case 4:
        return 'Do'
      case 5:
        return 'Fr'
      case 6:
        return 'Sa'
    }
  }

  const weekdayName = getWeekdayName(date)

  const formattedDateString = (dateForFormat: Date | number) => {
    const d = dateForFormat
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
    return `${da}.${mo}`
  }

  const dateString = formattedDateString(date)
  const createdate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9)
  const NoVisits = visits < 1
  const handleOnclick = useCallback(
    (e: React.SyntheticEvent) => {
      const dayOfftimesAlreadyCreated = isDayOfftimes(date)
      if (offtimeType !== undefined && onCreateOfftime && isOverView && !dayOfftimesAlreadyCreated) {
        const variables: OffTimeMutationVariables = {
          offtime_type: offtimeType,
          whole_day: true,
          date: createdate,
        }
        onCreateOfftime(variables)
        return
      }
      onClick()
    },
    [onCreateOfftime, onClick, offtimeType, isDayOfftimes, createdate, date, isOverView],
  )

  const handleDelete = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation()
      const dayOfftimesAlreadyCreated = isDayOfftimes(date)
      if (dayOfftimesAlreadyCreated) {
        onDeleteOfftime({ offtimeId: `${dayOfftimesAlreadyCreated.offTimeId}` })
      }
    },
    [onDeleteOfftime, isDayOfftimes, date],
  )

  return (
    <div
      ref={ref}
      id={id}
      className={`flex day row pt-2 pl-1 2xl:pl-4  last:bg-blue-600 border-blue-400 border-2 rounded-lg border-opacity-0 hover:border-opacity-25 ${
        selected && 'border-opacity-100 shadow'
      }`}
      onClick={(e) => handleOnclick(e)}
    >
      <div className={`flex w-10pc ${selected && 'border-opacity-0'} border-b border-gray-300 ml-1 pb-3 text-base font-body items-center`}>
        <div className={selected ? 'text-blue-700 font-medium' : ''}>
          {weekdayName}
          <br />
          {dateString}
          <br />
          {`${countOfReminders ? `(${countOfReminders})` : ''}`}
        </div>
      </div>
      <div className={`flex flex-wrap w-85pc pb-3 border-b border-gray-300 ${selected && 'border-opacity-0'}`}>{children}</div>
      <div className="text-base w-5pc mr-2">
        <div
          onClick={showVisits}
          className={`flex mt-1 border-b justify-center item-center border-gray-300 ${selected && 'border-opacity-0'} justify-center items-center ${
            NoVisits ? 'text-gray-400' : 'text-white bg-orange-600 cursor-pointer'
          }`}
        >
          {visits}
        </div>
        <button
          onClick={(e) => handleDelete(e)}
          className="flex items-center justify-center h-fit px-0.5 py-0.5 rounded-md text-gray-600 hover:text-red-500 focus:border-gray-300 focus:outline-none transition ease-in-out duration-150"
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  )
}

export default DayItem
