export interface MeResponse {
  me: User
}

export interface UserAddress {
  address_name?: string
  city?: string
  zipcode?: string
  longitude?: number
  latitude?: number
}

interface UserRoles {
  id?: string
  name?: string
  label?: string
  abilities: RolesAbilities[]
}

interface RolesAbilities {
  id?: string
  name: typeof userActions[number]
  label: string
}

export interface User {
  id: number
  name: string
  email: string
  address?: UserAddress
  roles: UserRoles[]
}

export const userActions = [
  'create_pharmacies',
  'edit_pharmacies',
  'read_pharmacies',
  'delete_pharmacies',
  'create_projects',
  'edit_projects',
  'read_projects',
  'delete_projects',
  'create_appointments',
  'edit_appointments',
  'read_appointments',
  'delete_appointments',
  'create_quarters',
  'edit_quarters',
  'read_quarters',
  'delete_quarters',
  'create_tasks',
  'edit_tasks',
  'read_tasks',
  'delete_tasks',
  'create_notes',
  'edit_notes',
  'read_notes',
  'delete_notes',
  'create_offtimes',
  'edit_offtimes',
  'read_offtimes',
  'delete_offtimes',
  'create_regions',
  'edit_regions',
  'read_regions',
  'delete_regions',
  'create_users',
  'edit_users',
  'read_users',
  'delete_users',
] as const
