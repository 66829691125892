import {
  totalAppointmentsInQuarterQuery,
  futureAppointmentsInQuarterQuery,
  pastAppointmentsInQuarterQuery,
  summaryOfAppointmentsInQuarterQuery,
  ApoClient,
  Quarter,
  useAuth,
  SummaryAppointmentsInQuarter,
} from '@aposphaere/core-kit'
import { useState, useContext, useEffect, useCallback, useMemo } from 'react'
import { CrmContext, ICrmContext } from '../contexts/crmContext'
import { IOverviewContext } from '../contexts/overviewContext'

export const useOverviewProvider = (): IOverviewContext => {
  const auth = useAuth()
  const client = useMemo(() => new ApoClient(), [])

  const { selectedQuater } = useContext(CrmContext) as ICrmContext

  const [summaryInfo, setSummaryInfo] = useState<{
    totalAppointmentsInQuarter?: number
    pastAppointmentsInQuarter?: number
    futureAppointmentsInQuarter?: number
    summaryOfAppointmentsInQuarter?: SummaryAppointmentsInQuarter
  }>({
    totalAppointmentsInQuarter: undefined,
    pastAppointmentsInQuarter: undefined,
    futureAppointmentsInQuarter: undefined,
    summaryOfAppointmentsInQuarter: undefined,
  })

  const fetchOverviewInformation = useCallback(
    async (quarter: Quarter) => {
      try {
        const total = await client.requestWithAuthentication<{ totalAppointmentsInQuarter: number }>(totalAppointmentsInQuarterQuery, auth.token, {
          quarter_id: quarter.id,
        })
        const future = await client.requestWithAuthentication<{ futurAppointmentsInQuarter: number }>(futureAppointmentsInQuarterQuery, auth.token, {
          quarter_id: quarter.id,
        })
        const past = await client.requestWithAuthentication<{ pastAppointmentsInQuarter: number }>(pastAppointmentsInQuarterQuery, auth.token, {
          quarter_id: quarter.id,
        })

        const summary = await client.requestWithAuthentication<{ summaryOfAppointmentsInQuarter: SummaryAppointmentsInQuarter }>(
          summaryOfAppointmentsInQuarterQuery,
          auth.token,
          {
            quarter_id: quarter.id,
          },
        )
        if (total.errors || future.errors || past.errors /*|| summary.errors*/) {
          throw new Error(`Couldn't fetch summary info`)
        }
        setSummaryInfo({
          totalAppointmentsInQuarter: total.data?.totalAppointmentsInQuarter,
          pastAppointmentsInQuarter: past.data?.pastAppointmentsInQuarter,
          futureAppointmentsInQuarter: future.data?.futurAppointmentsInQuarter,
          summaryOfAppointmentsInQuarter: summary.data?.summaryOfAppointmentsInQuarter,
        })
      } catch (err) {
        console.debug("Couldn't fetch summary info: ", err)
      }
    },
    [auth.token, client],
  )
  useEffect(() => {
    if (selectedQuater) {
      fetchOverviewInformation(selectedQuater).catch(console.warn)
    }
  }, [fetchOverviewInformation, selectedQuater])

  return {
    summaryInfo,
  }
}
