import React, { useEffect, useState } from 'react'
import { IconButton, IconButtonKind, LogoutIcon } from '@aposphaere/ui-components'
import { useHistory } from 'react-router-dom'
import { useAuth } from '@aposphaere/core-kit'

const SignOutButton: React.FC = () => {
  const [loggedIn, setLoggedIn] = useState(true)
  const history = useHistory()
  const auth = useAuth()

  useEffect(() => {
    if (!loggedIn) {
      auth.signOut()
      history.push('/login')
    }
  })
  return (
    <IconButton
      kind={IconButtonKind.custom}
      icon={<LogoutIcon />}
      additionalCss="-mr-2 ml-4 text-white active:text-white hover:text-white focus:outline-none hover:bg-blue-700 rounded active:bg-blue-800 transition ease-in-out duration-150 "
      onClick={() => setLoggedIn(false)}
    />
  )
}

export default SignOutButton
