import { Badge, BadgeKind, NavBar, TabBar, TabBarItem, TabBarItemKind } from '@aposphaere/ui-components'
import React, { useContext } from 'react'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import PharmacyClusterSwitcher from '../components/PharmacyClusterSwitcher'
import QuarterSwitcher from '../components/QuarterSwitcher'
import SignOutButton from '../components/SignOutButton'
import { CrmContext, ICrmContext, ModalKind } from '../contexts/crmContext'
import { CrmLogo, CrmLogoSmall } from '../Icons'
import { ADDRESSES_PAGE_ROUTE, REMINDERS_PAGE_ROUTE, START_PAGE_ROUTE, OVERVIEW_PAGE_ROUTE } from '../routes'

const MainLayout: React.FC<RouteComponentProps> = ({ children, location }) => {
  const history = useHistory()
  const { onOpenModal } = useContext(CrmContext) as ICrmContext
  const show = location.pathname !== '/login'
  return show ? (
    <div className="flex z-50 flex-wrap font-body">
      <>
        <div className="w-screen relativte z-50 h-14 lg:h-8 py-2 px-2 text-center bg-gray-200 flex justify-center align-middle">
          <div className="text-gray-700 font-medium text-base">
            Sie arbeiten auf einer <strong>Testumgebung</strong>. Wenn Ihnen Fehler auffallen oder Verbesserungswünsche bestehen, dann tragen Sie
            diese{' '}
            <a className="text-bold underline" href="https://forms.gle/jeyy7xEu5iSnpUAg9" target="_blank" rel="noopener noreferrer">
              hier
            </a>{' '}
            ein.
          </div>
        </div>
        <NavBar logoUrl={CrmLogo} logoUrlSmall={CrmLogoSmall} logoAlt="CRM Logo">
          <TabBar>
            <TabBarItem
              kind={TabBarItemKind.primary}
              selected={location.pathname === OVERVIEW_PAGE_ROUTE}
              onClick={() => history.push(OVERVIEW_PAGE_ROUTE)}
            >
              {'Region'}
            </TabBarItem>
            <TabBarItem
              kind={TabBarItemKind.primary}
              selected={location.pathname === START_PAGE_ROUTE}
              onClick={() => history.push(START_PAGE_ROUTE)}
            >
              {'Planung'}
            </TabBarItem>
            <TabBarItem
              kind={TabBarItemKind.primary}
              selected={location.pathname === ADDRESSES_PAGE_ROUTE}
              onClick={() => history.push(ADDRESSES_PAGE_ROUTE)}
            >
              {'Adressen'}
            </TabBarItem>

            <TabBarItem
              kind={TabBarItemKind.primary}
              selected={location.pathname === REMINDERS_PAGE_ROUTE}
              onClick={() => history.push(REMINDERS_PAGE_ROUTE)}
            >
              <CrmContext.Consumer>
                {(value) => (
                  <span>
                    {'Wiedervorlagen'}
                    <Badge kind={BadgeKind.primary}>{value?.remindersHook.reminders?.length}</Badge>
                  </span>
                )}
              </CrmContext.Consumer>
            </TabBarItem>
            <TabBarItem kind={TabBarItemKind.primary} selected={false} onClick={() => onOpenModal(ModalKind.Export)}>
              {'Export'}
            </TabBarItem>
          </TabBar>

          <div className="flex items-center">
            <div className="flex space-x-2 xl:space-x-4">
              <div className="w-32 lg:w-48 2lg:w-64">
                <div className="w-full" />
                <PharmacyClusterSwitcher />
              </div>
              <div>
                <QuarterSwitcher />
              </div>
            </div>
            <div className="border-l border-white border-solid w-px h-10 ml-2 mr-2 xl:ml-8 xl:mr-8" />
            <div className="flex items-center text-white">
              <SignOutButton />
            </div>
          </div>
        </NavBar>
      </>
      {children}
    </div>
  ) : (
    <>{children}</>
  )
}

export default withRouter(MainLayout)
