import { Pharmacy } from '@aposphaere/core-kit'
import Table from 'rc-table'
import React, { useCallback, useContext, useMemo } from 'react'
import { CrmContext, ICrmContext, ModalKind } from '../../contexts/crmContext'
import { determineMapIcon } from '../../pages/StartPage'
import QuickActionsInTable from '../AddressesTable/QuickActionsInTable'
import Spinner from '../loader.gif'

const ActivePharmaciesViewTable: React.FC = () => {
  const {
    setActivePharmacy,
    onOpenModal,
    pharmacies,
    searchFilterHook: {
      filterState: { searchResults },
    },
    toggleAvailableForCallCenter,
    selectedDayHook: { selectedDay },
    routeMap: { sortedAppointmentsToday },
  } = useContext(CrmContext) as ICrmContext

  const addAndSet = useCallback(
    (pharmacy: Pharmacy, kind: ModalKind) => {
      setActivePharmacy(pharmacy)
      onOpenModal(kind)
    },
    [setActivePharmacy, onOpenModal],
  )

  const titleClassName = 'h-11 pb-2 pr-6 py-3 text-gray-600 text-left text-xs font-medium uppercase tracking-wider font-body'

  const columns = [
    {
      title: <div className={`${titleClassName} pl-2`}>{'Apotheke'}</div>,
      width: '65%',
      dataIndex: 'id',
      key: 'data',
      render: (value: string, record: Pharmacy) => (
        <div className=" pr-1 py-4 whitespace-nowrap pl-2 w-18 flex items-center">
          <div className="w-18 pr-2">
            <img src={determineMapIcon(record, sortedAppointmentsToday, selectedDay)} alt="mapMarker" />
          </div>
          <div className="w-full">{`${record.name || ''} - ${record.address.address_name || ''}, ${record.address.zipcode || ''} ${
            record.address.city || ''
          }`}</div>
        </div>
      ),
    },
    {
      title: <div className={`${titleClassName} hidden xl:flex`}>{'Tel.'}</div>,
      width: '10%',
      dataIndex: 'phone',
      key: 'pharmacyName',
      render: (value: string) => <div className={'xl:pr-4 py-2 xl:py-4 whitespace-nowrap hidden xl:flex'}>{value}</div>,
    },
    {
      title: <div className={titleClassName}>{'Quickactions'}</div>,
      width: '25%',
      key: 'quickactions',
      render: (value: undefined, record: Pharmacy) => (
        <QuickActionsInTable pharmacy={record} addAndSet={addAndSet} toggleAvailableForCallCenter={toggleAvailableForCallCenter} />
      ),
    },
  ]

  const data = useMemo(() => (searchResults?.length ? searchResults : pharmacies), [searchResults, pharmacies])
  return data?.length ? (
    <div className="w-full border border-gray-400 sm:rounded-lg relative">
      <div className="absolute h-11 top-0 border-b border-gray-400 w-full" />
      <Table
        className="w-full"
        columns={columns}
        data={data}
        rowClassName={(record, index: number) =>
          `${
            index !== 0 ? 'border-t border-gray-400' : ''
          } text-base font-body hover:text-blue-700 hover:border-white hover:border-b hover:shadow-insettable hover:bg-gray-100`
        }
        tableLayout={'auto'}
        rowKey={(record: Pharmacy) => `${record.id}`}
      />
    </div>
  ) : (
    <div className="w-full h-4/5 bg-white relative flex items-center justify-center">
      <div className="flex items-center justify-center flex-wrap max-w-48">
        <img className="flex w-24" src={Spinner} alt="" />
        <br />
        <div className="w-full text-center mx-auto flex justify-center font-body mt-6 text-base text-blue-400 leading-5">
          {'Daten werden geladen.'}
        </div>
      </div>
    </div>
  )
}

export default ActivePharmaciesViewTable
