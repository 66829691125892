import React from 'react'

interface ISearchInputFilter {
  setFilterByProjectOption: (optionModeActive: boolean) => void
  optionAndActive: boolean
}

const SearchInputFilter: React.FC<ISearchInputFilter> = ({ children, setFilterByProjectOption, optionAndActive }) => {
  const radioStyle =
    'flex self-center appearance-none h-4 w-4 mr-1.5 border border-gray-400 rounded-full cursor-pointer checked:bg-blue-600 checked:border-transparent focus:outline-none'
  return (
    <div className="flex flex-wrap w-full p-4 text-base ">
      <div className="flex flex-wrap w-full items-center">
        <span className="flex font-bold text-blue-600 mr-4">Ansicht Filtern:</span>
        <div className="flex w-64">
          <fieldset className="flex w-full">
            <div className="flex mr-4 cursor-pointer">
              <input
                className={`${radioStyle}`}
                onClick={() => setFilterByProjectOption(true)}
                type="radio"
                id="und"
                name="filter"
                value="and"
                checked={optionAndActive}
              />
              <label className="flex self-center text-gray-700 text-base"> UND</label>
            </div>
            <div className="flex cursor-pointer">
              <input
                className={`${radioStyle}`}
                onClick={() => setFilterByProjectOption(false)}
                type="radio"
                id="oder"
                name="filter"
                value="or"
                checked={!optionAndActive}
              />
              <label className="flex self-center text-gray-700 text-base"> ODER</label>
            </div>
          </fieldset>
        </div>
      </div>
      <div className="mt-4 flex flex-1">
        {/* <TagToggleButton onClick={() => console.log('hallo')} customCSS="mr-2 mb-2" selected={true}>Alle</TagToggleButton>
          <TagToggleButton onClick={() => console.log('hallo')} customCSS="mr-2 mb-2" selected={false}>ApoCampus</TagToggleButton>
          <TagToggleButton onClick={() => console.log('hallo')} customCSS="mr-2 mb-2" selected={false}>ApoCampus</TagToggleButton>
          <TagToggleButton onClick={() => console.log('hallo')} customCSS="mr-2 mb-2" selected={false}>ApoCampus</TagToggleButton>
          <TagToggleButton onClick={() => console.log('hallo')} customCSS="mr-2 mb-2" selected={true}>ApoCampus</TagToggleButton>
          <TagToggleButton onClick={() => console.log('hallo')} customCSS="mr-2 mb-2" selected={true}>ApoCampus</TagToggleButton>
          <TagToggleButton onClick={() => console.log('hallo')} customCSS="mr-2 mb-2" selected={false}>ApoCampus</TagToggleButton>
          <TagToggleButton onClick={() => console.log('hallo')} customCSS="mr-2 mb-2" selected={false}>ApoCampus</TagToggleButton> */}
        {children}
      </div>
    </div>
  )
}

export default SearchInputFilter
