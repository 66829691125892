import React, { FunctionComponent } from 'react'

export enum TabBarItemKind {
  primary = `
    primary px-2 py-2 font-body font-medium
    text-base leading-4 xl:leading-5 rounded-md hover:border-solid
    focus:outline-none text-white hover:bg-blue-700
    focus:text-blue-200 focus:bg-blue-800 focus:outline-none overflow-visible`,

  secondary = `
      secondary px-2 py-2 font-body font-medium text-base
      leading-4 xl:leading-5 rounded-md hover:border-solid focus:outline-none
      text-gray-700 focus:outline-none hover:text-blue-700
      hover:bg-blue-100 active:text-blue-800 active:bg-blue-200 overflow-visible`,
}

export type TabBarItemProps = {
  kind: TabBarItemKind
  selected?: boolean
  children?: React.ReactNode
  onClick?: () => void
}

const composeCss = (selected: boolean, kind: TabBarItemKind) => {
  if (selected && kind === TabBarItemKind.primary) {
    return `
      primary-active px-2 py-2 font-body
      font-medium text-base leading-4 rounded-md xl:leading-5
      focus:text-blue-200 focus:bg-blue-800 focus:outline-none
      overflow-visible text-blue-700 bg-blue-100 focus:outline-none shadow-sm capitalize`
  }

  if (selected && kind === TabBarItemKind.secondary) {
    return `
      secondary-active px-2 py-2
      font-body font-medium text-base
      leading-4 xl:leading-5 rounded-md focus:text-blue-200
      focus:bg-blue-800 focus:outline-none overflow-visible
      text-white bg-blue-600 focus:outline-none shadow-sm capitalize`
  }

  return kind
}

const TabBarItem: FunctionComponent<TabBarItemProps> = ({ kind = TabBarItemKind.primary, onClick, selected, children }) => (
  <a onClick={onClick} className={`cursor-pointer ${composeCss(selected, kind)}`}>
    {children}
  </a>
)

export default TabBarItem
