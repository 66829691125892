import { Pharmacy, useAbilities, userActions } from '@aposphaere/core-kit'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import AddressesTable from '../components/AddressesTable/AddressesTable'
import { CrmContext, ICrmContext, ModalKind } from '../contexts/crmContext'

const AddressesPage: React.FC = () => {
  const prevPharmacyRef = useRef<Pharmacy>()

  const { onOpenModal, setActivePharmacy, currentlyActivePharmacy, toggleAvailableForCallCenter } = useContext(CrmContext) as ICrmContext
  const abilities: Record<typeof userActions[number], boolean> = useAbilities()
  const [toggleKey, setToggleKey] = useState<boolean>(true)
  const prevPharmacy = prevPharmacyRef.current

  useEffect(() => {
    prevPharmacyRef.current = currentlyActivePharmacy
  })

  useEffect(() => {
    if (prevPharmacy && !currentlyActivePharmacy) {
      return setToggleKey((prev) => !prev)
    }
  }, [prevPharmacy, currentlyActivePharmacy])

  useEffect(() => () => setActivePharmacy(undefined), [setActivePharmacy])

  const addAndSet = useCallback(
    (pharmacy: Pharmacy, kind: ModalKind) => {
      setActivePharmacy(pharmacy)
      onOpenModal(kind)
    },
    [setActivePharmacy, onOpenModal],
  )

  const toggleAvailableForCallCenterInTable = useCallback((pharmacy: Pharmacy) => toggleAvailableForCallCenter(false, pharmacy), [
    toggleAvailableForCallCenter,
  ])

  return (
    <div className="flex w-full z-40 h-auto bg-white p-6">
      <AddressesTable
        addAndSet={addAndSet}
        key={String(toggleKey)}
        toggleAvailableForCallCenterInTable={toggleAvailableForCallCenterInTable}
        abilities={abilities}
      />
    </div>
  )
}

export default AddressesPage
