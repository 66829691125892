import { createNote, deleteNote, Note, updateNote, useAuth } from '@aposphaere/core-kit'
import {
  Button,
  ButtonKind,
  InputLabel,
  InputMessage,
  InputMessageKind,
  Modal,
  ModalKind,
  NotificationBannerKind,
  TextAreaInput,
} from '@aposphaere/ui-components'
import { Formik } from 'formik'
import React, { useContext } from 'react'
import * as Yup from 'yup'

import { CrmContext, ICrmContext } from '../../contexts/crmContext'
import { useAuthenticatedMutation } from '../../hooks/useAuthenticatedMutation'

type NoteFormValues = {
  pharmacyId?: number
  typeOfNote: string
  note: string
}

interface INoteModal {
  editing?: boolean
  deletion?: boolean
  creature?: boolean
  note?: Note
}

export enum NoteOptions {
  PhoneNote = 'Telefonnotiz',
  VisitInfo = 'Besuchsinfo',
  GeneralNote = 'allgemeine Notiz',
  Miscellaneous = 'Sonstiges',
}

const NoteModal: React.FC<INoteModal> = ({ deletion = false, editing = false }) => {
  const context = useContext(CrmContext) as ICrmContext
  const auth = useAuth()

  const {
    selectNoteHook: { currentlySelectedNote },
    currentlyActivePharmacy,
    selectPharmacyBranchHook: { pharmacyBranchActive, pharmacyDetails, setPharmacyBranchActive },
  } = context

  const deleteNoteMutation = useAuthenticatedMutation(deleteNote)
  const updateNoteMutation = useAuthenticatedMutation(updateNote)
  const createNoteMutation = useAuthenticatedMutation(createNote)

  const getMutationWithVariables = (values: NoteFormValues) => {
    const defaultVariables = {
      pharmacy_id: values.pharmacyId,
      user_id: auth.user?.id,
      note_type: values.typeOfNote,
      content: values.note,
    }
    const mutation = deletion ? deleteNoteMutation : editing ? updateNoteMutation : createNoteMutation
    const variables = deletion
      ? { id: currentlySelectedNote?.id }
      : editing
      ? {
          ...defaultVariables,
          id: currentlySelectedNote?.id,
        }
      : defaultVariables
    return { mutation, variables }
  }

  const activePharmacy = pharmacyBranchActive ? pharmacyDetails : currentlyActivePharmacy

  const initialFormValues: NoteFormValues = {
    pharmacyId: activePharmacy ? activePharmacy.id : undefined,
    typeOfNote: currentlySelectedNote?.note_type || NoteOptions.PhoneNote,
    note: currentlySelectedNote?.content || '',
  }

  const NoteValidationScheme = Yup.object().shape({
    typeOfNote: Yup.string().required('Pflichtfeld'),
    note: Yup.string().min(3, 'Zu kurz').required('Pflichtfeld'),
  })

  const onFormSubmit = async (values: NoteFormValues) => {
    const { mutation, variables } = getMutationWithVariables(values)

    const noteResponse = await mutation(variables)
    if (noteResponse.errors !== undefined) {
      alert(noteResponse.errors)
      return
    }
    const { pharmacyId = 0 } = values

    await context?.getFreshPharmacyDetails(pharmacyId, pharmacyBranchActive)
    if (pharmacyBranchActive) {
      setPharmacyBranchActive(false)
    }
    const { notificationText } = getTextObject()

    closeModal()
    context.presentNotification({
      title: notificationText,
      content: '',
      kind: NotificationBannerKind.success,
    })
  }

  const closeModal = () => {
    context.onCloseModal()
    context.selectNoteHook.setCurrentlySelectedNote(null)
  }

  const getTextObject = () => {
    if (deletion) {
      return {
        titleModalText: 'Notiz löschen',
        submitButtonText: 'Löschen bestätigen',
        notificationText: 'Notiz erfolgreich gelöscht',
      }
    } else if (editing) {
      return {
        titleModalText: 'Notiz speichern',
        submitButtonText: 'Notiz speichern',
        notificationText: 'Notiz erfolgreich gespeichert',
      }
    }
    return {
      titleModalText: 'Notiz anlegen',
      submitButtonText: 'Notiz anlegen',
      notificationText: 'Notiz erfolgreich erstellt',
    }
  }

  return (
    <Modal kind={ModalKind.sm} title={getTextObject().titleModalText} onClose={closeModal} onBack={() => null}>
      <Formik key="note-creation-form" initialValues={initialFormValues} onSubmit={onFormSubmit} validationSchema={NoteValidationScheme}>
        {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
          <div className="flex flex-wrap w-full">
            <div className="flex flex-wrap w-full">
              <div className="w-full p-4">
                <div className="w-full grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <InputLabel>{'Notizart:'}</InputLabel>
                    <div className="w-full mt-1 rounded-md">
                      <select
                        disabled={deletion}
                        defaultValue={values.typeOfNote}
                        className="w-full form-select font-body h-10 text-gray-900 bg-gray-100 block rounded-md py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400"
                        onChange={(event: React.FormEvent<HTMLSelectElement>) => setFieldValue('typeOfNote', event.currentTarget.value)}
                      >
                        <option>{NoteOptions.VisitInfo}</option>
                        <option>{NoteOptions.PhoneNote}</option>
                        <option>{NoteOptions.GeneralNote}</option>
                        <option>{NoteOptions.Miscellaneous}</option>
                      </select>
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <InputLabel>{'Notiz:'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextAreaInput
                        disabled={deletion}
                        initialValue={values.note}
                        placeholder=""
                        onChange={(event: React.FormEvent<HTMLTextAreaElement>) => setFieldValue('note', event.currentTarget.value)}
                      />
                      {errors.note && touched.note ? <InputMessage kind={InputMessageKind.error}>{errors.note}</InputMessage> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex sticky bg-gradient-to-t from-white via-white to-transparent-opacity-0 self-end bottom-0 w-full justify-between p-4 pt-8 pb-6 place-items-stretch">
              <Button kind={ButtonKind.outlinedSecondary} onClick={closeModal}>
                {'Abbrechen'}
              </Button>
              <Button kind={deletion ? ButtonKind.danger : ButtonKind.primary} onClick={handleSubmit} disabled={isSubmitting}>
                {getTextObject().submitButtonText}
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  )
}

export default NoteModal
