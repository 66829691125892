import { useAbilities} from '@aposphaere/core-kit'
import { IconButton, IconButtonKind, MaximizeIcon, TabBarItem, TabBarItemKind } from '@aposphaere/ui-components'
import { useLoadScript } from '@react-google-maps/api'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AppointmentsCard from '../components/AppointmentsCard'
import Spinner from '../components/loader.gif'
import { googleMapsApiKey, MapMarkers } from '../components/Map'
import SimpleGoogleMap from '../components/Map/SimpleGoogleMap'
import NotesCard from '../components/NotesCard'
import { AppointmentSummaryCardWithSwitcher } from '../components/AppointmentSummaryCardWithSwitcher'
import { CrmContext, ICrmContext, ModalKind } from '../contexts/crmContext'
import { IOverviewContext, OverviewContext } from '../contexts/overviewContext'
import { START_PAGE_ROUTE } from '../routes'
import { determineMapIcon } from './StartPage'
import { IActivitiesType, IAppointmentsPerProjectType } from './OverviewPageTypes'

const OverviewPage = (): JSX.Element => {
  const {
    appointmentHook: { setCurrentAppointment, currentlySelectedAppointment },
    selectReminderHook: { currentlySelectedReminder },
    appointments,
    remindersHook: { reminders },
    onOpenModal,
    setActivePharmacy,
    pharmacies,
    onCloseModal,
    selectedPharmacies,
    currentlyOpenedModalKind,
    projects,
    selectedQuater,
    getFreshActivities,
    getFreshAppointmentsPerProject,
  } = useContext(CrmContext) as ICrmContext

  const [selectedProjectIndex, setSelectedProjectIndex] = useState<number>(0)

  const [activities, setActivities] = useState<IActivitiesType>()

  const [appointmentsPerProject, setAppointmentsPerProject] = useState<IAppointmentsPerProjectType>()

  useEffect(() => {
    const loadActivities = async () => {
      if (selectedQuater) {
        const result = await getFreshActivities(selectedQuater?.id)
        setActivities(result)
      }
    }

    const loadAppointmentsPerProject = async () => {
      if (selectedQuater && projects) {
        const result = await getFreshAppointmentsPerProject(selectedQuater?.id, projects[selectedProjectIndex].id)
        setAppointmentsPerProject(result)
      }
    }
    loadActivities()
    loadAppointmentsPerProject()
  }, [selectedQuater, selectedProjectIndex, projects, getFreshActivities, getFreshAppointmentsPerProject])

  const {
    summaryInfo: { futureAppointmentsInQuarter, pastAppointmentsInQuarter, totalAppointmentsInQuarter, summaryOfAppointmentsInQuarter },
  } = useContext(OverviewContext) as IOverviewContext

  const history = useHistory()
  const abilities = useAbilities()

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
  })

  const futureAppointments = useMemo(
    () =>
      appointments?.filter((appointment) => {
        if (!appointment) {
          return true
        }

        if (!appointment.date) {
          return true
        }

        return new Date(appointment.date.toString().replace(/\s/, 'T')) > new Date()
      }),
    [appointments],
  )

  useEffect(() => {
    if (currentlySelectedAppointment) {
      onOpenModal(ModalKind.Appointment)
      setActivePharmacy(pharmacies?.find((pharmacy) => pharmacy.id === currentlySelectedAppointment.pharmacy?.id))
    } else if (currentlySelectedReminder) {
      setActivePharmacy(pharmacies?.find((pharmacy) => pharmacy.id === currentlySelectedReminder.pharmacy?.id))
    } else {
      setActivePharmacy()
      if (currentlyOpenedModalKind !== ModalKind.Export) {
        onCloseModal()
      }
    }
  }, [currentlySelectedAppointment, currentlySelectedReminder, onCloseModal, onOpenModal, pharmacies, setActivePharmacy, currentlyOpenedModalKind])

  return pharmacies && isLoaded ? (
    <div className="flex w-full flex-wrap z-40 h-auto bg-white p-6">
      <div className="flex w-full py-2">
        <h1 className={`text-xl text-blue-700 leading-7 font-medium`}>{'Übersicht zu Ihrer Region'}</h1>
      </div>
      <div className="flex w-full">
        <div className="flex flex-wrap w-1/2 lg:w-2/5 mr-4 2xl:mr-8 h-fit">
          <div className="w-full">
            <AppointmentsCard
              projects={projects || []}
              showAllEntries={true}
              title="Anstehende Termine"
              setCurrentAppointment={setCurrentAppointment}
              canEditDelete={abilities.edit_appointments}
              appointments={(pharmacies && futureAppointments) || []}
            />
          </div>
          <div className="-mt-4 w-full">
            <NotesCard reminders={reminders || []} showAddButton={false} />
          </div>
        </div>
        <div className="flex flex-wrap w-1/2 lg:w-3/5 h-fit">
          <div className="h-fit relative w-full bg-white overflow-hidden rounded-md border border-solid border-gray-400 my-6 font-body">
            <div className="bg-white px-4 py-2 border-b border-gray-400 sm:pl-4 sm:pr-4 flex justify-between">
              <div className="flex w-full items-center">
                <h3 className="flex text-base leading-6 font-medium text-gray-700">{'Zusammenfassung aller Aktivitäten im Quartal'}</h3>
              </div>
            </div>
            <div className="flex w-full flex-wrap items-stretch min-h-150 p-1 ">
              <div className="flex flex-wrap bg-blue-50 w-1/2 lg:w-1/3 xl:w-1/3 p-4 pb-6 rounded border-2 border-white ">
                <span className="block h-10 text-sm font-medium leading-4 text-gray-700 tracking-wide uppercase w-full">{'Vergangene Termine'}</span>
                <div className="flex flex-row items-center">
                  <span className="flex w-1/2 text:base lg:text-xl xl:text-2xl font-medium text-blue-600 self-center">
                    {pastAppointmentsInQuarter}
                  </span>
                  <div className="flex flex-col ml-8 whitespace-no-wrap">
                    <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${activities?.past_appointments} Termine`}</span>
                    <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${activities?.past_visits} Besuche`}</span>
                    <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${activities?.past_average_appointments_per_day} Durchschnittliche Termine pro Tag`}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap bg-blue-50 w-1/2 lg:w-1/3 xl:w-1/3 p-4 pb-6 rounded border-2 border-white ">
                <span className="block h-10 text-sm font-medium leading-4 text-gray-700 tracking-wide uppercase w-full">{'Avisierte Termine'}</span>
                <div className="flex flex-row items-center">
                  <span className="flex w-1/2 text:base lg:text-xl xl:text-2xl font-medium text-blue-600 self-center">
                    {futureAppointmentsInQuarter}
                  </span>
                  <div className="flex flex-col ml-8 whitespace-no-wrap">
                    <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${activities?.future_appointments} Termine`}</span>
                    <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${activities?.future_visits} Besuche`}</span>
                    <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${activities?.future_average_appointments_per_day} Durchschnittliche Termine pro Tag`}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap bg-blue-50 w-1/2 lg:w-1/3 xl:w-1/3 p-4 pb-6 rounded border-2 border-white ">
                <span className="block h-10 text-sm font-medium leading-4 text-gray-700 tracking-wide uppercase w-full">
                  {'Gesamtanzahl Termine'}
                </span>
                <div className="flex flex-row items-center">
                  <span className="flex w-1/2 text:base lg:text-xl xl:text-2xl font-medium text-blue-600 self-center">
                    {totalAppointmentsInQuarter}
                  </span>
                  <div className="flex flex-col ml-8 whitespace-no-wrap">
                    <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${
                      Number(activities?.past_appointments) + Number(activities?.future_appointments)
                    } Termine`}</span>
                    <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${
                      Number(activities?.past_visits) + Number(activities?.future_visits)
                    } Besuche`}</span>
                    <span className="text-xxs whitespace-nowrap font-medium text-gray-700">{`${
                      Number(activities?.past_average_appointments_per_day) + Number(activities?.future_average_appointments_per_day)
                    } Durchschnittliche Termine pro Tag`}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {summaryOfAppointmentsInQuarter?.projects && summaryOfAppointmentsInQuarter?.projects.length > 0 && appointmentsPerProject && (
            <AppointmentSummaryCardWithSwitcher
              projects={summaryOfAppointmentsInQuarter?.projects}
              selectedProjectIndex={selectedProjectIndex}
              setSelectedProjectIndex={setSelectedProjectIndex}
              appointmentsPerProject={appointmentsPerProject}
            />
          )}
          <div className="relative w-full bg-white overflow-hidden rounded-md border border-solid border-gray-400 mt-2 mb-6 font-body">
            <div className="bg-white px-4 py-2 border-b border-gray-400 sm:pl-4 sm:pr-4 flex justify-between">
              <div className="flex w-full items-center py-2">
                <h3 className="flex text-base leading-6 font-medium text-gray-700">{'Ihre Region'}</h3>
                <span className="block pl-2 text:base lg:text-xl xl:text-2xl">
                  <TabBarItem kind={TabBarItemKind.secondary} selected={true}>
                    {`${pharmacies.length} Standorte`}
                  </TabBarItem>
                </span>
              </div>
              <IconButton
                kind={IconButtonKind.custom}
                selected={false}
                icon={<MaximizeIcon />}
                additionalCss="mx-2 xl:my-1.5 focus:outline-none text-gray-700 hover:bg-gray-300 hover:text-blue-800 rounded
                p-1 active:bg-blue-400 active:text-white transition ease-in-out duration-150 "
                onClick={() => history.push(START_PAGE_ROUTE)}
              />
            </div>
            <SimpleGoogleMap
              containerStyle={{
                width: '100%',
                height: '37vh',
              }}
            >
              <MapMarkers
                pharmacies={pharmacies}
                searchResults={[]}
                showInputSearchFilter={false}
                selectedPharmacies={selectedPharmacies}
                determineMapIcon={determineMapIcon}
              />
            </SimpleGoogleMap>
          </div>
        </div>
      </div>
    </div>
  ) : loadError ? (
    <span>{loadError}</span>
  ) : (
    <div className="w-full h-full bg-white flex fixed items-center justify-center">
      <div className="flex items-center justify-center flex-wrap max-w-48">
        <img className="flex w-24 -mt-16" src={Spinner} alt="" />
        <br />
        <div className="w-full text-center mx-auto flex justify-center font-body mt-4 text-base text-blue-400 leading-5">
          {'Daten werden geladen.'}
        </div>
      </div>
    </div>
  )
}

export default OverviewPage
