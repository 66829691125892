import { RequestDocument, Variables } from 'graphql-request/dist/types'
import { ApoClient } from '../graphql/client'
import { Response } from '../graphql/response'

/**
 * A custom Hook that sends data from the GraphQL API
 * @param mutation The provided GraphQL Mutation
 * @param variables Optional variables corresponding to the Qurey
 * @returns A function that executes the query and returns a Promise<Response<ModelType>>
 */
export const useMutation = <ModelType>(query: RequestDocument, headers?: Headers): ((variables: Variables) => Promise<Response<ModelType>>) => {
  const client = new ApoClient()

  if (headers) {
    client.setHeaders(headers)
  }
  const queryData = async (variables: Variables) => {
    const queryResponse = await client.request<ModelType>(query, variables)
    return queryResponse
  }
  return queryData
}
