import { Project } from '@aposphaere/core-kit'

export interface IOnMapFilterState {
  selectedProjects: Project[]
  optionAndActive: boolean
  onActiveProject: boolean
  onPriority: boolean
  onPotential: boolean
}

export interface IOnMapFilterAction {
  type: IOnMapFilterType
  payload?: {
    selectedProjects?: Project[]
    optionAndActive?: boolean
  }
}

type IOnMapFilterType = 'onActiveProject' | 'setSelectedProjects' | 'clear' | 'onPriority' | 'onPotential' | 'setAndOption'

export const filterOnMapReducer = <S extends IOnMapFilterState, A extends IOnMapFilterAction>(state: S, action: A): IOnMapFilterState => {
  switch (action.type) {
    case 'clear':
      return {
        ...initialOnMapFilterState,
      }
    case 'onActiveProject':
      return {
        ...state,
        onActiveProject: !state.onActiveProject,
      }
    case 'onPriority':
      return {
        ...state,
        onPriority: !state.onPriority,
      }
    case 'onPotential':
      return {
        ...state,
        onPotential: !state.onPotential,
      }
    case 'setAndOption':
      return {
        ...state,
        optionAndActive: action.payload?.optionAndActive || false,
      }
    case 'setSelectedProjects':
      return {
        ...state,
        selectedProjects: action.payload?.selectedProjects || [],
      }
    default:
      return { ...state }
  }
}

export const initialOnMapFilterState: IOnMapFilterState = {
  selectedProjects: [],
  onActiveProject: false,
  onPriority: false,
  onPotential: false,
  optionAndActive: false,
}
