import React, { FunctionComponent } from 'react'
import InputMessage, { InputMessageKind } from '../InputMessage'

export type TextInputProps = {
  type: 'text' | 'password' | 'email' | 'tel'
  value: string
  onChange: (event: React.FormEvent<HTMLInputElement>) => void
  disabled?: boolean
  placeholder?: string
  message?: string
  valid?: boolean
  name?: string
  id?: string
}

const TextInput: FunctionComponent<TextInputProps> = ({ disabled, message, name, id, valid, placeholder, value, type = 'text', onChange }) => {
  const disabledClass = disabled ? 'opacity-50 hover:shadow-none' : 'hover:shadow-focus'
  return (
    <>
      <div className={`mt-1 rounded-md w-full ${disabledClass}`}>
        <input
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          name={name}
          id={id}
          onChange={onChange}
          type={type}
          className={`form-input font-body block w-full h-10
                      bg-gray-100 rounded-md px-4 py-2 text-base
                      leading-6 border outline-none focus:shadow-focus
                      focus:border-4 border-solid border-gray-400 focus:border-blue-400
                      sm:text-base sm:leading-5`}
        />
      </div>
      {message ? <InputMessage kind={valid === false ? InputMessageKind.error : InputMessageKind.info}>{message}</InputMessage> : null}
    </>
  )
}

export default TextInput
