import React, { FunctionComponent, useMemo, useCallback } from 'react'

export enum OfftimeKind {
  HomeOffice = 'Home Office (blau)',
  Urlaub = 'Urlaub (grün)',
  Privat = 'Privat (Rosa)',
  Tagung = 'Tagung (gelb)',
  Krank = 'Krank (rot)',
}

export enum OfftimeColorCode {
  HomeOffice = 'bg-blue-700 hover:border hover:border-black',
  Urlaub = 'bg-green-900 hover:border hover:border-black',
  Privat = 'bg-pink-600 hover:border hover:border-black',
  Tagung = 'bg-yellow-900 hover:border hover:border-black',
  Krank = 'bg-red-800 hover:border hover:border-black',
}

export type OfftimeLabelProps = {
  kind: string
  labelInfo: string
  onClick: React.Dispatch<React.SetStateAction<OfftimeKind | undefined>>
  disabled?: boolean
  offtimeType?: string
}

const OfftimeLabel: FunctionComponent<OfftimeLabelProps> = ({ disabled, kind, labelInfo, onClick, offtimeType }) => {
  const handleOnClick = useCallback(() => {
    if (kind === offtimeType) {
      onClick(undefined)
    } else {
      onClick(kind as OfftimeKind)
    }
  }, [kind, offtimeType, onClick])

  const generateVacationKeyKind = useMemo((): OfftimeColorCode => {
    switch (kind) {
      case 'Home Office (blau)':
        return OfftimeColorCode.HomeOffice
      case 'Privat (Rosa)':
        return OfftimeColorCode.Privat
      case 'Tagung (gelb)':
        return OfftimeColorCode.Tagung
      case 'Urlaub (grün)':
        return OfftimeColorCode.Urlaub
      case 'Krank (rot)':
        return OfftimeColorCode.Krank
      default:
        return undefined
    }
  }, [kind])
  const selected = offtimeType === kind
  return (
    <div
      onClick={() => handleOnClick()}
      className={`flex justify-center text-white capitalize items-center text-center font-body text-base cursor-pointer w-18pc border-solid m-1 rounded py-0.5 transition duration-300 ease-in-out hover:shadow-md ${generateVacationKeyKind} ${
        selected ? 'border-2 border-black shadow-md py-1.5' : ''
      }`}
    >
      {labelInfo}
    </div>
  )
}

export default OfftimeLabel
