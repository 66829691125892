import React from 'react'
import MapMarker, { MapMarkerColor } from '../MapMarker'

const SearchInputMarkerInfo: React.FC = () => {
  const colStyle = 'flex flex-wrap w-1/3 h-fit'
  const rowStyle = 'flex w-full my-3 mr-10 gap-2'
  const markerStyle = 'transform scale-65 -mt-2 -ml-2'
  return (
    <div className="flex w-full p-6 pt-4 align-top text-base">
      <div className={`${colStyle}`}>
        <div className={`${rowStyle}`}>
          <div className="w-1/12 h-6">
            <MapMarker customCSS={`${markerStyle}`} color={MapMarkerColor.gray} shaded={false} letter="" />
          </div>
          <div className="w-11/12">Die Adresse wurde im ausgewähltem Quartal noch nicht behandelt.</div>
        </div>
        <div className={`${rowStyle}`}>
          <div className="w-1/12 h-6">
            <MapMarker customCSS={`${markerStyle}`} color={MapMarkerColor.green} shaded={false} letter="" />
          </div>
          <div className="w-11/12">Eine Adresse mit avisierten Termin im ausgewählten Quartal.</div>
        </div>
        <div className={`${rowStyle}`}>
          <div className="w-1/12 h-6">
            <MapMarker customCSS={`${markerStyle}`} color={MapMarkerColor.green} shaded={true} letter="" />
          </div>
          <div className="w-11/12">Eine Adresse, bei der der Quartalstermin bereits in der Vergangenheit liegt (geschulte Apotheke).</div>
        </div>
        <div className={`${rowStyle}`}>
          <div className="w-1/12 h-6">
            <MapMarker customCSS={`${markerStyle}`} color={MapMarkerColor.yellow} shaded={false} letter="" />
          </div>
          <div className="w-11/12">Eine Adresse mit einer aktiven Wiedervorlage.</div>
        </div>
        <div className={`${rowStyle}`}>
          <div className="w-1/12 h-6">
            <MapMarker customCSS={`${markerStyle}`} color={MapMarkerColor.purple} shaded={false} letter="" />
          </div>
          <div className="w-11/12">Eine Adresse mit avisiertem Remote-Schulungs-Termin im aktuell ausgewählten Quartal.</div>
        </div>
      </div>
      <div className={`${colStyle}`}>
        <div className={`${rowStyle}`}>
          <div className="w-1/12 h-6">
            <MapMarker customCSS={`${markerStyle}`} color={MapMarkerColor.blue} shaded={false} letter="" />
          </div>
          <div className="w-11/12">Eine Adresse mit offenem Besuchstermin im aktuell ausgewählten Quartal.</div>
        </div>
        <div className={`${rowStyle}`}>
          <div className="w-1/12 h-6">
            <MapMarker customCSS={`${markerStyle}`} color={MapMarkerColor.blue} shaded={true} letter="" />
          </div>
          <div className="w-11/12">Eine Adresse mit bereits vergangenem Besuchstermin im aktuell ausgewählten Quartal.</div>
        </div>
        <div className={`${rowStyle}`}>
          <div className="w-1/12 h-6">
            <MapMarker customCSS={`${markerStyle}`} color={MapMarkerColor.red} shaded={false} letter="X" />
          </div>
          <div className="w-11/12 break-words">Gesperrte/Deaktivierte Adresse</div>
        </div>
      </div>
      <div className={`${colStyle}`}>
        <div className={`${rowStyle}`}>
          <div className="w-1/12 font-bold">A</div>
          <div className="w-11/12">Eine Apotheke mit mehr als 10 Schulungsthemen seit dem Aposphäre-Start.</div>
        </div>
        <div className={`${rowStyle}`}>
          <div className="w-1/12 font-bold">B</div>
          <div className="w-11/12">Eine Apotheke mit 5-7 Schulungsthemen seit Aposphäre-Start.</div>
        </div>
        <div className={`${rowStyle}`}>
          <div className="w-1/12 font-bold">C</div>
          <div className="w-11/12">Eine Apotheke mit 8-10 Schulungsthemen seit Aposphäre-Start.</div>
        </div>
        <div className={`${rowStyle}`}>
          <div className="w-1/12 font-bold">D</div>
          <div className="w-11/12">Eine Apotheke mit weniger als 5 geschulten Schulungsthemen seit Aposphäre-Start.</div>
        </div>
        <div className={`${rowStyle}`}>
          <div className="w-1/12 font-bold">R</div>
          <div className="w-11/12">
            Für Reaktiviertes Potenzial - Apotheke hatte bereits abgesagt wurde jedoch von der Steuerungsebene Reaktiviert.
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchInputMarkerInfo
