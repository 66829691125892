import { Pharmacy, Appointment, formattedDateString } from '@aposphaere/core-kit'
import React, { useContext, useEffect } from 'react'
import Map, { CustomDirectionsRenderer, MapMarkers } from '../components/Map'
import customMarker, { CustomMarkerColors } from '../components/Map/customMarker'
import InfoWindowContainer from '../components/Map/InfoWindowContainer'
import { CrmContext, ICrmContext } from '../contexts/crmContext'
import { PharmacyStatus } from '../components/DeactivatePharmacyModal'
export const determineMapIcon = (pharmacy: Pharmacy, sortedAppointmentsToday: Appointment[] | null, selectedDay?: Date | undefined): string => {
  const blockedColor = CustomMarkerColors.red
  const isBlocked = pharmacy.available_for_callcenter === false

  if (pharmacy.pharmacy_status === PharmacyStatus.Inactive) {
    return customMarker({
      selected: false,
      color: CustomMarkerColors.bordeauxRed,
      shaded: false,
      letter: pharmacy.computed_label ? pharmacy.computed_label : '',
    })
  }

  const isVisitToday = () => {
    if (sortedAppointmentsToday && sortedAppointmentsToday.length > 0) {
      const pharmacyVisits = sortedAppointmentsToday.filter((app) => app.pharmacy?.id === pharmacy.id && `${app.appointmentType?.id || 0}` === '4')
      if (pharmacyVisits.length && selectedDay) {
        const visitsToday = pharmacyVisits.filter((v) => formattedDateString(new Date(v.date || '')) === formattedDateString(selectedDay))
        console.log({ visitsToday })
        return Boolean(visitsToday.length)
      }
      return false
    }
    return false
  }
  if (isVisitToday()) {
    return customMarker({
      selected: false,
      color: CustomMarkerColors.orange,
      shaded: false,
      letter: pharmacy.computed_label ? pharmacy.computed_label : '',
    })
  }

  switch (pharmacy.computed_state) {
    case 'not_served':
      return customMarker({
        selected: false,
        color: isBlocked ? blockedColor : CustomMarkerColors.gray,
        shaded: false,
        letter: pharmacy.computed_label ? pharmacy.computed_label : '',
      })
    case 'executed_appointment':
      return customMarker({
        selected: false,
        color: isBlocked ? blockedColor : CustomMarkerColors.green,
        shaded: true,
        letter: pharmacy.computed_label ? pharmacy.computed_label : '',
      })
    case 'planned_appointment':
      return customMarker({
        selected: false,
        color: isBlocked ? blockedColor : CustomMarkerColors.green,
        shaded: false,
        letter: pharmacy.computed_label ? pharmacy.computed_label : '',
      })
    case 'follow_up':
      return customMarker({
        selected: false,
        color: isBlocked ? blockedColor : CustomMarkerColors.yellow,
        shaded: false,
        letter: pharmacy.computed_label ? pharmacy.computed_label : '',
      })
    case 'remote_appointment':
      return customMarker({
        selected: false,
        color: isBlocked ? blockedColor : CustomMarkerColors.purple,
        shaded: false,
        letter: pharmacy.computed_label ? pharmacy.computed_label : '',
      })
    case 'executed_visit':
      return customMarker({
        selected: false,
        color: isBlocked ? blockedColor : CustomMarkerColors.black,
        shaded: true,
        letter: pharmacy.computed_label ? pharmacy.computed_label : '',
      })
    case 'planned_visit':
      return customMarker({
        selected: false,
        color: isBlocked ? blockedColor : CustomMarkerColors.black,
        shaded: false,
        letter: pharmacy.computed_label ? pharmacy.computed_label : '',
      })
    case 'deactivated':
      return customMarker({
        selected: false,
        color: isBlocked ? blockedColor : CustomMarkerColors.red,
        shaded: false,
        letter: pharmacy.computed_label ? pharmacy.computed_label : '',
      })
    default:
      return ''
  }
}

const StartPage: React.FC = () => {
  const crmContext = useContext(CrmContext) as ICrmContext

  const {
    setSelectedPharmacyId,
    selectedPharmacyId,
    setActivePharmacy,
    pharmacies,
    routeMap: { sortedAppointmentsToday, pharmaciesHasAppointmentsToday },
    routeHook: { setRouteResponse, routeResponse },
    selectPharmacyBranchHook: { selectedPharmacyBranchId, clear },
    currentUserAddress,
    selectedPharmacies,
    searchFilterHook: {
      filterState: { searchResults, showInputSearchFilter },
    },
    setLeftSideBoardStatus,
  } = crmContext
  useEffect(() => clear, [clear])
  console.log({
    pharmacies: pharmaciesHasAppointmentsToday,
  })
  return (
    <div className="flex w-full bg-transparent z-40  h-full-sidebar">
      <Map>
        <MapMarkers
          pharmacies={sortedAppointmentsToday && pharmaciesHasAppointmentsToday ? pharmaciesHasAppointmentsToday : pharmacies}
          searchResults={searchResults}
          showInputSearchFilter={showInputSearchFilter}
          selectedPharmacies={selectedPharmacies}
          setSelectedPharmacyId={setSelectedPharmacyId}
          determineMapIcon={determineMapIcon}
        />
        {sortedAppointmentsToday && (
          <CustomDirectionsRenderer
            sortedAppointmentsToday={sortedAppointmentsToday}
            setRouteResponse={setRouteResponse}
            routeResponse={routeResponse}
            origin={currentUserAddress}
          />
        )}
        {selectedPharmacyId && selectedPharmacyBranchId ? (
          <>
            <InfoWindowContainer
              branch
              key={Date.now() + 1}
              pharmacyId={selectedPharmacyBranchId}
              onClose={() => {
                clear()
                setLeftSideBoardStatus('calendar')
                setActivePharmacy(undefined)
                setSelectedPharmacyId(undefined)
              }}
            />
          </>
        ) : selectedPharmacyId ? (
          <InfoWindowContainer
            branch={false}
            key={selectedPharmacyId}
            pharmacyId={selectedPharmacyId}
            onClose={() => {
              clear()
              setLeftSideBoardStatus('calendar')
              setActivePharmacy(undefined)
              setSelectedPharmacyId(undefined)
            }}
          />
        ) : selectedPharmacyBranchId ? (
          <InfoWindowContainer
            branch
            key={selectedPharmacyBranchId}
            pharmacyId={selectedPharmacyBranchId}
            onClose={() => {
              clear()
              setLeftSideBoardStatus('calendar')
              setActivePharmacy(undefined)
              setSelectedPharmacyId(undefined)
            }}
          />
        ) : null}
      </Map>
    </div>
  )
}

export default StartPage
