import { gql } from 'graphql-request'

export const allStatuses = gql`
  {
    statuses {
      id
      label
      label_short
      is_active
    }
  }
`
