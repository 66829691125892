import React, { FunctionComponent, useEffect, useState } from 'react'

export enum InputSearchPanelKind {
  overlay = 'bg-white rounded-md shadow-lg box-border flex overflow-hidden flex-wrap',
  flat = 'bg-white rounded-md box-border border-solid border border-gray-300 flex overflow-hidden flex-wrap',
}

export type InputSearchPanelProps = {
  kind: InputSearchPanelKind
  children?: React.ReactNode
  placeholderText?: string
  expandedContent?: React.ReactNode
  onType?: (data: string) => void
  selectedPharmacy?: string
  isListViewedActivePharmaciesTable?: boolean
  handleOnBlur?: () => void
  handleOnClick?: () => void
}

const InputSearchPanel: FunctionComponent<InputSearchPanelProps> = ({
  isListViewedActivePharmaciesTable,
  kind = InputSearchPanelKind.overlay,
  children,
  expandedContent,
  placeholderText,
  onType,
  selectedPharmacy = '',
  handleOnBlur,
  handleOnClick,
}) => {
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    setInputValue(selectedPharmacy)
  }, [selectedPharmacy])

  return (
    <div className={`${kind}`}>
      <div className={`flex w-full ${isListViewedActivePharmaciesTable ? '' : 'flex-col'} 2lg:flex-row`}>
        <div className="flex items-center input w-full">
          <input
            value={inputValue}
            className="box-border form-input font-body block w-full h-12 xl:my-1 xl:mx-1 bg-white px-4 py-2 text-base leading-6 rounded-none border-r border-t-0 border-b-0 border-l-0 focus:outline-none focus:shadow-none focus:bg-blue-50 border-gray-300 focus:border-gray-300 sm:text-base sm:leading-5"
            placeholder={placeholderText}
            onChange={(e) => {
              setInputValue(e.target.value)
              onType(e.target.value)
            }}
            onClick={handleOnClick}
            onBlur={handleOnBlur}
          />
        </div>
        <div className="flex controls bg-white text-gray-700 items-center">{children}</div>
      </div>
      <div className="flex w-full extend border-solid border-t">{expandedContent}</div>
    </div>
  )
}

export default InputSearchPanel
