import React, { FunctionComponent } from 'react'
import InputMessage, { InputMessageKind } from '../InputMessage'

export type TextAreaInputProps = {
  name?: string
  maxlength?: number
  required?: boolean
  onChange: (event: React.FormEvent<HTMLTextAreaElement>) => void
  disabled?: boolean
  placeholder?: string
  message?: string
  valid?: boolean
  initialValue?: string
}

const TextAreaInput: FunctionComponent<TextAreaInputProps> = ({
  disabled,
  initialValue = '',
  message,
  valid,
  placeholder,
  maxlength,
  name,
  required,
  onChange,
}) => {
  const disabledClass = disabled ? 'opacity-50 hover:shadow-none' : 'hover:shadow-focus'
  return (
    <>
      <div className={`mt-1 relative rounded-md w-full ${disabledClass}`}>
        <textarea
          defaultValue={initialValue}
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          onChange={onChange}
          maxLength={maxlength}
          required={required}
          className={`form-input font-body block w-full h-24
                      bg-gray-100 rounded-md px-4 py-2 text-base
                      leading-6 border outline-none focus:shadow-focus
                      focus:border-4 border-solid border-gray-400 focus:border-blue-400
                      sm:text-base sm:leading-5`}
        />
      </div>
      {message ? <InputMessage kind={valid === false ? InputMessageKind.error : InputMessageKind.info}>{message}</InputMessage> : <></>}
    </>
  )
}

export default TextAreaInput
