import React, { createContext } from 'react'
import { useOverviewProvider } from '../hooks/useOverviewProvider'
import { SummaryAppointmentsInQuarter } from '@aposphaere/core-kit'
/**
 * Definition for the Overview Context
 */
export interface IOverviewContext {
  /**
   * The overview information
   */
  summaryInfo: {
    totalAppointmentsInQuarter?: number
    pastAppointmentsInQuarter?: number
    futureAppointmentsInQuarter?: number
    summaryOfAppointmentsInQuarter?: SummaryAppointmentsInQuarter
  }
}
export const OverviewContext = createContext<IOverviewContext | null>(null)

const OverviewProvider: React.FC = ({ children }) => {
  const overviewData = useOverviewProvider()
  return <OverviewContext.Provider value={overviewData}>{children}</OverviewContext.Provider>
}

export default OverviewProvider
