import { Pharmacy, useAbilities } from '@aposphaere/core-kit'
import {
  DeactivateIcon,
  EditIcon,
  IconButton,
  IconButtonKind,
  LocationProfileIcon,
  LockedForCallsIcon,
  NotificationBannerKind,
  PlusSignIcon,
  ProjectstatusIcon,
  ReminderIcon,
} from '@aposphaere/ui-components'
import { useGoogleMap } from '@react-google-maps/api'
import moment from 'moment'
import React, { useContext } from 'react'
import { CrmContext, ICrmContext, ModalKind } from '../../contexts/crmContext'

type QuickActionProps = {
  pharmacy: Pharmacy
  branch?: boolean
}

const QuickActions: React.FunctionComponent<QuickActionProps> = ({ pharmacy, branch }) => {
  const {
    onOpenModal,
    onOpenPharmacyDetails,
    setSelectedPharmacyId,
    selectPharmacyBranchHook: { setPharmacyBranchActive, pharmacyBranchActive, selectedPharmacyBranchId },
    toggleAvailableForCallCenter,
    setLeftSideBoardStatus,
    currentlyActivePharmacy,
    zoomToLocation,
    presentNotification,
    nextPossibleDate,
  } = useContext(CrmContext) as ICrmContext

  const abilities = useAbilities()

  const mapInstance = useGoogleMap() as { fitBounds: (arg0: any) => void }

  const switchActivePharmacy = () => {
    if (!pharmacyBranchActive) {
      setPharmacyBranchActive(true)
    }
  }
  const onCreateAppointment = () => {
    if (currentlyActivePharmacy?.address?.longitude && currentlyActivePharmacy?.address?.latitude) {
      const locationObject = {
        longitude: currentlyActivePharmacy.address.longitude || 0,
        latitude: currentlyActivePharmacy.address.latitude || 0,
      }
      zoomToLocation(locationObject, mapInstance)
    }
    if (branch) {
      switchActivePharmacy()
    }
    setLeftSideBoardStatus('appointmentCreation')
    if (nextPossibleDate) {
      presentNotification({
        title: 'Terminvorschläge:',
        content: nextPossibleDate,
        kind: NotificationBannerKind.info,
        seconds: 10,
      })
    }
  }

  const onCreateReminder = () => {
    if (branch) {
      switchActivePharmacy()
    }
    onOpenModal(ModalKind.ReminderCreate)
  }

  const onAddNote = () => {
    if (branch) {
      switchActivePharmacy()
    }
    onOpenModal(ModalKind.NoteCreature)
  }

  const onDeactivatePharmacy = () => {
    if (branch) {
      switchActivePharmacy()
    }
    onOpenModal(ModalKind.Pharmacy)
  }

  const onShowPharmacyDetails = () => {
    if (branch) {
      setSelectedPharmacyId(selectedPharmacyBranchId)
    }
    onOpenPharmacyDetails()
  }

  const additionalCss = pharmacy?.tasks?.filter((task) => moment(new Date(task.until || '')).isSameOrAfter(new Date(), 'day')).length
    ? 'text-yellow-600'
    : ''
  return (
    <div className="flex items-center justify-center">
      <IconButton kind={IconButtonKind.default} icon={<LocationProfileIcon />} onClick={onShowPharmacyDetails} />
      <IconButton kind={IconButtonKind.default} icon={<ProjectstatusIcon />} onClick={() => onOpenModal(ModalKind.ProjectsEdit)} />
      {abilities.create_appointments && <IconButton kind={IconButtonKind.default} icon={<PlusSignIcon />} onClick={onCreateAppointment} />}
      {abilities.create_tasks && (
        <IconButton additionalCss={additionalCss} kind={IconButtonKind.default} icon={<ReminderIcon />} onClick={onCreateReminder} />
      )}
      {abilities.edit_pharmacies && (
        <IconButton
          kind={IconButtonKind.default}
          additionalCss={pharmacy.available_for_callcenter === false ? 'text-red-700' : ''}
          icon={<LockedForCallsIcon />}
          onClick={() => toggleAvailableForCallCenter(!!branch)}
        />
      )}
      {abilities.create_tasks && <IconButton kind={IconButtonKind.default} icon={<EditIcon />} onClick={onAddNote} />}
      {abilities.delete_pharmacies && <IconButton kind={IconButtonKind.default} icon={<DeactivateIcon />} onClick={onDeactivatePharmacy} />}
    </div>
  )
}

export default QuickActions
