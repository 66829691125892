import { gql } from 'graphql-request'

export const allPharmacyProjectStatuses = gql`
  {
    pharmacyProjectStatuses {
      id
      project_id
      pharmacy_id
      project {
        name
      }
      status {
        id
        label
      }
    }
  }
`
